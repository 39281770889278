import React, {useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
} from "reactstrap";
import { api, DB_FORMAT, exportExcel, getFormatOnlyDate, getLocaleFixedValue, isActionPermitted, USER_TYPES } from "../../util";
import { getTipsDetailedReportRequest } from "../../redux/report/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getEmployeesRequest } from "../../redux/employees/actions";

const TipsDetailedReport = () => {

    const dispatch = useDispatch();
    const { permissions , role } = useSelector((state) => state.user);
    const { saloons } = useSelector((state) => state.saloons);
    const { employees } = useSelector((state) => state.employees);
    const { reports , count , loading } = useSelector((state) => state.reports);
    const [paginationConfig, setPaginationConfig] = useState({
        filter: {
            // name: "",
            from:  moment().format(DB_FORMAT.DATE),
            to:  moment().format(DB_FORMAT.DATE),
            location: "",
            employee: "",
        },
        page: 1,
        limit: 10,
    });
    const [employeeFilter , setEmployeeFilter] = useState({
        filter : {
            working_location : ""
        }
    })

    useEffect(() => {
        if (isActionPermitted("tips-detailed-report", "read", permissions)) {
            if(role == USER_TYPES.SUPER_ADMIN) {
                dispatch(getSaloonsRequest());
            }
            dispatch(getEmployeesRequest(employeeFilter));
            dispatch(getTipsDetailedReportRequest(paginationConfig));
        }
    } , [paginationConfig]);

    const onDateRangeChange = ({ target }) => {
        const { name, value } = target;
        let pc = paginationConfig;
        if (name === "from") {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        } else {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        }
        setPaginationConfig({ ...pc, page: 1, limit: 10 });
    };

    // const onSearchChange = ({ target }) => {
    //     const { value } = target;
    //     const pc = paginationConfig;
    //     pc.filter.name = value;
    //     setPaginationConfig({ ...pc,  page: 1, limit: 10 });
    // };
  
    const [startDate , setStartDate] = useState(paginationConfig.filter.from);
    const [endDate , setEndDate] = useState(moment().format( DB_FORMAT.DATE));

    return (
        <React.Fragment>
            <PageHeader title="Tips Detailed Report" shouldShowAddBtn={true} onAddBtnClick={() => exportExcel('/reports/tipsDetailedReport' , paginationConfig)} addBtnText="Export Excel" />
            <Card>
                <CardBody>
                    <Row className="gy-3 gx-3">
                        <Col xl={3} md={3} sm={12} xs={12}>
                            <Label>Employee</Label>
                            <Input caret type="select" name="location" value={paginationConfig.filter.employee}
                                onChange={(e) => {
                                let x = paginationConfig;
                                x.filter.employee = e.target.value;
                                setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                }}
                                >
                                <option value={""}>All Employee</option>
                                {employees.map((_, index) => (
                                <option key={_.id} value={_.Employee.id}>
                                    {_.Employee.firstName} {_.Employee.lastName}
                                </option>
                                ))}
                            </Input>
                        </Col>
                        {role === USER_TYPES.SUPER_ADMIN && (
                            <Col xl={3} md={3} sm={12} xs={12}>
                                <Label>Location</Label>
                                <Input caret type="select" name="location" value={paginationConfig.filter.location}
                                    onChange={(e) => {
                                    const ef = employeeFilter;
                                    ef.filter.working_location = e.target.value;
                                    setEmployeeFilter({ ...ef });
                                    let x = paginationConfig;
                                    x.filter.employee = ((employees.filter((value) => value?.Employee?.id == x.filter.employee && (value?.Employee?.saloonIds.split(',')).includes(e.target.value))?.length > 0) ? x.filter.employee : '');
                                    x.filter.location = e.target.value;
                                    setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                    }}
                                    >
                                    <option value={""}>All Location</option>
                                    {saloons.map((_, index) => (
                                    <option key={_.id} value={_.Saloon.id}>
                                        {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                                    </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>From</Label>
                            <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
                        </Col>
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>To</Label>
                            <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <DataTable
                isPagination
                count={count}
                loading={loading}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                data={reports}
                columns={[
                    { 
                        Header: "Date", 
                        accessor: "billingDate",
                        Cell : ({row}) => {
                            return (row?.original?.billingDate ? getFormatOnlyDate(row.original.billingDate) : '')
                        }
                    },
                    { Header: "Transaction Id", accessor: "transactionId"},
                    { 
                        Header: "Employee Name",
                        Cell : ({row}) => {
                            return `${(row?.original?.Employee?.firstName ? row.original.Employee.firstName : '')} ${(row?.original?.Employee?.lastName ? row.original.Employee.lastName : '')}`;
                        }
                    },
                    { 
                        Header: "Services" ,  
                        Cell : ({row}) => {
                            return (
                                <>
                                {row?.original?.BillingItems && row.original.BillingItems.map((billingItem , index) => (
                                    <div style={{minHeight : "30px"}}>
                                        {(billingItem?.Service?.name ? billingItem.Service.name + ((index + 1) < row.original.BillingItems.length ? ' ,' : '') : '')}
                                    </div>
                                ))}
                                </>
                            )
                        }
                    },
                    { 
                        Header: "Tips", 
                        // accessor : "tipAmount",
                        Cell : ({row}) => {
                            return (row?.original?.tipAmount ? getLocaleFixedValue(row.original.tipAmount) : '');
                        }
                    },
                ]}
            />
        </React.Fragment>
    );
};

export default TipsDetailedReport;