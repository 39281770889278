import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Input, Row, Col, Label } from "reactstrap";
import ConfirmModal from "../../components/common/ConfirmModal";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getProjectsRequest } from "../../redux/projects/actions";
import { getLocationsRequest } from "../../redux/locations/actions";
import { api, isActionPermitted , getFormatDate, USER_TYPES, DB_FORMAT } from "../../util";
import { getAccountsRequest } from "../../redux/accounts/actions";
import moment from "moment/moment";

const ListLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locations, count, loading } = useSelector((state) => state.locations);
  const { projects } = useSelector((state) => state.projects);
  const { permissions , accountId } = useSelector((state) => state.user);
  const { accounts } = useSelector((state) => state.accounts);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
      name: "",
      project: "",
      account: "",
      from: null, // moment().format(DB_FORMAT.DATE),
	    to: null, // moment().format(DB_FORMAT.DATE),
    },
    sort: "name",
  });

  useEffect(() => {
    //if (isActionPermitted("location", "read", permissions)) {
      dispatch(getLocationsRequest(paginationConfig));
      dispatch(getProjectsRequest());
      dispatch(getAccountsRequest());
   // }
  }, [paginationConfig, permissions]);

  const deleteLocation = async (id) => {
    try {
      const response = await api.delete("/locations/" + id);
      if (response.status === 200) {
        //if (isActionPermitted("location", "read", permissions)) {
          dispatch(getLocationsRequest(paginationConfig));
        //}
        toast.success(response?.data?.message || response.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  const onDateRangeChange = ({ target }) => {
	  const { name, value } = target;
	  let pc = paginationConfig;
	  if (name === "from") {
      pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
    } else {
      pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
    }
	  setPaginationConfig({ ...pc, page: 1, limit: 10 });
  };

  const [startDate , setStartDate] = useState(paginationConfig.filter.from);
  const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));

  return (
    <React.Fragment>
      <PageHeader title="Locations" addBtnUrl="/locations/add" />
      <Card>
        <CardBody>
          <Row className="gy-3 gx-3">
          {accountId === 0 && (
              <Col xl={3} md={3} sm={12} xs={12}>
                <Label>Square Account</Label>
                <Input caret type="select" name="account" value={paginationConfig.filter.account}
                  onChange={(e) => {
                    let x = paginationConfig;
                    x.filter.account = e.target.value;
                    setPaginationConfig({ ...x,  page: 1, limit: 10 });
                  }}
                  >
                  <option value={""}>All Square Account</option>
                  {accounts.map((_, index) => (
                    <option key={_.id} value={_.id}>
                      {_.name}
                    </option>
                  ))}
                </Input>
              </Col>
            )}
            <Col xl={3} md={3} sm={12} xs={12}>
              <Label>Square Application</Label>
              <Input caret type="select" name="project" value={paginationConfig.filter.project}
                onChange={(e) => {
                  let x = paginationConfig;
                  x.filter.project = e.target.value;
                  setPaginationConfig({ ...x,  page: 1, limit: 10 });
                }}
                >
                <option value={""}>All Square Application</option>
                {projects.map((_, index) => (
                  <option key={_.id} value={_.id}>
                    {_.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xl={3} md={3} sm={12} xs={12}>
              <Label>Location</Label>
              <Input
                value={paginationConfig.filter.name}
                type="search"
                placeholder="Search Location"
                role="search"
                aria-roledescription="search for location"
                autoFocus
                onChange={onSearchChange}
              />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>From</Label>
              <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>To</Label>
              <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={locations}
        initialState={ accountId != 0 ? {hiddenColumns : ['Account.name']} : {} }
        columns={[
          { Header: "Square Account", accessor: "Account.name" },
          { Header: "Square Application", accessor: "Project.name" },
          { Header: "Name", accessor: "name" },
          { Header: "Address", accessor: "address_line_1" },
          { Header: "Locality", accessor: "locality" },
          { Header: "State", accessor: "state" },
          { Header: "Postal Code", accessor: "pincode" },
          {
              Header: "Date and Time",
              accessor: "createdAt",
              Cell: ({ row }) => {
                  return `${getFormatDate(row.original.createdAt)}`;
              },
          },
          {
            Header: "Actions",
            accessor: "actions",
            width: "10%",
            Cell: ({ row }) => {
              return (
                <div className="d-flex gap-2">
                  {isActionPermitted("location", "edit", permissions) && (
                    <Button
                      onClick={() => navigate(`/locations/${row.original.id}`)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                  {/*
                  {isActionPermitted("project", "delete", permissions) && (
                    <Button
                      color="danger"
                      onClick={() => {
                        setDeleteId(row.original.id);
                        setShowDeleteModal(true);
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  )}
                  */ }
                </div>
              );
            },
          },
        ]}
      />

      {showDeleteModal && (
        <ConfirmModal
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onOk={() => deleteLocation(deleteId)}
          onCancel={() => {
            setDeleteId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ListLocation;
