import { all, fork } from "redux-saga/effects";
import { userWatcher } from "./auth/saga";
import { billsWatcher } from "./bills/saga";
import { categoriesWatcher } from "./categories/saga";
import { employeesWatcher } from "./employees/saga";
import { saloonsWatcher } from "./saloons/saga";
import { servicesWatcher } from "./services/saga";
import { socketWatcher } from "./socket/saga";
import { customersWatcher } from "./customers/saga";
import { accountsWatcher } from "./accounts/saga";
import { projectsWatcher } from "./projects/saga";
import { locationsWatcher } from "./locations/saga";
import { schedulesWatcher } from "./schedules/saga";
import { reportsWatcher } from "./report/saga";
import { usersWatcher } from "./users/saga";

function* rootSaga() {
  yield all([
    fork(userWatcher),
    servicesWatcher(),
    saloonsWatcher(),
    categoriesWatcher(),
    billsWatcher(),
    fork(socketWatcher),
    employeesWatcher(),
    customersWatcher(),
    accountsWatcher(),
    projectsWatcher(),
    locationsWatcher(),
    schedulesWatcher(),
    reportsWatcher(),
    usersWatcher(),
  ]);
}

export default rootSaga;
