import {
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNT_PENDING,
} from "./actionTypes";

// NOTES

export const getAccountsRequest = (payload) => {
  return {
    type: GET_ACCOUNTS_REQUEST,
    payload,
  };
};

export const getAccountsSuccess = (payload) => {
  return {
    type: GET_ACCOUNTS_SUCCESS,
    payload,
  };
};

export const getAccountsFailure = (payload) => {
  return {
    type: GET_ACCOUNTS_FAILURE,
    payload,
  };
};

export const getAccountsPending = () => {
  return {
    type : GET_ACCOUNT_PENDING,
  }
}
