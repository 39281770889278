import {
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_FAILURE,
  GET_SCHEDULES_LIST_REQUEST,
  GET_SCHEDULES_PENDING,
} from "./actionTypes";

// NOTES

export const getSchedulesRequest = (payload) => {
  return {
    type: GET_SCHEDULES_REQUEST,
    payload,
  };
};

export const getSchedulesSuccess = (payload) => {
  return {
    type: GET_SCHEDULES_SUCCESS,
    payload,
  };
};

export const getSchedulesFailure = (payload) => {
  return {
    type: GET_SCHEDULES_FAILURE,
    payload,
  };
};

export const getSchedulesListRequest = (payload) => {
  return {
    type: GET_SCHEDULES_LIST_REQUEST,
    payload,
  };
};

export const getSchedulesPending = () => {
  return {
    type : GET_SCHEDULES_PENDING,
  }
}
