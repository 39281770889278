import { takeLatest, call, put } from "redux-saga/effects";
import { getSchedulesApiCall } from "./apiCalls";
import { getSchedulesFailure, getSchedulesPending, getSchedulesSuccess } from "./actions";
import { GET_SCHEDULES_REQUEST } from "./actionTypes";

function* getSchedulesWorker({ payload }) {
  try {
    yield put(getSchedulesPending());
    const response = yield call(getSchedulesApiCall, payload);
    yield put(getSchedulesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getSchedulesFailure({ error }));
  }
}


export function* schedulesWatcher() {
  yield takeLatest(GET_SCHEDULES_REQUEST, getSchedulesWorker);
}
