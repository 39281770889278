import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Input } from "reactstrap";
import ConfirmModal from "../../components/common/ConfirmModal";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getEmployeesRequest } from "../../redux/employees/actions";
import { getSchedulesRequest } from "../../redux/schedules/actions";
import { api, isActionPermitted, getFormatDate , getFormatOnlyDate } from "../../util";

const ListSchedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employees } = useSelector((state) => state.employees);
  const { schedules, count, loading } = useSelector((state) => state.schedules);
  const { permissions } = useSelector((state) => state.user);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
      name: "",
    },
    sort: "name",
   });

  useEffect(() => {
    if (isActionPermitted("employee", "read", permissions)) {
      dispatch(getEmployeesRequest(paginationConfig));
      dispatch(getSchedulesRequest(paginationConfig));
    }
  }, [paginationConfig, permissions]);

  
  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  return (
    <React.Fragment>
      <PageHeader title="Employees Schedule" addBtnUrl="/employees/add" />
      <Card>
        <CardBody>
          <Input
            value={paginationConfig.filter.name}
            type="search"
            placeholder="Search Employee"
            role="search"
            aria-roledescription="search for employee"
            autoFocus
            onChange={onSearchChange}
          />
        </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={schedules}
        columns={[
          { Header: "Salon", accessor: "Saloon.name"  },
          {
              Header: "Employee",
              accessor: "Employee.firstName",
              Cell: ({ row }) => {
                  return `${ ( row.original.Employee.firstName  + ' ' +  (row.original.Employee.lastName) ) }`;
              },
          },
          {
              Header: "Date",
              accessor: "scheduleDate",
              Cell: ({ row }) => {
                  return `${ ( row.original.scheduleDate ? getFormatOnlyDate(row.original.scheduleDate) : '' )}`;
              },
          },
          {
            Header: "Actions",
            accessor: "actions",
            width: "10%",
            Cell: ({ row }) => {
            	{console.log("row")}{console.log(row)}
              return (
                <div className="d-flex gap-2">
                  {isActionPermitted("schedules", "edit", permissions) && (
                    
                	<Button
                      onClick={() => navigate(`/schedules/${row.original.id}`)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                  
                </div>
              );
            },
          },
        ]}
      />
     </React.Fragment>
  );
};

export default ListSchedule;
