import { api } from "../../util";

export const getSchedulesApiCall = (payload = {}) => {
  let url = "/schedules";

  return api.get(url, {
	    params: {
	      page: payload.page,
	      limit: payload.limit,
	      filter: payload.filter || {},
	      //   sort: payload.sort,
	      //   search: payload.search,
	    },
	  });
};


