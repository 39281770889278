import {
  GET_PAYROLL_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_COMMISSION_DETAILED_REPORT_REQUEST,
  GET_TIPS_DETAILED_REPORT_REQUEST,
  GET_HOURLY_DETAILED_REPORT_REQUEST,
  GET_REPORT_PENDING,
  GET_SCHEDULE_DETAILED_REPORT_REQUEST
} from "./actionTypes";

// NOTES

export const getPayrollReportRequest = (payload) => {
  return {
    type: GET_PAYROLL_REPORT_REQUEST,
    payload,
  };
};

export const getCommissionDetailedReportRequest = (payload) => {
  return {
    type: GET_COMMISSION_DETAILED_REPORT_REQUEST,
    payload,
  };
};

export const getTipsDetailedReportRequest = (payload) => {
  return {
    type: GET_TIPS_DETAILED_REPORT_REQUEST,
    payload,
  };
};

export const getHourlyDetailedReportRequest = (payload) => {
  return {
    type: GET_HOURLY_DETAILED_REPORT_REQUEST,
    payload,
  };
};

export const getScheduleDetailedReportRequest = (payload) => {
	  return {
	    type: GET_SCHEDULE_DETAILED_REPORT_REQUEST,
	    payload,
	  };
	};

export const getReportsSuccess = (payload) => {
  return {
    type: GET_REPORT_SUCCESS,
    payload,
  };
};

export const getReportsFailure = (payload) => {
  return {
    type: GET_REPORT_FAILURE,
    payload,
  };
};

export const getReportsPending = () => {
  return {
    type : GET_REPORT_PENDING,
  }
}
