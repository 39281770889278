import React, {useState, useEffect} from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export default function SignUp({ pageInfo }) { 
  const { state = { phoneNumber: '' } } = useLocation();

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  let dummyCategories = [
    {indexKey: 1,categoriesName: "Brow Lamination",},
    {indexKey: 2,categoriesName: "Eyebrow Threading",},
    {indexKey: 3,categoriesName: "Eyelash",},
    {indexKey: 4,categoriesName: "Hair Service",},
    {indexKey: 5,categoriesName: "Henna",},
    {indexKey: 6,categoriesName: "Other Threading",},
    {indexKey: 7,categoriesName: "Products",},
    {indexKey: 8,categoriesName: "Services",},
    {indexKey: 9,categoriesName: "Skincare",},
  ]

  return (
    <>
    <div className="main-card">
      <div className="info-card">
        <div className="card">
          <div className="border-bottom p-3 mb-0 card-title">
            <i className="fa-solid fa-square-phone icon"></i>{state && state.phoneNumber && ( <span className="number">{state.phoneNumber}</span>)}
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-header">
                <span className="number-tag">01</span>Select Services
              </div>
              <div className="tab-body">
                <div className="input">
                  <div className="service-tag">Hair Cut ($50.00)</div>
                  <div className="service-tag">Beard ($25.00)</div>
                  <div className="service-tag">Women Hair Cut ($150.00)</div>
                  <div className="service-tag">Hair Cut ($35.00)</div>
                </div>
              </div>
              <div className="tab-footer">
                <button onClick={toggle} type="button" className="add-service"><span className="icon-bg"><i className="fa-solid fa-plus icon"></i></span><p className="text">Touch to select service</p></button>
              </div>
            </div>

            <div className="tab-content">
              <div className="tab-header">
                <span className="number-tag">02</span>Select Artist (optional)
              </div>
              <div className="tab-body">
                <div className="checkbox-list">
                  <div className="checkbox-div">
                    <div className="checkbox">
                      <input className="form-check-input checkbox-input" name="select_artist" type="radio" value="" id="artist_1"  />
                      <label className="form-check-label" htmlFor="artist_1">First Available</label>
                    </div>
                    <span className="waiting-text">0 min wait</span>
                  </div>
                  <div className="checkbox-div">
                    <div className="checkbox"> 
                      <input className="form-check-input checkbox-input" name="select_artist" type="radio" value="" id="artist_2"  />
                      <label className="form-check-label" htmlFor="artist_2">John</label>
                    </div>
                    <span className="waiting-text">30 min wait</span>
                  </div>
                  <div className="checkbox-div">
                    <div className="checkbox">
                      <input className="form-check-input checkbox-input" name="select_artist" type="radio" value="" id="artist_3"  />
                      <label className="form-check-label" htmlFor="artist_3">Joel</label>
                    </div>
                    <span className="waiting-text">10 min wait</span>
                  </div>
                  <div className="checkbox-div">
                    <div className="checkbox">
                      <input className="form-check-input checkbox-input" name="select_artist" type="radio" value="" id="artist_4"  />
                      <label className="form-check-label" htmlFor="artist_4">Lorem</label>
                    </div>
                    <span className="waiting-text">5 min wait</span>
                  </div>
                  <div className="checkbox-div">
                    <div className="checkbox">
                      <input className="form-check-input checkbox-input" name="select_artist" type="radio" value="" id="artist_5"  />
                      <label className="form-check-label" htmlFor="artist_5">Ipsum</label>
                    </div>
                    <span className="waiting-text">8 min wait</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group text-right mb-0 btns card-footer border-top-0 d-flex gap-2 justify-content-end mt-3">
              <Link to={'/book-appointment'} className="border btn btn-transaprent back-btn">Cancel</Link>
              <button type="submit" name="submit" className="btn btn-secondary send-btn">Sign In</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal isOpen={modal} className="service-modal" toggle={toggle} centered size={'lg'}>
        <ModalHeader toggle={toggle}>Select Services</ModalHeader>
        <ModalBody>
          <Card className="shadow-none mb-0">
            <Row>
              <Col lg={4} md={5} className="row-height">
                <CardTitle tag="h5">Categories</CardTitle>
                {dummyCategories.map((data) =>(
                  <Button block key={data.indexKey} type="button" color={"secondary"} className="my-1">{data.categoriesName}</Button>
                ))}
              </Col>
              <Col lg={8} md={7} className="border-start row-height">
                <CardTitle tag="h5">Services </CardTitle>
                <div className="service-list">
                  <Row>
                    <Col lg={6}><Button block type="button" color={"secondary"} className="my-1">Eyelashes</Button></Col>
                    <Col lg={6}><Button block type="button" color={"secondary"} className="my-1">Eyelashes Refill</Button></Col>
                  </Row>
                </div>
              </Col>
              <Col sm={12} className="mt-3 border-top pt-2">
                <CardTitle tag="h5">Selected Services</CardTitle>  
                <Row>
                  <Col md={6} lg={4}>
                    <Button block type="button" color="light" className="d-flex justify-content-between align-items-center my-1 fs-6 gap-1">
                      <div className="d-flex text-start">Eyelashes (1)<br />$35.00</div>
                      <i className="bi bi-x fs-4 fw-bolder"/>
                    </Button>
                  </Col>
                  <Col md={6} lg={4}>
                    <Button block type="button" color="light" className="d-flex justify-content-between align-items-center my-1 fs-6 gap-1">
                      <div className="d-flex text-start">Eyelashes (1)<br />$35.00</div>
                      <i className="bi bi-x fs-4 fw-bolder"/>
                    </Button>
                  </Col>
                  <Col md={6} lg={4}>
                    <Button block type="button" color="light" className="d-flex justify-content-between align-items-center my-1 fs-6 gap-1">
                      <div className="d-flex text-start">Eyelashes (1)<br />$35.00</div>
                      <i className="bi bi-x fs-4 fw-bolder"/>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Link className="btn btn-primary" to={'/thank-you'}>OK</Link>
        </ModalFooter>
      </Modal>
    </>
  );
}