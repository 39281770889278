import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , FIXED_VALUE_INCENTIVE , PRECENTAGE_VALUE_INCENTIVE } from "../../util";
import { toast } from "react-toastify";
import Select from 'react-select'
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "bootstrap";


const SmsSetting = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.user);
  const [initialValues, setInitialValues] = useState({
	  notification_number: "",
	  send_notification_sms : "",
	  marketing_number : "",
	  send_marketing_sms : "",
	  signup_sms : "",
	  twenty_day_sms : "",
	  thirty_day_sms : "",
	  forty_day_sms : "",
	  fifty_day_sms : "",
	  sixty_day_sms : "",
  });
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    setInitialValues({
      ...initialValues,
    });
  }, []);
 
  const onSubmit = async (values) => {
	  if( sendNotificationSMS !== false ){
	    	values.send_notification_sms = 1;
	    } else {
	    	values.send_notification_sms = 0;
	    }
	  if( sendMarketingSMS !== false ){
	    	values.send_marketing_sms = 1;
	    } else {
	    	values.send_marketing_sms = 0;
	    }
	  setIsLoading(true)
	  try {
	    const response = await api.post("/dashboard/updateSMSSetting", {
	      ...values,
	    });
	    if (response.status === 201) {
	      toast.success(response?.data?.message || response.message);
	      navigate("/sms-setting");
	    }
	  } catch (error) {
	    toast.error(error.response?.data?.message || error.message);
	  } finally {
	    setIsLoading(false);
	  }
	    
  };
  const [sendNotificationSMS, setSendNotificationSMS] = useState(false);
  const [sendMarketingSMS, setSendMarketingSMS] = useState(false);
  useEffect(() => {
    
      const getSMSSettingInfo = async (id) => {
    	setIsLoading(true);
    	try {
          const response = await api.get("/dashboard/getSMSSetting");
          if (response.status === 200) {
        	  setInitialValues({
              ...response.data.data,
              notification_number: response.data.data.notification_number || "",
              send_notification_sms: response.data.data.send_notification_sms || "",
              marketing_number: response.data.data.marketing_number || "",
              send_marketing_sms: response.data.data.send_marketing_sms || "",
              signup_sms: response.data.data.signup_sms || "",
              twenty_day_sms: response.data.data.twenty_day_sms || "",
              thirty_day_sms: response.data.data.thirty_day_sms || "",
              forty_day_sms: response.data.data.forty_day_sms || "",
              fifty_day_sms: response.data.data.fifty_day_sms || "",
              sixty_day_sms: response.data.data.sixty_day_sms || "",
            });
        	if(response.data.data.send_notification_sms === 1 ){
        		setSendNotificationSMS(true)
        	} else {
        		setSendNotificationSMS(false)
        	}
        	
        	if(response.data.data.send_marketing_sms === 1 ){
        		setSendMarketingSMS(true)
        	} else {
        		setSendMarketingSMS(false)
        	}
          }
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        } finally {
          setIsLoading(false);
        }
      };
      getSMSSettingInfo();
    
  }, []);
  
  
  const handleSendNotificationSMSSelection = (selectedOptions,values, setValues) => {
	  setSendNotificationSMS(!sendNotificationSMS);
  }
  
  
  const handleSendMarketingSMSSelection = (selectedOptions,values, setValues) => {
	  setSendMarketingSMS(!sendMarketingSMS);
  }

  const handleMobileField = (e , values, setValues ) => {
    let enterValue = e.target.value;
    enterValue = enterValue.replace(/[^\d]/g, '').match(/\d*/g).join('').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-').replace(/-*$/g, '');
    setValues({ ...values, marketing_number: enterValue });
  }
  
  const handleNotificationMobileField = (e , values, setValues ) => {
	    let enterValue = e.target.value;
	    enterValue = enterValue.replace(/[^\d]/g, '').match(/\d*/g).join('').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-').replace(/-*$/g, '');
	    setValues({ ...values, notification_number: enterValue });
  }
 
  return (
    <React.Fragment>
      <Formik
        validateOnChange
        initialValues={{ ...initialValues }}
        validationSchema={
          Yup.object().shape({
        	  notification_number: Yup.string().required("Please enter notification number"),
        	  marketing_number: Yup.string().required("Please enter marketing number"),
        	}) 
        }
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, values, touched, setValues }) => (
          <>
            <PageHeader
              shouldShowAddBtn={false}
              title="Manage SMS Setting"
            />
            <Card>
              <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                <i className="bi bi-person-bounding-box me-2"> </i>
                SMS Setting
              </CardTitle>
              <CardBody>
                <Form className="form-inline">
                  <Row>
	                <Col xl={6}>
	                	<FormGroup>
                        <Label for="notification_number" className="font-bold">Notification Number</Label>
                        <Field
                          id="notification_number"
                          name="notification_number"
                          placeholder="Notification Number"
                         onChange={(e) => {
                        	 handleNotificationMobileField(e, values, setValues );
    		              }}
                          type="text"
                          className={
                            "form-control" +
                            (errors.notification_number && touched.notification_number ? " is-invalid" : "")
                          }
                        />
                        {errors.notification_number && touched.notification_number && (
                          <span component="div" className="invalid-feedback">
                            {errors.notification_number}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
	                    <FormGroup>
	                    <Label>Send Notification SMS</Label>
	                    <div className="d-flex gap-2">
	                      <Input id="send_notification_sms_yes" 
	                    	  	type="radio" 
	                    	  	name="send_notification_sms"
	                    	  	checked={ sendNotificationSMS !== false ? 'checked' : '' }
	                	  		onClick={(e) => {
	                	  			handleSendNotificationSMSSelection(e, values, setValues);
	    	                    }}
	                      />
	                      <Label htmlFor="send_notification_sms_yes" className="mx-1 px-1">Yes</Label>
	
	                      <Input id="send_notification_sms_no" 
	                    	  	type="radio"
	                    	  	value 
	                    	  	name="send_notification_sms"
	                    	  	checked={ sendNotificationSMS !== true ? 'checked' : '' }
	                    	  	onClick={(e) => {
	                    	  		handleSendNotificationSMSSelection(e, values, setValues);
	    	                    }}
	                    	  		
	                      />
	                      <Label htmlFor="send_notification_sms_no" className="mx-1 px-1">No</Label>
	                    </div>
	                  </FormGroup>
	                </Col>
                    <Col xl={6}>
                    <FormGroup>
                      <Label for="marketing_number" className="font-bold">Marketing Number</Label>
                      <Field
                        id="marketing_number"
                        name="marketing_number"
                        placeholder="Marketing Number"
                        type="text"
                        	onChange={(e) => {
      	                	  handleMobileField(e, values, setValues);
      		              }}
                        className={
                          "form-control" +
                          (errors.marketing_number && touched.marketing_number ? " is-invalid" : "")
                        }
                      />
                      {errors.marketing_number && touched.marketing_number && (
                        <span component="div" className="invalid-feedback">
                          {errors.marketing_number}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6}>
	                  <FormGroup>
	                  <Label>Send Marketing SMS</Label>
	                  <div className="d-flex gap-2">
	                    <Input id="send_marketing_sms_yes" 
	                  	  	type="radio" 
	                  	  	name="send_marketing_sms"
	                  	  	checked={ sendMarketingSMS !== false ? 'checked' : '' }
	              	  		onClick={(e) => {
	              	  		handleSendMarketingSMSSelection(e, values, setValues);
	  	                    }}
	                    />
	                    <Label htmlFor="send_marketing_sms_no" className="mx-1 px-1">Yes</Label>
	
	                    <Input id="send_marketing_sms_no" 
	                  	  	type="radio"
	                  	  	value 
	                  	  	name="send_marketing_sms"
	                  	  	checked={ sendMarketingSMS !== true ? 'checked' : '' }
	                  	  	onClick={(e) => {
	                  	  	handleSendMarketingSMSSelection(e, values, setValues);
	  	                    }}
	                  	  		
	                    />
	                    <Label htmlFor="send_marketing_sms_no" className="mx-1 px-1">No</Label>
	                  </div>
	                </FormGroup>
	              </Col>
                </Row>
                <div className="">
                	<h5>Marketing SMS</h5>
                	<Row>
                	<Col xl={12}>
	                    <FormGroup>
		                    <Row>
			                    <Col xs={2} className="text-right sms-setting-label">
			                    	<Label For="signup_sms" className="font-bold">Signup SMS</Label>
			                    </Col>
			                    <Col xs={10}>
			                      <textarea
				                      id="signup_sms"
				                      name="signup_sms"
				                      placeholder="Signup SMS"
				                      type="text"
				                      rows={3}
				                      className={
				                        "form-control" +
				                        (errors.signup_sms && touched.signup_sms ? " is-invalid" : "")
				                      }
				                    />
				                    {errors.signup_sms && touched.signup_sms && (
				                      <span component="div" className="invalid-feedback">
				                        {errors.signup_sms}
				                      </span>
				                    )}
			                      </Col>
			                  </Row>
	                      </FormGroup>
	                </Col>
                	<Col xl={12}>
	                    <FormGroup>
		                    <Row>
			                    <Col xs={2} className="text-right sms-setting-label">
			                    	<Label For="twenty_day_sms" className="font-bold">After 20 Days</Label>
			                    </Col>
			                    <Col xs={10}>
			                      <textarea
				                      id="twenty_day_sms"
				                      name="twenty_day_sms"
				                      placeholder="20 Days Marketing SMS"
				                      type="textarea"
				                      rows={3}
				                      className={
				                        "form-control" +
				                        (errors.twenty_day_sms && touched.twenty_day_sms ? " is-invalid" : "")
				                      }
				                    />
				                    {errors.twenty_day_sms && touched.twenty_day_sms && (
				                      <span component="div" className="invalid-feedback">
				                        {errors.twenty_day_sms}
				                      </span>
				                    )}
			                      </Col>
			                  </Row>
	                      </FormGroup>
                    </Col>
                    
                    <Col xl={12}>
	                    <FormGroup>
		                    <Row>
			                    <Col xs={2} className="text-right sms-setting-label">
			                    	<Label For="thirty_day_sms" className="font-bold">After 30 Days</Label>
			                    </Col>
			                    <Col xs={10}>
			                      <textarea
				                      id="thirty_day_sms"
				                      name="thirty_day_sms"
				                      placeholder="30 Days Marketing SMS"
				                      type="textarea"
				                      rows={3}
				                      className={
				                        "form-control" +
				                        (errors.thirty_day_sms && touched.thirty_day_sms ? " is-invalid" : "")
				                      }
				                    />
				                    {errors.thirty_day_sms && touched.thirty_day_sms && (
				                      <span component="div" className="invalid-feedback">
				                        {errors.thirty_day_sms}
				                      </span>
				                    )}
			                      </Col>
			                  </Row>
	                      </FormGroup>
	                </Col>
	                
	                <Col xl={12}>
	                    <FormGroup>
		                    <Row>
			                    <Col xs={2} className="text-right sms-setting-label">
			                    	<Label For="forty_day_sms" className="font-bold">After 40 Days</Label>
			                    </Col>
			                    <Col xs={10}>
			                      <textarea
				                      id="forty_day_sms"
				                      name="forty_day_sms"
				                      placeholder="40 Days Marketing SMS"
				                      type="textarea"
				                      rows={3}
				                      className={
				                        "form-control" +
				                        (errors.forty_day_sms && touched.forty_day_sms ? " is-invalid" : "")
				                      }
				                    />
				                    {errors.forty_day_sms && touched.forty_day_sms && (
				                      <span component="div" className="invalid-feedback">
				                        {errors.forty_day_sms}
				                      </span>
				                    )}
			                      </Col>
			                  </Row>
	                      </FormGroup>
	                </Col>
                
	                <Col xl={12}>
		                <FormGroup>
		                    <Row>
			                    <Col xs={2} className="text-right sms-setting-label">
			                    	<Label For="fifty_day_sms" className="font-bold">After 50 Days</Label>
			                    </Col>
			                    <Col xs={10}>
			                      <textarea
				                      id="fifty_day_sms"
				                      name="fifty_day_sms"
				                      placeholder="50 Days Marketing SMS"
				                      type="textarea"
				                      rows={3}
				                      className={
				                        "form-control" +
				                        (errors.fifty_day_sms && touched.fifty_day_sms ? " is-invalid" : "")
				                      }
				                    />
				                    {errors.fifty_day_sms && touched.fifty_day_sms && (
				                      <span component="div" className="invalid-feedback">
				                        {errors.fifty_day_sms}
				                      </span>
				                    )}
			                      </Col>
			                  </Row>
		                  </FormGroup>
		            </Col>
            
		            <Col xl={12}>
			            <FormGroup>
			                <Row>
			                    <Col xs={2} className="text-right sms-setting-label">
			                    	<Label For="sixty_day_sms" className="font-bold">After 60 Days</Label>
			                    </Col>
			                    <Col xs={10}>
			                      <textarea
				                      id="sixty_day_sms"
				                      name="sixty_day_sms"
				                      placeholder="60 Days Marketing SMS"
				                      type="textarea"
				                      rows={3}
				                      className={
				                        "form-control" +
				                        (errors.sixty_day_sms && touched.sixty_day_sms ? " is-invalid" : "")
				                      }
				                    />
				                    {errors.sixty_day_sms && touched.sixty_day_sms && (
				                      <span component="div" className="invalid-feedback">
				                        {errors.sixty_day_sms}
				                      </span>
				                    )}
			                      </Col>
			                  </Row>
			              </FormGroup>
			        </Col>
			        </Row>
             </div>
             
             <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                    <Button
                      color="transaprent"
                      className="border"
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading} className="btn btn-primary">
                      Update
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SmsSetting;
