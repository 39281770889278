import Header from "./Header";
import { Container } from "reactstrap";

const UnauthenticatedLayout = ({ children , showHeader , additionalClass  }) => {
  return (
    <main>
      {/********header**********/}
      {showHeader && (
        <Header isAuthenticated={false} />
      )}
      <div className={"pageWrapper d-lg-flex " + ( additionalClass ? additionalClass : '' ) }>
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="p-4 new-overflow" fluid>
            {children}
          </Container>
        </div>
      </div>
    </main>
  );
};

export default UnauthenticatedLayout;
