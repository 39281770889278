import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , LOCAL_STORAGE_USER } from "../../util";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { getAccountsRequest } from "../../redux/accounts/actions";
import { getProjectsRequest } from "../../redux/projects/actions";
import { getLocationsRequest } from "../../redux/locations/actions";
import { toast } from "react-toastify";
import { getCategoriesRequest } from "../../redux/actions";
import Select from 'react-select';

const UpsertSaloon = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accounts } = useSelector((state) => state.accounts);
  //const { projects } = useSelector((state) => state.projects);
  const { saloons } = useSelector((state) => state.saloons);
  const { categories } = useSelector((state) => state.categories);
  //const { locations } = useSelector((state) => state.locations);
  const { permissions } = useSelector((state) => state.user);
  const [projects, setProjects] = useState();
  const [locations, setLocations] = useState();
  const defaultSelectOption = { 'label' : 'Select' , 'value' : '' };
  
  const [initialValues, setInitialValues] = useState({
	account: defaultSelectOption,
	project: defaultSelectOption,
	name: "",
    locationId: defaultSelectOption,
    tax : null,
    location : null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState({});
  const [projectOptions, setProjectOptions] = useState({});
  const [locationOptions, setLocationOptions] = useState({});
  
  useEffect(() => {
    if (isActionPermitted("saloons", "read", permissions)) {
      dispatch(getAccountsRequest());
      //dispatch(getProjectsRequest());
      dispatch(getSaloonsRequest());
    }
  }, [permissions]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
    });
  }, []);
  const getProjectList = async (accountId = null , selectAccountOptions = null , values = null , setValues = null  ) => {
  	  setIsLoading(true) 
      try {
    	var payLoad = {};
    	if( accountId ){
    		payLoad = { params: { filter: { account : accountId } } }
    		const response = await api.get("/projects" , payLoad );
            if (response.status === 200) {
            	setProjects(response.data.data.list);
            }
        } else {
    		setProjects();
    		
    		
    	}
    	setLocations();
    	if( selectAccountOptions ){
    		console.log("selectAccountOptions if");
    		/*setInitialValues({
	  	          ...initialValues,
	  	          project: defaultSelectOption,
	  	          account: selectAccountOptions,
	  	   });*/
    		setValues({ ...values, project: defaultSelectOption , account: selectAccountOptions   , locationId : defaultSelectOption });
    	} else {
    		console.log("selectAccountOptions else");
    		/*setInitialValues({
	  	          ...initialValues,
	  	          project: defaultSelectOption,
	  	   });*/
    		setValues({ ...values, project: defaultSelectOption , locationId : defaultSelectOption  });
    	}
    	
        setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false);
      }
  };
  const getLocationList = async (projectId = null , selectedProjectOptions = null , values = null , setValues = null ) => {
  	setIsLoading(true)
  	console.log("getLocationList"  );
  	console.log("projectId" , projectId );
  	console.log("selectedProjectOptions" , selectedProjectOptions );
      try {
    	var payLoad = {};
    	if( projectId ){
    		payLoad = { params: { filter: { project : projectId } } }
    		const response = await api.get("/locations/list" , payLoad );
            if (response.status === 200) {
            	setLocations(response.data.data.list);
            }
    	} else {
    		setLocations();
    	}
    	if( selectedProjectOptions ){
    		/*setInitialValues({
	  	          ...initialValues,
	  	         locationId: defaultSelectOption,
	  	       project: selectedProjectOptions,
	  	   });*/
    		setValues({ ...values, project:selectedProjectOptions  , locationId:defaultSelectOption });
    	}  else {
    		/*setInitialValues({
	  	          ...initialValues,
	  	          project: defaultSelectOption,
	  	        project: selectedProjectOptions,
	  	   });*/
    		setValues({ ...values, locationId: defaultSelectOption  });
    	}
    	setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false);
      }
  };
  
  
  useEffect(() => {
	    if (id !== "add") {
	      const getSallon = async (id) => {
	    	setIsLoading(true) 
	        try {
	          const response = await api.get("/saloons/getSaloonInfo/" + id);
	          if (response.status === 200) {
	        	  console.log("response")
	        	  console.log(response);
	        	var accountSelectedObject = defaultSelectOption;
	        	if( response?.data?.data?.saloon?.Account?.id  ){
	        		accountSelectedObject = { "value": response?.data?.data?.saloon?.Account?.id , "label": response?.data?.data?.saloon?.Account?.name };
	        	}
	        	
	        	var projectSelectedObject = defaultSelectOption;
	        	if( response?.data?.data?.saloon?.Project?.id  ){
	        		projectSelectedObject = { "value": response?.data?.data?.saloon?.Project?.id , "label": response?.data?.data?.saloon?.Project?.name };
	        	}
	        	
	        	var locationSelectedObject = defaultSelectOption;
	        	if( response?.data?.data?.saloon?.locationId  ){
	        		var selectedLabel = null;
	        		response.data.data.locations.forEach(location => {
	        		    if (response?.data?.data?.saloon?.locationId === location.id) {
	        		    	selectedLabel = location.name
	        		    }
	        		});
	        		locationSelectedObject = { "value": response?.data?.data?.saloon?.locationId , "label": selectedLabel };
	        	}
	        	
	        	if( response?.data?.data?.projects  ){
	        		console.log("setProjects");
	        		setProjects(response.data.data.projects);
	        	}
	        	if( response?.data?.data?.locations  ){
	        		console.log("setLocations");
	        		setLocations(response.data.data.locations);
	        	}
	        	setInitialValues({
		              ...response.data.data.saloon,
		              name: response.data.data.saloon.name || "",
		              account: accountSelectedObject,
		              project: projectSelectedObject,
			          name: response.data.data.saloon.name  || "",
		              locationId: locationSelectedObject  || "",
		              tax : response.data.data.saloon.tax  || "",
	        	});
	          }
	          setIsLoading(false);
	        } catch (error) {
	           setIsLoading(false);
	           toast.error(error.response?.data?.message || error.message);
	        } finally {
	          setIsLoading(false);
	        }
	      };
	      getSallon(id);
	    }
	  }, [id, saloons]);
 
  const onSubmit = async (values) => {
    // console.log('getting values',values,isVariablePricing);
	console.log("values");
	console.log("onSubmit");
	console.log(values);
	if(!values?.account?.value){
		  toast.error("Please select square account");
		  return false;
	}
	if(!values?.project?.value){
		  toast.error("Please select square application");
		  return false;
	}
	if( values?.account ){
		//values.account = values?.account?.value;
	}
	if( values?.project ){
		//values.project = values?.project?.value;
	}
	
	
	
	if( values?.locationId ){
		//values.locationId = values?.locationId?.value;
		locations.forEach(location => {
		    if (values.locationId.value === location.id) {
		    	values.location = location.name
		    	if( location?.address?.addressLine1){
		    		values.location += ' ' + location.address.addressLine1 ;
		    	}
		    	if( location?.address?.addressLine2){
		    		values.location += ' ' +location.address.addressLine2;
		    	}
		    	if( location?.address?.administrativeDistrictLevel1){
		    		values.location += ' ' +location.address.administrativeDistrictLevel1 ;
		    	}
		    	if( location?.address?.postalCode){
		    		values.location += ' ' + location.address.postalCode;
		    	}
		    }
		});
	}
	if(!values?.locationId?.value){
		  toast.error("Please select shop location");
		  return false;
	}
	
	console.log("values");
	console.log(values);
	console.log(locations);
    
	setIsLoading(true)
    if (id === "add") {
      try {
        const response = await api.post("/saloons", {
          ...values,
        });
        if (response.status === 201) {
          toast.success(response?.data?.message || response.message);
          navigate("/salons");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await api.put("/saloons/" + id, {
          ...values,
        });
        if (response.status === 200) {
          toast.success(response?.data?.message || response.message);
          navigate("/salons");
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false);
      }
    }
    
  };
  
  const projectList = async(accountId = null , selectedAccountOptions = null , values = null , setValues = null  ) => {
	  //setIsLoading(true)
	  getProjectList(accountId , selectedAccountOptions , values , setValues ); 
	  //await dispatch(getProjectsRequest( { filter : { account : accountId } } ) );
	  //setIsLoading(false)
  }
  
  const locationList = async(projectId = null , selectedProjectOptions = null , values = null , setValues = null ) => {
	  getLocationList(projectId , selectedProjectOptions  , values , setValues  );
	  //await dispatch(getLocationsRequest( { filter : { project : projectId } } ) );
	  
  }
  
  const handleAccountSelection = (selectedOptions , values , setValues ) => {
	  const selectedAccountValue = selectedOptions?.value;
	  /*setInitialValues({
          ...initialValues,
          account: selectedOptions,
      });*/
	  console.log("selectedOptions" , selectedOptions );
	  console.log("values" , values );
	  console.log("setValues" , setValues );
	  //setValues({ ...values, account : selectedOptions });
	  projectList(selectedAccountValue , selectedOptions , values , setValues  );
	}
  
  useEffect(() => {
	  var accountSelection = [];
	  accountSelection.push(defaultSelectOption)
	  accounts.map( (_, index) => (
			accountSelection.push({ "value": _.id, "label": _.name })
	  ))
	  setAccountOptions(accountSelection);
  }, [accounts]);
  
  
  
  useEffect(() => {
	   var projectSelection = [];
	   projectSelection.push(defaultSelectOption)
	   console.log("projects" , projects );
	   console.log((projects) );
	   if( (projects) ) {
		   projects.map( (_, index) => (
				  projectSelection.push({ "value": _.id, "label": _.name })
		   ))
	   }
	   setProjectOptions(projectSelection);
 }, [projects]);
  
  useEffect(() => {
	   var locationSelection = [];
	   locationSelection.push(defaultSelectOption)
	   console.log("locations" , locations );
	   if( locations ) {
		   locations.map( ( location, index) => (
				   locationSelection.push({ "value": location.id, "label": location.name })  
		   ))
	   }
	   setLocationOptions(locationSelection);
}, [locations]);
  
  const handleProjectSelection = (selectedOptions , values = null , setValues = null ) => {
	  const selectedProjectValue = selectedOptions?.value;
	  
	  /*setInitialValues({
          ...initialValues,
          project: selectedOptions,
      });*/
	  console.log("selectedProjectValue" , selectedProjectValue );
	  console.log("selectedOptions" , selectedOptions );
	  locationList(selectedProjectValue , selectedOptions ,  values , setValues  );
  }
  const handleLocationSelection = (selectedOptions , values , setValues ) => {
	  const selectedProjectValue = selectedOptions?.value;
	  /*setInitialValues({
          ...initialValues,
          locationId: selectedOptions,
      });*/
	  setValues({ ...values, locationId : selectedOptions });
  }
  const getUserInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
  if( getUserInfo.accountId ){
	  navigate("/");
  }
  

  
  return (
    <React.Fragment>
      <Formik
        validateOnChange
        initialValues={{ ...initialValues }}
        validationSchema={
	    	 Yup.object().shape({
	        	 //account: Yup.object().required("Please select account"),
			  	 //project: Yup.object().required("Please select project"),
	        	 name: Yup.string().required("Please enter shop name"),
	        	// locationId: Yup.string().required("Please select shop location"),
	        	 //tax: Yup.number().required("Please enter tax"),
	        })
        }
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, values, touched, setValues }) => (
          <>
            <PageHeader
              shouldShowAddBtn={false}
              title={id === "add" ? "Add Salon" : "Edit Salon"}
            />
            <Card>
              <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                <i className="bi bi-shop me-2"> </i>
                {id === "add" ? "Create a new salon" : "Edit this salon"}
              </CardTitle>
              <CardBody>
              	<Form>
              		<Row>
                  		<Col xl={6}>
                  			<FormGroup>
	                  			<Label for="account">Square Account</Label>
	                  			<Select options={accountOptions} name="account" value={values.account} 
	                  			onChange={(e) => {
	                  				handleAccountSelection(e, values, setValues);
	    	                    }}
	                  			closeMenuOnSelect={true}/>
	                  			{errors.account && touched.account && (
			                      <span component="div" className="invalid-feedback">
			                        {errors.account}
			                      </span>
			                    )}
		                    </FormGroup>
		                </Col>
		                <Col xl={6}>
	              			<FormGroup>
	              			<Label for="project">Square Application</Label>
	              			<Select options={projectOptions} name="project" value={values.project} 
	              			onChange={(e) => {
	              				handleProjectSelection(e, values, setValues);
		                    }}
	              			closeMenuOnSelect={true}/>
	              			{errors.project && touched.project && (
		                      <span component="div" className="invalid-feedback">
		                        {errors.project}
		                      </span>
		                    )}
	                    </FormGroup>
	                </Col>	
	                <Col xl={6}>
                      <FormGroup>
                        <Label for="name">Shop name</Label>
                        <Field
                          id="name"
                          name="name"
                          placeholder="Shop name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        {errors.name && touched.name && (
                          <span component="div" className="invalid-feedback">
                            {errors.name}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    
	                  <Col xl={6}>
            			<FormGroup>
                			<Label for="locationId">Shop Location</Label>
                			<Select options={locationOptions} name="locationId" value={values.locationId} 
                			onChange={(e) => {
                				handleLocationSelection(e, values, setValues);
		                    }}
                			closeMenuOnSelect={true}/>
                			{errors.locationId && touched.locationId && (
		                      <span component="div" className="invalid-feedback">
		                        {errors.locationId}
		                      </span>
		                    )}
	                    </FormGroup>
	                </Col>
	                  <Col xl={6}>
	                    <FormGroup>
	                      <Label for="location">Tax</Label>
	                      <Field
	                        id="tax"
	                        name="tax"
	                        min={0}
	                      	step={0.01}
	                        placeholder="Tax"
	                        type="number"
	                        className={
	                          "form-control" +
	                          (errors.tax && touched.tax ? " is-invalid" : "")
	                        }
	                      />
	                      {errors.tax && touched.tax && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.tax}
	                        </span>
	                      )}
	                    </FormGroup>
	                  </Col>
                  </Row>
                  <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                    <Button
                      color="transaprent"
                      className="border"
                      type="button"
                      onClick={() => navigate("/salons")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading} className="btn btn-primary">
                      {id === "add" ? "Add Salon" : "Update Salon"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UpsertSaloon;
