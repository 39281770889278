import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Input , Row , Col , Label } from "reactstrap";
import ConfirmModal from "../../components/common/ConfirmModal";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getAccountsRequest } from "../../redux/accounts/actions";
import { api, isActionPermitted, getFormatDate, DB_FORMAT } from "../../util";
import moment from "moment/moment";

const ListAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accounts, count, loading } = useSelector((state) => state.accounts);
  const { permissions } = useSelector((state) => state.user);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
      name: "",
      from: null, //moment().format(DB_FORMAT.DATE),
	    to: null, //moment().format(DB_FORMAT.DATE),
    },
    sort: "name",
  });

  useEffect(() => {
    if (isActionPermitted("account", "read", permissions)) {
      dispatch(getAccountsRequest(paginationConfig));
    }
  }, [paginationConfig, permissions]);

  const deleteAccount = async (id) => {
    try {
      const response = await api.delete("/accounts/" + id);
      if (response.status === 200) {
        if (isActionPermitted("account", "read", permissions)) {
          dispatch(getAccountsRequest(paginationConfig));
        }
        toast.success(response?.data?.message || response.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  const onDateRangeChange = ({ target }) => {
	  const { name, value } = target;
	  let pc = paginationConfig;
	  if (name === "from") {
		  pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
	  } else {
		  pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
	  }
	  setPaginationConfig({ ...pc, page: 1, limit: 10 });
  };

  const [startDate , setStartDate] = useState(paginationConfig.filter.from);
  const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));

  return (
    <React.Fragment>
      <PageHeader title="Square Accounts" addBtnUrl="/square-accounts/add" />
      <Card>
        <CardBody>
          <Row className="gy-3 gx-3">
            <Col xl={3} md={3} sm={12} xs={12}>
              <Label>Square Account</Label>
              <Input
                value={paginationConfig.filter.name}
                type="search"
                placeholder="Search Square Account"
                role="search"
                aria-roledescription="search for square account"
                autoFocus
                onChange={onSearchChange}
              />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>From</Label>
              <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>To</Label>
              <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={accounts}
        columns={[
          { Header: "Name", accessor: "name", width: "30%" },
          { Header: "Email", accessor: "email", width: "30%" },
          {
              Header: "Date and Time",
              accessor: "createdAt",
              width: "30%",
              Cell: ({ row }) => {
                  return `${getFormatDate(row.original.createdAt)}`;
              },
          },
          {
            Header: "Actions",
            accessor: "actions",
            width: "20%",
            Cell: ({ row }) => {
              return (
                <div className="d-flex gap-2">
                  {isActionPermitted("account", "edit", permissions) && (
                    <Button
                      onClick={() => navigate(`/square-accounts/${row.original.id}`)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                  { /* 
                  {isActionPermitted("account", "delete", permissions) && (
                	<Button
                      color="danger"
                      onClick={() => {
                        setDeleteId(row.original.id);
                        setShowDeleteModal(true);
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  )}
                 */}	
                </div>
              );
            },
          },
        ]}
      />

      {showDeleteModal && (
        <ConfirmModal
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onOk={() => deleteAccount(deleteId)}
          onCancel={() => {
            setDeleteId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ListAccount;
