import { takeLatest, call, put } from "redux-saga/effects";
import { getLocationsApiCall } from "./apiCalls";
import { getLocationsFailure, getLocationsPending, getLocationsSuccess } from "./actions";
import { GET_LOCATIONS_REQUEST } from "./actionTypes";

function* getLocationsWorker({ payload }) {
  try {
    yield put(getLocationsPending());
    const response = yield call(getLocationsApiCall, payload);
    yield put(getLocationsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getLocationsFailure({ error }));
  }
}

export function* locationsWatcher() {
  yield takeLatest(GET_LOCATIONS_REQUEST, getLocationsWorker);
}
