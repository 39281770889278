import React, { useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import currectIcon from '../../assets/images/correct.png';
import { useLocation, useNavigate } from "react-router-dom";

export default function ThankYou({ pageInfo }) {

  const navigate = useNavigate();
  const { state = {saloonLocationId : null} } = useLocation();
  useEffect(() => {
    if(state === null) {
      navigate('/');
    } else {
      setTimeout(() => {
        navigate('/book-appointment/' + state.saloonLocationId , {replace:true});
      }, 10000);
    }
  } , []);
  
  return (
    <>
     <div className="thank-you-div new-thank-you for-big-font">
        <p className="thank-you-text">Please Have a Seat and one of our Stylist will be right with you.</p>

        <h5 className="thank-you-text-2 thank-you-text">You Are Checked In</h5>

        <div className="icon-div">
          <img src={currectIcon} alt="Thank You" className="thanks-icon" />
        </div>
        <h5 className="thank-you-text mt-3">Thank You!</h5>
     </div>
    </>
  );
}