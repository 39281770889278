import { GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_FAILURE, GET_CUSTOMER_PENDING } from "./actionTypes";

const initialState = {
  customers: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function customersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_CUSTOMER_PENDING:
      return { ...state , loading : true };
      
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_CUSTOMERS_FAILURE:
      return { ...state, failed: true, loading: false, error: payload.error };

    default:
      return state;
  }
}

export default customersReducer;
