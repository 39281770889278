import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , FIXED_VALUE_INCENTIVE , PRECENTAGE_VALUE_INCENTIVE } from "../../util";
import { getAccountsRequest } from "../../redux/accounts/actions";
import { getProjectsRequest } from "../../redux/projects/actions";
import { getLocationsRequest } from "../../redux/locations/actions";
import { toast } from "react-toastify";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "bootstrap";


const UpsertLocation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accounts } = useSelector((state) => state.accounts);
  const { projects } = useSelector((state) => state.projects);
  const { permissions } = useSelector((state) => state.user);
  const [initialValues, setInitialValues] = useState({
	  projectId : { 'label' : 'Select' , 'value' : '' },
	  name: "",
	  locality : "",
	  address_line_1 : "",
	  address_line_2 : "",
	  state : "",
	  pincode : "",
  });
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (isActionPermitted("location", "read", permissions)) {
      dispatch(getProjectsRequest());
    }
  }, [permissions]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
    });
  }, [projects]);
 
  const onSubmit = async (values) => {
	  
	  
	  if (id === "add") {
		  if(!values?.projectId?.value){
			  toast.error("Please select square application");
			  return false;
		  }
		  /*if( values?.projectId ){
				values.projectId = values?.projectId?.value;
		  }*/
		  
	  }
	  setIsLoading(true)
	  if (id === "add") {
	      try {
	        const response = await api.post("/locations", {
	          ...values,
	        });
	        if (response.status === 201) {
	          toast.success(response?.data?.message || response.message);
	          navigate("/locations");
	        }
	      } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    } else {
	      try {
	        const response = await api.put("/locations/" + id, {
	          ...values,
	        });
	        if (response.status === 200) {
	          toast.success(response?.data?.message || response.message);
	          navigate("/locations");
	        }
	      } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    }
  };
  
  useEffect(() => {
    if (id !== "add") {
      const getLocation = async (id) => {
    	 setIsLoading(true);
    	 try {
          const response = await api.get("/locations/" + id);
          console.log("response" , response );
          if (response.status === 200) {
        	 var projectSelectedObject = { 'label' : 'Select' , 'value' : '' };
        	 if( response?.data?.data?.Project?.id  ){
        		projectSelectedObject = { "value": response?.data?.data?.Project?.id , "label": response?.data?.data?.Project?.name };
        	 }
        	 console.log("sss");
        	 console.log(response.data.data.name);
        	 setInitialValues({
              ...response.data.data,
              projectId: projectSelectedObject || "",
              name: response.data.data.name || "",
              locality: response.data.data.locality || "",
              address_line_1: response.data.data.address_line_1 || "",
              address_line_2: response.data.data.address_line_2 || "",
              state: response.data.data.state || "",
              pincode: response.data.data.pincode || "",
            });
          }
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        } finally {
          setIsLoading(false);
        }
      };
      getLocation(id);
    }
  }, [id]);

 
  var projectSelection = [];
  projectSelection.push({ 'label' : 'Select' , 'value' : '' });
  projects.map( (_, index) => (
		  projectSelection.push({ "value": _.id, "label": _.name })
  ))
  
   const handleProjectSelection = (selectedOptions,values, setValues) => {
	   setValues({ ...values, projectId: selectedOptions });
	}

  const handleNumericField = (e , values , setValues) => {

	var value = e.target.value;
	value = value.replace(/[^\d]/g, "");
	setValues({ ...values , [e.target.name] : value});
  }
  
  return (
    <React.Fragment>
      <Formik
        validateOnChange
        initialValues={{ ...initialValues }}
        validationSchema={
          Yup.object().shape({
        	  //projectId: Yup.string().required("Please enter first name"),
        	  name: Yup.string().required("Please enter name"),
        	  locality: Yup.string().required("Please enter locality"),
        	  address_line_1: Yup.string().required("Please enter address line 1"),
        	  state: Yup.string().required("Please enter state"),
        	  pincode: Yup.string().required("Please enter pin code"),
           }) 
        }
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, values, touched, setValues }) => (
          <>
            <PageHeader
              shouldShowAddBtn={false}
              title={id === "add" ? "Add Location" : "Edit Location"}
            />
            <Card>
              <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                <i className="bi bi-geo-alt-fill me-2"> </i>
                {id === "add" ? "Create a new location" : "Edit this location"}
              </CardTitle>
              <CardBody>
                <Form>
                  <Row>
	                { ( id === "add" ) && (  
	                  	<Col xl={6}>
		                  <FormGroup>
		                    <Label for="accountId">Square Application</Label>
		                    <Select options={projectSelection} name="projectId" value={values.projectId} 
		                    onChange={(e) => {
		                    	handleProjectSelection(e, values, setValues);
		                    }}
		                    closeMenuOnSelect={true} />
		                    {errors.accountId && touched.accountId && (
		                     <span component="div" className="invalid-feedback">
		                        {errors.accountId}
		                      </span>
		                    )}
		                  </FormGroup>
		                </Col>
	                )}
                  	<Col xl={6}>
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Field id="name" name="name" placeholder="Name" type="text"
                          className={ "form-control" + (errors.name && touched.name ? " is-invalid" : "") }
                        />
                        {errors.name && touched.name && (
                          <span component="div" className="invalid-feedback">
                            {errors.name}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
	                    <FormGroup>
	                      <Label for="name">Address Line 1</Label>
	                      <Field id="address_line_1" name="address_line_1" placeholder="Address Line 1" type="text"
	                        className={ "form-control" + (errors.address_line_1 && touched.address_line_1 ? " is-invalid" : "") }
	                      />
	                      {errors.address_line_1 && touched.address_line_1 && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.address_line_1}
	                        </span>
	                      )}
	                    </FormGroup>
	                </Col>
	                <Col xl={6}>
	                    <FormGroup>
	                      <Label for="name">Address Line 2</Label>
	                      <Field id="address_line_2" name="address_line_2" placeholder="Address Line 2" type="text"
	                        className={ "form-control" + (errors.address_line_2 && touched.address_line_2 ? " is-invalid" : "") }
	                      />
	                      {errors.address_line_2 && touched.address_line_2 && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.address_line_2}
	                        </span>
	                      )}
	                    </FormGroup>
	                </Col>
	                <Col xl={4}>
	                    <FormGroup>
	                      <Label for="name">Locality</Label>
	                      <Field id="locality" name="locality" placeholder="Locality" type="text"
	                        className={ "form-control" + (errors.locality && touched.locality ? " is-invalid" : "") }
	                      />
	                      {errors.locality && touched.locality && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.locality}
	                        </span>
	                      )}
	                    </FormGroup>
	                </Col>
	                <Col xl={4}>
	                    <FormGroup>
	                      <Label for="name">State</Label>
	                      <Field id="state" name="state" placeholder="State" type="text"
	                        className={ "form-control" + (errors.state && touched.state ? " is-invalid" : "") }
	                      />
	                      {errors.state && touched.state && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.state}
	                        </span>
	                      )}
	                    </FormGroup>
	                </Col>
	                <Col xl={4}>
	                    <FormGroup>
	                      <Label for="name">Pin Code</Label>
	                      <Field id="pincode" name="pincode" placeholder="Pin Code" type="text" onChange={(e) => {handleNumericField(e , values , setValues)}}
	                        className={ "form-control" + (errors.pincode && touched.pincode ? " is-invalid" : "") }
	                      />
	                      {errors.pincode && touched.pincode && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.pincode}
	                        </span>
	                      )}
	                    </FormGroup>
	                </Col>
             </Row>
             <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                    <Button
                      color="transaprent"
                      className="border"
                      type="button"
                      onClick={() => navigate("/locations")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading} className="btn btn-primary">
                      {id === "add" ? "Add Location" : "Update Location"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UpsertLocation;
