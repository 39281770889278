import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Input, Row, Col, Label, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup } from "reactstrap";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getClockInSummaryRequest } from "../../redux/employees/actions";
import { api, DB_FORMAT, getFormatOnlyDate, isActionPermitted, USER_TYPES, WORKING_LOG_TIME } from "../../util";
import moment from "moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { toast } from "react-toastify";
import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import "bootstrap";

const ListClockInSummary = () => {

    const formRef = useRef();
    const dispatch = useDispatch();
    const { saloons } = useSelector((state) => state.saloons);
    const { employees, count, loading } = useSelector((state) => state.employees);
    const { role , permissions } = useSelector((state) => state.user);
    const [paginationConfig, setPaginationConfig] = useState({
        page: 1,
        limit: 10,
        filter: {
            name: "",
            from: moment().format(DB_FORMAT.DATE),
            to  : moment().format(DB_FORMAT.DATE),
            saloon:""
        },
    });

    useEffect(() => {
        if( isActionPermitted("employee", "read", permissions) ) {
            dispatch(getSaloonsRequest());
            dispatch(getClockInSummaryRequest(paginationConfig));
        }
    }, [paginationConfig, permissions]);

    const onSearchChange = ({ target }) => {
        const { value } = target;
        const pc = paginationConfig;
        pc.filter.name = value;
        setPaginationConfig({ ...pc,  page: 1, limit: 10 });
    };

    const onDateRangeChange = ({ target }) => {
        const { name, value } = target;
        let pc = paginationConfig;
        if (name === "from") {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        } else {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        }
        setPaginationConfig({ ...pc, page: 1, limit: 10 });
    };

    const [startDate , setStartDate] = useState(paginationConfig.filter.from);
    const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));

    const [showModal , setShowModal] = useState(false);
    const toggle = () => setShowModal(!showModal);
    const [workingLogId , setWorkingLogId] = useState(null);
    const [workingLogTimeType , setWorkingLogTimeType] = useState(null);
    const [startTime , setStartTime] = useState(null);
    const [endTime , setEndTime] = useState(null);
    const [eventTime , setEventTime] = useState(null);

    const openChangeTimeModal = (row , type) => {
        
        setStartTime(row?.startTime);
        setEndTime(row?.endTime);
        setEventTime(type === WORKING_LOG_TIME.START_TIME ? row?.startTime : row?.endTime);
        setWorkingLogId(row?.id);
        setWorkingLogTimeType(type);
        setShowModal(true);
    }

    const onSubmit = async () => {

        if(eventTime == null) {
            toast.error("Please select time");
            return false;
        }

        if( workingLogTimeType == WORKING_LOG_TIME.START_TIME) {
            if(moment(endTime , DB_FORMAT.TIME).utc() < moment(eventTime , DB_FORMAT.TIME).utc()){
                toast.error("Invalid start time");
                return false;
            }
        } else {
            if(moment(startTime , DB_FORMAT.TIME).utc() > moment(eventTime , DB_FORMAT.TIME).utc()){
                toast.error("Invalid end time");
                return false;
            }
        }
        // console.log('on submit')
        const data = {};
        data.id = workingLogId;
        data.type = workingLogTimeType;
        data.previous_time = (workingLogTimeType === WORKING_LOG_TIME.START_TIME ? startTime : endTime);
        data.event_time = eventTime;

        try {
            const response = await api.post('/timesheet/changeWorkingEventTime' , data);
            if(response.status === 200) {
                toast.success(response?.data?.message || response?.message);
                toggle();
                dispatch(getSaloonsRequest());
                dispatch(getClockInSummaryRequest(paginationConfig));
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message);
        }
    }

    const handleTimePickerSelection = (e) => {
        setEventTime(moment( new Date(e) ).format(DB_FORMAT.TIME));
    }

    return (
        <React.Fragment>
            <PageHeader title="Clock In Summary" shouldShowAddBtn={false} />
            <Formik
                validateOnChange
                initialValues={{ event_time : "" }}
                onSubmit={onSubmit}
                enableReinitialize
                innerRef={formRef}
            >

            {({ errors, values, touched, setValues , submitForm }) => (
                <Form>
                    <Modal size="md" centered isOpen={showModal} toggle={toggle}>
                        <ModalHeader toggle={toggle} className="text-capitalize">
                            Change Time
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker 
                                            label={workingLogTimeType === WORKING_LOG_TIME.START_TIME ? "Start Time" : "End Time"}
                                            format="hh:mm A"
                                            name="event_time"
                                            value={eventTime !== null ? dayjs(moment().format(DB_FORMAT.DATE) + eventTime) : null}
                                            onChange={(e) => {
                                                handleTimePickerSelection(e);
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter tag={"div"} className="transparant border-0 bg-light bg-light">
                            <Button
                                type="button"
                                onClick={toggle}
                                color="transprent"
                                className="border"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color={"primary"}
                                className="text-capitalize"
                                onClick={submitForm}
                            >
                                Update
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Form>
            )}
            </Formik>
            <Card>
                <CardBody>
                    <Row className="gy-3 gx-3">
                        {role == USER_TYPES.SUPER_ADMIN && (
                            <Col xl={3} md={3} sm={12} xs={12}>
                                <Label>Salon</Label>
                                <Input caret type="select" name="saloon" value={paginationConfig.filter.saloon}
                                    onChange={(e) => {
                                        let x = paginationConfig;
                                        x.filter.saloon = e.target.value;
                                        setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                    }}
                                >
                                    <option value={""}>All Salon</option>
                                    {saloons.map((_, index) => (
                                        <option key={_.Saloon.id} value={_.Saloon.id}>
                                            {_.Saloon.name}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        <Col xl={3} md={3} sm={12} xs={12}>
                            <Label>Employee</Label>
                            <Input
                                value={paginationConfig.filter.name}
                                type="search"
                                placeholder="Search Employee"
                                role="search"
                                aria-roledescription="search for employee"
                                autoFocus
                                onChange={onSearchChange}
                            />
                        </Col>
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>From</Label>
                            <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
                        </Col>
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>To</Label>
                            <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <DataTable
                isPagination
                count={count}
                loading={loading}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                data={employees}
                columns={[
                    {
                        Header: "Salon Name",
                        // width: "15%",
                        Cell: ({ row }) => {
                        return row?.original?.Saloon?.name;
                        },
                    },
                    {
                        Header: "Employee Name",
                        // width: "15%",
                        Cell: ({ row }) => {
                            return row?.original?.Employee?.firstName + ' ' + row?.original?.Employee?.lastName;
                        },
                    },
                    {
                        Header: "Working Date",
                        // width: "20%",
                        Cell : ({row}) => {
                            return (
                                row?.original?.workingDate ? getFormatOnlyDate(row.original.workingDate) : ''
                            )
                        }
                    },
                    {
                        Header: "Event",
                        accessor: "event",
                        // width: "20%",
                    },
                    {
                        Header: "Start Time",
                        accessor: "startTime",
                        // width: "20%",
                        Cell : ({row}) => {
                            return (
                                <div onClick={() => openChangeTimeModal(row?.original , WORKING_LOG_TIME.START_TIME)}>
                                    {moment(row?.original?.startTime , DB_FORMAT.TIME).format("hh:mm A")}
                                </div>
                            )
                        }
                    },
                    {
                        Header: "End Time",
                        accessor: "endTime",
                        // width: "20%",
                        Cell : ({row}) => {
                            return (
                                <div onClick={() => openChangeTimeModal(row?.original , WORKING_LOG_TIME.END_TIME)}>
                                    {(row?.original?.endTime !== null ? moment(row.original.endTime , DB_FORMAT.TIME).format("hh:mm A") : '-')}
                                </div>
                            )
                        }
                    },
                ]}
            />
        </React.Fragment>
    );
};

export default ListClockInSummary;
