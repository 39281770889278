import { GET_PROJECTS_SUCCESS, GET_PROJECTS_FAILURE, GET_PROJECTS_PENDING } from "./actionTypes";

const initialState = {
  projects: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function projectsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROJECTS_PENDING:
      return { ...state , loading : true };
      
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_PROJECTS_FAILURE:
      return { ...state, projects : [] , failed: true, loading: false, error: payload.error };

    default:
      return state;
  }
}

export default projectsReducer;
