import moment from "moment";
import axios from "axios";
import React, { useState, useRef, useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { getCategoriesRequest, getEmployeesRequest , getEmployeesDropdownListRequest } from "../../redux/actions";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { DB_FORMAT, PAYMENT_STATUS, SOCKET, USER_ROLES, api, getFormatOnlyDate, getLocaleFixedValue, roundOff, socket } from "../../util";
import Loading from "../../components/common/Loading";
import PageHeader from "../../components/common/PageHeader";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { format, startOfWeek, endOfWeek, addDays } from 'date-fns';


const SalesCalendar = () => {
  const user = useSelector((state) => state.user);
  const { saloons } = useSelector((state) => state.saloons);
  const { categories } = useSelector((state) => state.categories);
  const { employees } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [renderRequired , setRenderRequired] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
 
  
  const [eventDetails , setEventDetails] = useState([]);
  const [apiData , setApiData] = useState([]);
  const [apiEmpData , setApiEmpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const calendarRef = useRef(null);
  const [saloonStatus, setSaloonStatus] = useState("active");

  useEffect(() => {
	    if (calendarRef.current) {
	      calendarRef.current.render();
	    }
  }, [calendarRef]);
  
  const [employeeFilter , setEmployeeFilter] = useState({
      filter : {
          working_location : ""
      }
  })
  
  const [saloonFilter , setSaloonFilter] = useState({
      filter : {
          status : saloonStatus
      }
  })
  
  

  const [paginationConfig, setPaginationConfig] = useState({
    location: null,
    status : saloonStatus,
    employee: null,
    category: null,
    date: {
      //from: moment().startOf('month').format(DB_FORMAT.DATE),
      //to  : moment().endOf('month').format(DB_FORMAT.DATE),
      from : '2024-07-29',
      to : '2024-09-09',
    },
  });
  
  useEffect(() => {
    if(renderRequired) {
    	getSalesSummary();
        setRenderRequired(false);
    }
  }, []);

  useEffect(() => {
	dispatch(getSaloonsRequest({ 'filter' :{ 'status' : saloonStatus } }));
	dispatch(getEmployeesDropdownListRequest(employeeFilter));
  } ,[]);

  useEffect(() => {
	  getSalesSummary();
	  dispatch(getSaloonsRequest({ 'filter' :{ 'status' : saloonStatus } })); 
	  dispatch(getEmployeesDropdownListRequest(employeeFilter));
  }, [paginationConfig]);

  /*const fetchData = async() => {
	  try{
		  const response = await axios.get('https://jsonplaceholder.typicode.com/users');
		  console.log("welcome123" , response.data );
		  setApiData(response.data);
	  }catch(error){
		  setError('eeee');
	  } finally{
		  setLoading(false);
	  }
  }*/
  
  const updateEmpData = (empData, name, sales, count) => {
	  const index = empData.findIndex((emp) => emp.emp_name === name);
	  if (index !== -1) {
	    empData[index].sales = (parseFloat(sales) + parseFloat(empData[index].sales)); 
	    empData[index].count = (parseFloat(count) + parseFloat(empData[index].count));
	  } else {
	    empData.push({ emp_name: name, sales: sales, count :count });
	  }
	  return empData;
  };
  
  const getSalesSummary = async () => {
	    try {
	    	console.log("loading" , loading );
	    	if( loading === false ){
	    		console.log("setloader");
	    		//setLoading(true)
	    	}
	    	const elements = document.querySelectorAll('.twt-calendar-td');
	    	  elements.forEach((element) => {
	    	    element.innerHTML = '';
	    	});
	    	var requestUrl = '/dashboard/salesSummary?extraParams=' + JSON.stringify(paginationConfig);
	    	const response = await api.get(requestUrl);
			if(response.status === 200) {
				 const dateWiseDetails = response?.data?.data?.dateWiseDetails;
		    	  const eventInfo = [];
		    	  var weeklySummryInfo = [];
		    	  var weeklyIndex = 1;
		    	  var weeklyTotal = 0;
		    	  var weeklyCount = 0; 
		    	  var weeklyEmpInfo = [];
		    	  
		    	  var loopEmpInfo = [];
		    	  for( const dateWiseDetail of dateWiseDetails){
		    		  var eventTitle = '';
		    		  if( dateWiseDetail.sales_amount > 0 ) {
		    			  var additionalDateClass = moment(dateWiseDetail.date, 'YYYY-MM-DD').format('ddd');
		    			  eventTitle += '<div class="event-display-box '+additionalDateClass.toLowerCase()+' ">';
		    			  eventTitle += '<div class="text-center">';
		    			  var dateAvg = ( dateWiseDetail.sales_amount > 0 ? parseInt( parseFloat(dateWiseDetail.sales_amount) / parseFloat(dateWiseDetail.record_count) ) : 0 );
		    			  eventTitle += ( dateWiseDetail.sales_amount > 0 ? '$' + dateWiseDetail.sales_amount + '(' + dateWiseDetail.record_count + ')-A' +dateAvg : '' );
		    			  eventTitle += ' - <span class="text-danger">' + moment(dateWiseDetail.date, 'YYYY-MM-DD').format('DD MMM') + '<span>' ;
		    			  eventTitle += "</div>"
		        		 
		    			  if( dateWiseDetail.sales_amount > 0 ){
		    				  weeklyTotal = parseFloat(dateWiseDetail.sales_amount) + parseFloat(weeklyTotal);
		    				  weeklyCount = parseInt(dateWiseDetail.record_count) + parseInt(weeklyCount);
		    			  }	  
		    				  
		        		  //eventTitle += ( dateWiseDetail.sales_amount > 0 ? dateWiseDetail.sales_amount + ' (' + sales_amount + ')' : '' );
		        		  if( eventTitle !== "" && eventTitle !== null ){
		        			  var empInfos =  ( dateWiseDetail.sales_amount > 0 ? dateWiseDetail.emp_info : [] );
		            		  var empString = [];
		            		  var empIndex = 1;
		            		  for( const empInfo of empInfos){
		            			  var empAverage = ( ( empInfo.sales_amount  > 0 ) ?  ( parseFloat(empInfo.sales_amount) / parseFloat(empInfo.record_count ) ) : 0 );
		            			  empAverage = parseFloat(empAverage) > 0 ? parseInt(empAverage) : 0;
		            			  empString.push(  + empIndex + '. ' + empInfo.emp_name.substring(0, 4)  + ( empInfo.sales_amount > 0 ? ' ' +  empInfo.sales_amount + '(' + empInfo.record_count + ')' + ' --(' + empAverage +')'  : '' )  );
		            			  empIndex++;
		            			  loopEmpInfo = updateEmpData( loopEmpInfo , empInfo.emp_name.substring(0, 4) , empInfo.sales_amount , empInfo.record_count );
		            		  }
		            		  eventTitle += '\n';
		            		  eventTitle += empString.join('\n') ;
		            		  //console.log("empString" , empString.join('\n') );
		            		  eventTitle += "</div>"
		        			  eventInfo.push({ title: eventTitle , start: dateWiseDetail.date , end: dateWiseDetail.date , extendedProps: { odd: false } });
		        		  }
		    		  } 
		    		  if( weeklyIndex % 7 == 0 ){
		    			  weeklySummryInfo.push({'date' : dateWiseDetail.date , 'sales' : weeklyTotal , 'count' : weeklyCount } );
		    			  weeklyEmpInfo.push({'date' : dateWiseDetail.date , 'emp' : loopEmpInfo } );
		    			  weeklyTotal = 0;
		    			  weeklyCount = 0;
		    			  loopEmpInfo = [];
		    		  }
		    		  weeklyIndex++;
		    	  }
		    	  
		    	  console.log("weeklySummryInfo" , weeklySummryInfo );
		    	  console.log("weeklyEmpInfo" , weeklyEmpInfo );
		    	  
		    	  setApiData(weeklySummryInfo)
				  setApiEmpData(weeklyEmpInfo);
		    	  setEventDetails(eventInfo);
		    	  //reinitializeCalendar();
		    }
	    } catch (error) {
	      toast.error(error.response?.data?.message || error.message);
	    } finally {
	    	setLoading(false);
	    }
	  }
  
  useEffect(() => {
	  if (!socket) return;
	  	socket.emit(SOCKET.EMIT_JOIN, {
	  		roomId: "SUPER_ADMIN",
	        from: {
	          id : user?.id,
	          accountId : user?.accountId,
	          role : (user?.accountId && user.accountId > 0 ? USER_ROLES.ACCOUNT : USER_ROLES.SUPER_ADMIN)
	        }
	  	});

	    socket.on("disconnected", (data) => {
	      //console.log("socket got disconnected on",  moment().format("DD MMM, YY hh:mm:ss"), "because of ", data);
	    });    

	    return () => {
	      socket.off(SOCKET.EMIT_JOIN);
	    };
  }, []);

  useEffect(() => {
	  if (!socket) return;

	  	socket.on(SOCKET.RECEIVE_BILLING_DATA_CHANGE , (data) => {
	      // console.log('billing data change')
	      // console.log(data);
	  		if(data.paymentStatus === PAYMENT_STATUS.SUCCESSFUL) {
	  			// console.log('payment complete')
	  			setRenderRequired(true);
	  			getSalesSummary();
	      }
	  	})
	    return () => {
	      socket.off(SOCKET.RECEIVE_BILLING_DATA_CHANGE);
	    };
  } , [socket]);
	  
  const [currentMonth, setCurrentMonth] = useState(null);
  const handleDatesSet = (arg) => {
	  	const { start, end, view } = arg;
	    const month = start.getMonth();
	    setStartDate(moment(new Date(start)).format('YYYY-MM-DD'));
	    setEndDate(moment(new Date(end)).format('YYYY-MM-DD'));
	    setCurrentMonth(month);
	    
	    let pc = paginationConfig;
	    pc.date["from"] = moment(new Date(start)).format('YYYY-MM-DD');
	    pc.date["to"] = moment(new Date(end)).format('YYYY-MM-DD');
	    setPaginationConfig({ ...pc  });
	    setRenderRequired(true);
	    //getSalesSummary();
  };
	  
  const EventColor = (eventInfo) => {
	  //console.log("eventInfo" , eventInfo ); 
	  if (eventInfo.event.extendedProps.odd) {
	    return {
	      backgroundColor: 'red', // or any other color you want
	    };
	  }
	  return {};
  };
  
  useEffect(() => {
	  //getSalesSummary();
  }, []);

  
 
  
  	const handleDayHeaderDidMount = (dayHeaderElement) => {
	const date = dayHeaderElement.date;
	    if( dayHeaderElement.date.getDay() === 6 ){
	    	var weeklySummaryHeader = "";
	    	weeklySummaryHeader += '<div class="twt-calendar border-bottom text-center">';
	    	weeklySummaryHeader += '<span class="blue-color">Summary</span>';
	    	weeklySummaryHeader += '</div>'
	    	dayHeaderElement.el.insertAdjacentHTML('afterend' , weeklySummaryHeader);
	    }
	    
	 };
	  
	  const handleDayCellDidMount = (info) => {
		  
		  if( info.date.getDay() === 0 ){
			  var weekEndDate = moment( info.date.toISOString() ).format('YYYY-MM-DD') ;
			  
			  const findWeekIndex = apiData.findIndex((emp) => emp.date == weekEndDate);
			  //console.log("apiData" , apiData ); 
			  //console.log("eventDetails" , eventDetails );
			  var summaryTitle = '<div class="twt-calendar only1-border-bottom">';
			  if (findWeekIndex !== -1) {
				  var weekSale = apiData[findWeekIndex].sales;
				  var weekCount = apiData[findWeekIndex].count;
				  var weekAvg = ( weekSale > 0 ? parseFloat(weekSale) / parseFloat(weekCount) : 0 );
				  weekAvg = (weekAvg > 0 ? parseInt(weekAvg) : 0 );
				  if( weekSale > 0 ){
					  summaryTitle += '<div class="event-display-box">';
					  summaryTitle += '<div class="text-center">';
					  summaryTitle += ( apiData[findWeekIndex].sales > 0 ? '$ ' + apiData[findWeekIndex].sales + ' (' + apiData[findWeekIndex].count + ')' : '' );
					  summaryTitle += "</div>"
					  
					  //console.log("weekEndDate" , weekEndDate )
					  //console.log("findWeekIndex" , findWeekIndex );
					  //console.log("setApiData" , apiData );
				  }
				  
			  }
			  
			  const findEmpWeekIndex = apiEmpData.findIndex((emp) => emp.date == weekEndDate);
			  if (findEmpWeekIndex !== -1) {
				  var weekEmpInfos =   apiEmpData[findEmpWeekIndex].emp ;
        		  var weekEmpString = [];
        		  var weekEmpIndex = 1;
        		  for( const weekEmpInfo of weekEmpInfos){
        			  var weekEmpAverage = ( ( weekEmpInfo.sales  > 0 ) ?  ( parseFloat(weekEmpInfo.sales) / parseFloat(weekEmpInfo.count ) ) : 0 );
        			  weekEmpAverage = parseFloat(weekEmpAverage) > 0 ? parseInt(weekEmpAverage) : 0;
        			  weekEmpString.push(  + weekEmpIndex + '. ' + weekEmpInfo.emp_name.substring(0, 4)  + ( weekEmpInfo.sales > 0 ? ' ' +  parseInt(weekEmpInfo.sales) + '(' + weekEmpInfo.count + ')' + ' --(' + weekEmpAverage +')'  : '' )  );
        			  weekEmpIndex++;
        			}
        		  summaryTitle += '<br>';
        		  summaryTitle += weekEmpString.join('\n') ;
        		  summaryTitle += "</div>"
				  
			  }
			  summaryTitle += "</div>"
			  //console.log("sss" , apiEmpData );
			  info.el.insertAdjacentHTML('afterend' , '<div class="twt-calendar only1-border-bottom"></div>');
		  }
		  /*
		  if( info.date.getDay() === 0 ){
		    	console.log("inside date",info.date);
		    	var weekEndDate = moment( info.date.toISOString() ).format('YYYY-MM-DD') ;
		    	
		    		
		    		const findWeekIndex = miteshInfo.findIndex((emp) => emp.date == weekEndDate);
			    	console.log('dddd' , moment( info.date.toISOString() ).format('YYYY-MM-DD') );
			    	console.log("findWeekIndex" , findWeekIndex );
		    	
		    	
		    	 info.el.insertAdjacentHTML('afterend' , 100);
		    }
		    */
	  };
	  
	  
		  
		  
		 
  
	  if(loading){
		  return <div>loading....</div>
	  }
	  
	  if(error){
		  return <div>error....</div>
	  }
	  
	  const handleEventDidMount = (event) => {
		  //console.log("handleEventDidMounthandleEventDidMounthandleEventDidMounthandleEventDidMounthandleEventDidMount");
		  //console.log("handleEventDidMount apiData" , apiData );
		  //console.log("handleEventDidMount apiEmpData" , apiEmpData );
		    // Update the day cell content here
		  	console.log("apiData" , apiData );
		  	console.log("apiEmpData" , apiEmpData );
		  	for( const apiWeekData of apiData){
		    	const dayCell = document.querySelector(`[data-date="${apiWeekData.date}"]`);
			  	if (dayCell) {
			    	const nextTd = dayCell.nextElementSibling;
			    	
			    	var summaryTitle = '<div class="twt-calendar twt-calendar-td only1-border-bottom">';
					  	var weekSale = apiWeekData.sales;
						  var weekCount = apiWeekData.count;
						  var weekAvg = ( weekSale > 0 ? parseFloat(weekSale) / parseFloat(weekCount) : 0 );
						  weekAvg = (weekAvg > 0 ? parseInt(weekAvg) : 0 );
						  if( weekSale > 0 ){
							  summaryTitle += '<div class="event-display-box">';
							  summaryTitle += '<div class="text-center">';
							  summaryTitle += ( weekSale > 0 ? '$ ' + parseInt(weekSale) + ' (' + weekCount + ')-A' +weekAvg : '' );
							  summaryTitle += "</div>"
						 }
						  
					  
					  const findEmpWeekIndex = apiEmpData.findIndex((emp) => emp.date == apiWeekData.date);
					  if (findEmpWeekIndex !== -1) {
						  var weekEmpInfos =   apiEmpData[findEmpWeekIndex].emp ;
		        		  var weekEmpString = [];
		        		  var weekEmpIndex = 1;
		        		  for( const weekEmpInfo of weekEmpInfos){
		        			  var weekEmpAverage = ( ( weekEmpInfo.sales  > 0 ) ?  ( parseFloat(weekEmpInfo.sales) / parseFloat(weekEmpInfo.count ) ) : 0 );
		        			  weekEmpAverage = parseFloat(weekEmpAverage) > 0 ? parseInt(weekEmpAverage) : 0;
		        			  weekEmpString.push(  + weekEmpIndex + '. ' + weekEmpInfo.emp_name.substring(0, 4)  + ( weekEmpInfo.sales > 0 ? ' ' +  parseInt(weekEmpInfo.sales) + '(' + weekEmpInfo.count + ')' + ' --(' + weekEmpAverage +')'  : '' )  );
		        			  weekEmpIndex++;
		        			}
		        		  summaryTitle += '<br>';
		        		  summaryTitle += weekEmpString.join('<br>') ;
		        		  summaryTitle += "</div>"
						  
					  }
					  
					  summaryTitle += "</div>"
			    	if( nextTd ){
			    		nextTd.innerHTML = summaryTitle;
			    	}
			    	
			    }
		    } 
		  };
		  /*
		  const [calendarKey, setCalendarKey] = useState(1);

		  // Function to force re-render
		  const reinitializeCalendar = () => {
		    setCalendarKey(prevKey => prevKey + 1); // Change the key to trigger re-render
		  };
		  */

		  
		  
  return (
    <React.Fragment>
    	{ 
    	<PageHeader title="Sales Summary" shouldShowAddBtn={false} />
    	}
    	<Card>
        <CardBody>
          <Row xxl={5} xl={5} md={3} sm={2} xs={1} className="gy-3 gx-3">
	          <Col>
	          <Label>Status</Label>
	          <Input caret type="select" name="status" onChange={(e) => {
	        	    console.log("sss" , e.target.value );
	            	setSaloonStatus(e.target.value);
	            	
		            let x = paginationConfig;
		            x.status = e.target.value;
		            if( e.target.value !== "" && e.target.value !== null ){
  		            	x.location = null;
  		            }
		            setPaginationConfig({ ...x });
	            }}
	            >
	            <option value={""}>All</option>
	            <option value={"active"} selected>Active</option>
	            <option value={"inactive"}>Inactive</option>
	          </Input>
	        </Col>
          	<Col>
              <Label>Location</Label>
              <Input
                caret
                type="select"
                name="location"
                value={paginationConfig.location}
                onChange={(e) => {
                	const ef = employeeFilter;
                	ef.filter.working_location = e.target.value;
                	setEmployeeFilter({ ...ef });
                  let x = paginationConfig;
                  x.location = e.target.value;
                  setPaginationConfig({ ...x });
                }}
              >
                <option value={""}>All Location</option>
                {saloons.map((_, index) => (
                  <option key={_.id} value={_.Saloon.id}>
                     {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                  </option>
                ))}
              </Input>
            </Col>
            <Col>
              <Label>Employee</Label>
              <Input
                caret
                type="select"
                name="location"
                value={paginationConfig.employee}
                onChange={(e) => {
                	
                  let x = paginationConfig;
                  x.employee = e.target.value;
                  setPaginationConfig({ ...x });
                }}
              >
                <option value={""}>All Employees</option>
                {employees.map( employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.firstName + " " + employee.lastName}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
      <CardBody> 
      <Row xxl={12} xl={12} md={12} sm={12} xs={12} className="mb-4">
	      <FullCalendar
		      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
	      firstDay={1}
	      ref={calendarRef}
	      	  initialView='dayGridMonth'
		      dayHeaderDidMount={handleDayHeaderDidMount}  
	      	  dayCellDidMount={handleDayCellDidMount}
	      eventDidMount={handleEventDidMount}
	      events={eventDetails}
      	  datesSet={handleDatesSet}
      	  navLinks={true}
      	  
	  	  eventContent={(event) => (
	              <div
	                dangerouslySetInnerHTML={{
	                  __html: event.event.title.replace(/\n/g, '<br>'),
	                }}
	              />
          )}
			      
		 />
	      
	  </Row>
	  
	  </CardBody>
	  </Card>

      
      
     
    </React.Fragment>
  );
};

export default SalesCalendar;
