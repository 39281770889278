export const GET_EMPLOYEES_REQUEST = "GET_EMPLOYEES_REQUEST";
export const GET_EMPLOYEES_LIST_REQUEST = "GET_EMPLOYEES_LIST_REQUEST";
export const GET_EMPLOYEES_DROPDOWN_LIST_REQUEST = "GET_EMPLOYEES_DROPDOWN_LIST_REQUEST";
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";
export const GET_EMPLOYEE_PENDING = "GET_EMPLOYEE_PENDING";
export const GET_LATE_EMPLOYEES_REQUEST = "GET_LATE_EMPLOYEES_REQUEST";
export const GET_TODAY_LATE_CLOCK_OUT = "GET_TODAY_LATE_CLOCK_OUT";
export const GET_CLOCK_IN_SUMMARY_REQUEST = "GET_CLOCK_IN_SUMMARY_REQUEST";

// EMPLOYEE LOGIN 

export const EMPLOYEE_LOGIN_REQUEST = "EMPLOYEE_LOGIN_REQUEST"
export const EMPLOYEE_LOGIN_SUCCESS = "EMPLOYEE_LOGIN_SUCCESS"
export const EMPLOYEE_LOGIN_FAILURE = "EMPLOYEE_LOGIN_FAILURE"