import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Input , Row , Col , Label, Button, FormGroup } from "reactstrap";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getSubscribedCustomersRequest } from "../../redux/customers/actions";
import { api, isActionPermitted , SELECTION, USER_TYPES } from "../../util";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const SMSQueue = () => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [initialValues, setInitialValues] = useState({
    content: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setInitialValues({
        ...initialValues,
    });
  }, []);

  const { customers, count, loading } = useSelector((state) => state.customers);
  const { saloons } = useSelector((state) => state.saloons);
  const { role, permissions } = useSelector((state) => state.user);
  const [paginationConfig, setPaginationConfig] = useState({
	  filter: {
	      name: "",
	      location: "",
          mobileSubscribe: SELECTION.YES,
	  },
	  page: 1,
	  limit: 10,
  });

  useEffect(() => {
    if (role === USER_TYPES.SUPER_ADMIN) {
      dispatch(getSaloonsRequest());
    }
    if (isActionPermitted("customers-check-in", "read", permissions)) {
      dispatch(getSubscribedCustomersRequest(paginationConfig));
      resetCustomerSelection();
    }
  }, [dispatch, paginationConfig , permissions , role]);

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  const onSubmit = async (data) => {
    if(!data?.content) {
        toast.error("Please enter SMS content");
        return;
    }
    if(!customers || !customers?.length || !customerSelection?.length) {
        toast.error("Please select at least one customer");
        return;
    }
    setIsLoading(true);
    // data.customer = paginationConfig.filter.name;
    data.customerSelection = customerSelection.join(',');
    // data.location = paginationConfig.filter.location;
    try {
        const response = await api.post("/customers" , {
            ...data,
        });
        if (response.status === 200) {
            toast.success(response?.data?.message || response.message);
            formikRef.current?.resetForm();
            resetCustomerSelection();
        }
    } catch (error) {
        toast.error(error.response?.data?.message || error.message);
    } finally {
        setIsLoading(false);
    }
  }

  const [customerSelection, setCustomerSelection] = useState([]);

  const handleSelection = (e) => {
    const dataFieldId = e.target.getAttribute('data-field-id');
    const selectedCustomers = customerSelection;
    if(e.target?.checked) {
      selectedCustomers.push(dataFieldId);
    } else {
      var dataKey = selectedCustomers.indexOf(dataFieldId);
      if( dataKey !== -1 ) {
        selectedCustomers.splice(dataKey, 1);
      }
    }
    setCustomerSelection(selectedCustomers);
    document.querySelector('.customer-select-all').checked = false;
  }

  const selectAllCustomers = (e) => {
    const selectedCustomers = customerSelection;
    if(e.target?.checked) {
      document.querySelectorAll(".customer-selection").forEach(e => {
        e.checked = true;
        selectedCustomers.push(e.getAttribute('data-field-id'));
      })
      setCustomerSelection(selectedCustomers);
    } else {
      setCustomerSelection([]);
      document.querySelectorAll(".customer-selection").forEach(e => {
        e.checked = false;
      })
    }
  }

  const resetCustomerSelection = () => {
    setCustomerSelection([]);
    document.querySelector('.customer-select-all').checked = false;
  }

  return (
    <React.Fragment>
      <PageHeader title="SMS Queue" shouldShowAddBtn={false} />
      <Card>
      	<CardBody>
      		<Row className="gy-3 gx-3">
      			<Col xl={3} md={3} sm={12} xs={12}>
      				<Label>Customer</Label>
	        		<Input value={paginationConfig.filter.name} type="search" placeholder="Search Customer" role="search" aria-roledescription="search for Customer" autoFocus onChange={onSearchChange}/>
	        	</Col>
          		{role === USER_TYPES.SUPER_ADMIN && (
                    <Col xl={3} md={3} sm={12} xs={12}>
                        <Label>Location</Label>
                        <Input caret type="select" name="location" value={paginationConfig.filter.location}
                        onChange={(e) => {
                            let x = paginationConfig;
                            x.filter.location = e.target.value;
                            setPaginationConfig({ ...x,  page: 1, limit: 10 });
                        }}
                        >
                        <option value={""}>All Location</option>
                        {saloons.map((_, index) => (
                            <option key={_.id} value={_.Saloon.id}>
                            {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                            </option>
                        ))}
                        </Input>
                    </Col>
                )}
        	</Row>
	     </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={customers}
        title={
          <>
          <label>Select All &nbsp;</label>
          <input type="checkbox" className="customer-select-all form-check-input" onClick={selectAllCustomers} />
          </>
        }
        columns={[
          { 
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => {
              return (
                <>
                  <input type="checkbox" className="form-check-input customer-selection" data-field-id={row?.original?.id} onChange={handleSelection} />
                  {` ${(row?.original?.name)}`}
                </>
              )
            }
          },
          { Header: "Mobile", accessor: "mobile"},
          {
            Header: "Location",
            Cell: ({ row }) => {
                return `${(row?.original?.Saloon?.name)}${(row?.original?.Saloon?.location && row?.original?.Saloon?.location != null) ? ' - ' + row?.original?.Saloon?.location : ''}`;
            },
          }
        ]}
      />
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={
          Yup.object().shape({
            content: Yup.string().required("Please enter SMS content"),
          }) 
        }
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ errors, touched }) => (
            <Card>
                <CardBody>
                    <Form>
                        <Row>
                            <Col xl={12}>
                                <FormGroup>
                                    <Label for="content">SMS Content</Label>
                                    <Field
                                        id="content"
                                        name="content"
                                        placeholder="SMS Content"
                                        type="textarea"
                                        className={
                                            "form-control" +
                                            (errors.content && touched.content ? " is-invalid" : "")
                                        }
                                        component="textarea"
                                        rows="5"
                                    />
                                    {errors.content && touched.content && (
                                        <span component="div" className="invalid-feedback">
                                            {errors.content}
                                        </span>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                            <Button type="submit" disabled={isLoading} className="btn btn-primary">
                                Send
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SMSQueue;
