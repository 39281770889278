import { 
  GET_EMPLOYEES_FAILURE, 
  GET_EMPLOYEES_SUCCESS,
  EMPLOYEE_LOGIN_FAILURE,
  EMPLOYEE_LOGIN_SUCCESS, 
  GET_EMPLOYEE_PENDING,
} from "./actionTypes";

const initialState = {
  employees: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function employeesReducer(state = initialState, { type, payload }) {
  //console.log("employeesReducertype" , type );	
  switch (type) {
    
    // LOGIN
    
    case EMPLOYEE_LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
        failed: false,
        loading: false,
      };

    case EMPLOYEE_LOGIN_FAILURE:
      return { ...state, failed: true, loading: false, error: payload.error };

    // EMPLOYEE 

    case GET_EMPLOYEE_PENDING:
      return { ...state , loading : true };

    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        failed: true,
        employees: [],
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
}

export default employeesReducer;
