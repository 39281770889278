import { GET_ACCOUNTS_SUCCESS, GET_ACCOUNTS_FAILURE, GET_ACCOUNT_PENDING } from "./actionTypes";

const initialState = {
  accounts: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function accountsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_ACCOUNT_PENDING: 
      return { ...state , loading : true };

    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_ACCOUNTS_FAILURE:
      return { ...state, failed: true, loading: false, error: payload.error };

    default:
      return state;
  }
}

export default accountsReducer;
