import { combineReducers } from "redux";
import userReducer from "./auth/reducer";
import billsReducer from "./bills/reducer";
import categoriesReducer from "./categories/reducer";
import employeesReducer from "./employees/reducer";
import saloonsReducer from "./saloons/reducer";
import servicesReducer from "./services/reducer";
import socketReducer from "./socket/reducer";
import customersReducer from "./customers/reducer";
import accountsReducer from "./accounts/reducer";
import projectsReducer from "./projects/reducer";
import locationsReducer from "./locations/reducer";
import schedulesReducer from "./schedules/reducer";
import reportsReducer from "./report/reducer";
import usersReducer from "./users/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  services: servicesReducer,
  saloons: saloonsReducer,
  categories: categoriesReducer,
  bills: billsReducer,
  socket: socketReducer,
  employees: employeesReducer,
  customers: customersReducer,
  accounts: accountsReducer,
  projects: projectsReducer,
  locations: locationsReducer,
  schedules: schedulesReducer,
  reports: reportsReducer,
  users: usersReducer,
});

export default rootReducer;
