import {
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_PENDING,
  GET_SUBSCRIBED_CUSTOMERS_REQUEST,
} from "./actionTypes";

// NOTES

export const getCustomersRequest = (payload) => {
  return {
    type: GET_CUSTOMERS_REQUEST,
    payload,
  };
};

export const getSubscribedCustomersRequest = (payload) => {
  return {
    type: GET_SUBSCRIBED_CUSTOMERS_REQUEST,
    payload,
  };
};

export const getCustomersSuccess = (payload) => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload,
  };
};

export const getCustomersFailure = (payload) => {
  return {
    type: GET_CUSTOMERS_FAILURE,
    payload,
  };
};

export const getCustomersPending = () => {
  return {
    type : GET_CUSTOMER_PENDING,
  }
}
