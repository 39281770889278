import {
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_PENDING,
} from "./actionTypes";

// NOTES

export const getUsersRequest = (payload) => {
  return {
    type: GET_USERS_REQUEST,
    payload,
  };
};

export const getUsersSuccess = (payload) => {
  return {
    type: GET_USERS_SUCCESS,
    payload,
  };
};

export const getUsersFailure = (payload) => {
  return {
    type: GET_USERS_FAILURE,
    payload,
  };
};

export const getUsersPending = () => {
  return {
    type : GET_USER_PENDING,
  }
}
