import { takeLatest, call, put } from "redux-saga/effects";
import { getUsersApiCall } from "./apiCalls";
import { getUsersFailure, getUsersPending, getUsersSuccess } from "./actions";
import { GET_USERS_REQUEST } from "./actionTypes";

function* getUsersWorker({ payload }) {
  try {
    yield put(getUsersPending());
    const response = yield call(getUsersApiCall, payload);
    yield put(getUsersSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getUsersFailure({ error }));
  }
}

export function* usersWatcher() {
  yield takeLatest(GET_USERS_REQUEST, getUsersWorker);
}
