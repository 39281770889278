import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Input, Row, Col, Label } from "reactstrap";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getLateEmployeesRequest , getTodayLateClockOutRequest } from "../../redux/employees/actions";
import { api, DB_FORMAT, getFormatOnlyDate, isActionPermitted, LATE_STATUS, USER_TYPES } from "../../util";
import moment from "moment/moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { toast } from "react-toastify";

const TodayLateClockOut = () => {
  const dispatch = useDispatch();
  const { saloons } = useSelector((state) => state.saloons);
  const { employees, count, loading } = useSelector((state) => state.employees);
  const { role , permissions } = useSelector((state) => state.user);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
        name: "",
        from: moment().format(DB_FORMAT.DATE),
	    to  : moment().format(DB_FORMAT.DATE),
        saloon:""
    },
    // sort: "name",
  });

  useEffect(() => {
    if( isActionPermitted("employee", "read", permissions)) {
      dispatch(getSaloonsRequest());
      dispatch(getTodayLateClockOutRequest(paginationConfig));
    }
  }, [paginationConfig, permissions]);

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  const onDateRangeChange = ({ target }) => {
        const { name, value } = target;
        let pc = paginationConfig;
        if (name === "from") {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        } else {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        }
        setPaginationConfig({ ...pc, page: 1, limit: 10 });
  };

  const [startDate , setStartDate] = useState(paginationConfig.filter.from);
  const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));
 
  const allUpdateTimeValues = []; 
 
  const handleUpdateTimeChange = (id, value) => {
	  console.log("id" , id );
	  console.log("value" , value );
	  const findRowIndex = allUpdateTimeValues.findIndex((allUpdateTimeValue) => allUpdateTimeValue.id === id);
	  if (findRowIndex !== -1) {
		  allUpdateTimeValues[findRowIndex].value = value;
	  } else {
		  allUpdateTimeValues.push({'id' : id , 'value' : value })  
	  }
	  console.log("allUpdateTimeValues" , allUpdateTimeValues );
	  
	  //setRows(rows.map(row => (row.id === id ? { ...row, newValue: value } : row)));
  };

  
  
  const handleUpdateTimeClick = async (recordId) => {
	    const data = {};
	    data.id = recordId;
	    const findRowIndex = allUpdateTimeValues.findIndex((allUpdateTimeValue) => allUpdateTimeValue.id === recordId);
		if (findRowIndex !== -1) {
			data.update_minutes = allUpdateTimeValues[findRowIndex].value;
		}
		console.log("data" , data );
		try {
	        const response = await api.post('timesheet/updateEmpClockOutTime' , data);
	        if(response.status === 200) {
	            if(response?.data?.code === 200) {
	                toast.success(response?.data?.message || "Clock Out updated successfully");
	                dispatch(getSaloonsRequest());
	                dispatch(getTodayLateClockOutRequest(paginationConfig));
	            } else {
	                toast.error(response?.data?.message || "Status was not changed");
	            }
	        }
	    } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	    }
	    
  }

  return (
    <React.Fragment>
      <PageHeader title="Today Late Clock Out" shouldShowAddBtn={false} />
      <Card>
        <CardBody>
            <Row className="gy-3 gx-3">
                {role == USER_TYPES.SUPER_ADMIN && (
                    <Col xl={3} md={3} sm={12} xs={12}>
                        <Label>Salon</Label>
                        <Input caret type="select" name="saloon" value={paginationConfig.filter.saloon}
                            onChange={(e) => {
                                let x = paginationConfig;
                                x.filter.saloon = e.target.value;
                                setPaginationConfig({ ...x,  page: 1, limit: 10 });
                            }}
                        >
                            <option value={""}>All Salon</option>
                            {saloons.map((_, index) => (
                                <option key={_.Saloon.id} value={_.Saloon.id}>
                                    {_.Saloon.name}
                                </option>
                            ))}
                        </Input>
                    </Col>
                )}
                <Col xl={3} md={3} sm={12} xs={12}>
                    <Label>Employee</Label>
                    <Input
                        value={paginationConfig.filter.name}
                        type="search"
                        placeholder="Search Employee"
                        role="search"
                        aria-roledescription="search for employee"
                        autoFocus
                        onChange={onSearchChange}
                    />
                </Col>
                <Col xl={3} md={3} sm={6} xs={6}>
                    <Label>From</Label>
                    <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
                </Col>
                <Col xl={3} md={3} sm={6} xs={6}>
                    <Label>To</Label>
                    <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
                </Col>
            </Row>
        </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={employees}
        columns={[
            {
                Header: "Salon Name",
                width: "15%",
                Cell: ({ row }) => {
                  return row?.original?.Saloon?.name;
                },
            },
            {
                Header: "Employee Name",
                width: "15%",
                Cell: ({ row }) => {
                    return row?.original?.Employee?.firstName + ' ' + row?.original?.Employee?.lastName;
                },
            },
            {
                Header: "Working Date",
                // accessor: "workingDate",
                width: "20%",
                Cell : ({row}) => {
                    return (
                        row?.original?.workingDate ? getFormatOnlyDate(row.original.workingDate) : ''
                    )
                }
            },
            
            {
                Header: "Clock Out Time",
                // accessor: "endTime",
                width: "10%",
                Cell : ({row}) => {
                    return (
                        row?.original?.endTime ? moment(row.original.endTime , DB_FORMAT.TIME).format('hh:mm A') : ''
                    )
                }
            },
            
            {
                Header: "Inc Time",
                accessor: "inctime",
                Cell: ({ row }) => {
                    return (
                        <>
                            <input type="number" min="0" max="45" step="1" className="form-control" onChange={(e) => handleUpdateTimeChange(row?.original?.id, e.target.value)}/>
                        </>
                    );
                },
            },
            {
                Header: "Actions",
                accessor: "actions",
                Cell: ({ row }) => {
                    return (
                        <>
                            {row?.original?.saloonId > 0 && (
                                <div className="d-flex gap-2">
                                    <button type="button" className="btn btn-primary" onClick={() => handleUpdateTimeClick(row?.original?.id)}>Update</button>
                                </div>
                            )}
                        </>
                    );
                },
            }
        ]}
      />
    </React.Fragment>
  );
};

export default TodayLateClockOut;
