import {
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_PENDING,
} from "./actionTypes";

// NOTES

export const getLocationsRequest = (payload) => {
  return {
    type: GET_LOCATIONS_REQUEST,
    payload,
  };
};

export const getLocationsSuccess = (payload) => {
  return {
    type: GET_LOCATIONS_SUCCESS,
    payload,
  };
};

export const getLocationsFailure = (payload) => {
  return {
    type: GET_LOCATIONS_FAILURE,
    payload,
  };
};

export const getLocationsPending = () => {
  return {
    type : GET_LOCATIONS_PENDING,
  }
}
