import { takeLatest, call, put } from "redux-saga/effects";
import { getProjectsApiCall } from "./apiCalls";
import { getProjectsFailure, getProjectsPending, getProjectsSuccess } from "./actions";
import { GET_PROJECTS_REQUEST } from "./actionTypes";

function* getProjectsWorker({ payload }) {
  try {
    yield put(getProjectsPending());
    const response = yield call(getProjectsApiCall, payload);
    yield put(getProjectsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getProjectsFailure({ error }));
  }
}

export function* projectsWatcher() {
  yield takeLatest(GET_PROJECTS_REQUEST, getProjectsWorker);
}
