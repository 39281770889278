import { takeLatest, call, put } from "redux-saga/effects";
import { getCommissionDetailedReportApiCall, getHourlyDetailedReportApiCall, getPayrollReportApiCall, getTipsDetailedReportApiCall, getScheduleDetailedReportApiCall } from "./apiCalls";
import { getReportsFailure, getReportsPending, getReportsSuccess } from "./actions";
import { GET_COMMISSION_DETAILED_REPORT_REQUEST, GET_HOURLY_DETAILED_REPORT_REQUEST, GET_PAYROLL_REPORT_REQUEST, GET_TIPS_DETAILED_REPORT_REQUEST, GET_SCHEDULE_DETAILED_REPORT_REQUEST } from "./actionTypes";

function* getPayrollReportWorker({ payload }) {
  try {
    yield put(getReportsPending());
    const response = yield call(getPayrollReportApiCall, payload);
    yield put(getReportsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getReportsFailure({ error }));
  }
}

function* getCommissionDetailedReportWorker({ payload }) {
  try {
    yield put(getReportsPending());
    const response = yield call(getCommissionDetailedReportApiCall, payload);
    yield put(getReportsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getReportsFailure({ error }));
  }
}

function* getTipsDetailedReportWorker({ payload }) {
  try {
    yield put(getReportsPending());
    const response = yield call(getTipsDetailedReportApiCall, payload);
    yield put(getReportsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getReportsFailure({ error }));
  }
}

function* getHourlyDetailedReportWorker({ payload }) {
  try {
    yield put(getReportsPending());
    const response = yield call(getHourlyDetailedReportApiCall, payload);
    yield put(getReportsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getReportsFailure({ error }));
  }
}

function* getScheduleDetailedReportWorker({ payload }) {
  try {
    yield put(getReportsPending());
    const response = yield call(getScheduleDetailedReportApiCall, payload);
    yield put(getReportsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getReportsFailure({ error }));
  }
}

export function* reportsWatcher() {
  yield takeLatest(GET_PAYROLL_REPORT_REQUEST, getPayrollReportWorker);
  yield takeLatest(GET_COMMISSION_DETAILED_REPORT_REQUEST, getCommissionDetailedReportWorker);
  yield takeLatest(GET_TIPS_DETAILED_REPORT_REQUEST, getTipsDetailedReportWorker);
  yield takeLatest(GET_HOURLY_DETAILED_REPORT_REQUEST, getHourlyDetailedReportWorker);
  yield takeLatest(GET_SCHEDULE_DETAILED_REPORT_REQUEST, getScheduleDetailedReportWorker);
}
