import { takeLatest, call, put } from "redux-saga/effects";
import { getCustomersApiCall, getSubscribedCustomersApiCall } from "./apiCalls";
import { getCustomersFailure, getCustomersPending, getCustomersSuccess } from "./actions";
import { GET_CUSTOMERS_REQUEST, GET_SUBSCRIBED_CUSTOMERS_REQUEST } from "./actionTypes";

function* getCustomersWorker({ payload }) {
  try {
    yield put(getCustomersPending());
    const response = yield call(getCustomersApiCall, payload);
    yield put(getCustomersSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getCustomersFailure({ error }));
  }
}

function* getSubscribedCustomersWorker({ payload }) {
  try {
    yield put(getCustomersPending());
    const response = yield call(getSubscribedCustomersApiCall, payload);
    yield put(getCustomersSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getCustomersFailure({ error }));
  }
}

export function* customersWatcher() {
  yield takeLatest(GET_CUSTOMERS_REQUEST, getCustomersWorker);
  yield takeLatest(GET_SUBSCRIBED_CUSTOMERS_REQUEST, getSubscribedCustomersWorker);
}
