import { takeLatest, call, put } from "redux-saga/effects";
import { getAccountsApiCall } from "./apiCalls";
import { getAccountsFailure, getAccountsPending, getAccountsSuccess } from "./actions";
import { GET_ACCOUNTS_REQUEST } from "./actionTypes";

function* getAccountsWorker({ payload }) {
  try {
    yield put(getAccountsPending());
    const response = yield call(getAccountsApiCall, payload);
    yield put(getAccountsSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    yield put(getAccountsFailure({ error }));
  }
}

export function* accountsWatcher() {
  yield takeLatest(GET_ACCOUNTS_REQUEST, getAccountsWorker);
}
