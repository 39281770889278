import React, {useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  Table,
} from "reactstrap";
import { DB_FORMAT, getFormatOnlyDate, isActionPermitted,PAYMENT_STATUS,  SOCKET, USER_TYPES, USER_ROLES, roundOff, exportExcel, socket } from "../../util";
import { getScheduleDetailedReportRequest } from "../../redux/report/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { getEmployeesRequest } from "../../redux/employees/actions";
import PageHeader from "../../components/common/PageHeader";
import NoDataFound from "../../components/common/NoDataFound";
import Loading from "../../components/common/Loading";

const ScheduleDetailedReport = () => {
	const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const { permissions , role } = useSelector((state) => state.user);
    const { saloons } = useSelector((state) => state.saloons);
    const { employees } = useSelector((state) => state.employees);
    const { reports , count , loading } = useSelector((state) => state.reports);
    const [saloonStatus, setSaloonStatus] = useState("active");
    const [paginationConfig, setPaginationConfig] = useState({
        filter: {
            from:  moment().format(DB_FORMAT.DATE),
            to:  moment().format(DB_FORMAT.DATE),
            location: "",
            employee: "",
            status : saloonStatus,
        },
        page: 1,
        limit: 10,
    });
    const [employeeFilter , setEmployeeFilter] = useState({
        filter : {
            working_location : ""
        }
    })
     const [saloonFilter , setSaloonFilter] = useState({
      filter : {
          status : saloonStatus
      }
    })

    useEffect(() => {
        if (isActionPermitted("schedule-detailed-report", "read", permissions)) {
            setDataFound(false);
            if(role == USER_TYPES.SUPER_ADMIN) {
                dispatch(getSaloonsRequest({ 'filter' :{ 'status' : saloonStatus } }));
            }
            dispatch(getEmployeesRequest(employeeFilter));
            dispatch(getScheduleDetailedReportRequest(paginationConfig));
        }
    } , [paginationConfig]);

    const onDateRangeChange = ({ target }) => {
        const { name, value } = target;
        let pc = paginationConfig;
        if (name === "from") {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        } else {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        }
        setPaginationConfig({ ...pc, page: 1, limit: 10 });
    };

    const [startDate , setStartDate] = useState(paginationConfig.filter.from);
    const [endDate , setEndDate] = useState(moment().format( DB_FORMAT.DATE));

    const [dataFound, setDataFound] = useState(true);
    
    
    useEffect(() => {
        if (!socket) return;
          socket.emit(SOCKET.EMIT_JOIN, {
            roomId: "SUPER_ADMIN",
            from: {
              id : user?.id,
              accountId : user?.accountId,
              role : (user?.accountId && user.accountId > 0 ? USER_ROLES.ACCOUNT : USER_ROLES.SUPER_ADMIN)
            }
          });

        socket.on("disconnected", (data) => {
          console.log("socket got disconnected on",  moment().format("DD MMM, YY hh:mm:ss"), "because of ", data);
        });    

        return () => {
          socket.off(SOCKET.EMIT_JOIN);
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on(SOCKET.RECEIVE_BILLING_DATA_CHANGE , (data) => {
          // console.log('billing data change')
          // console.log(data);
          if(data.paymentStatus === PAYMENT_STATUS.SUCCESSFUL) {
            // console.log('payment complete')
        	  let pc = paginationConfig;
        	 setPaginationConfig({ ...pc });
          }
        })
        return () => {
          socket.off(SOCKET.RECEIVE_BILLING_DATA_CHANGE);
        };
    } , [socket]);
    
   return (
        <React.Fragment>
            { 
        	<PageHeader title="Schedule Detailed Report" shouldShowAddBtn={false} />
        	}
            <Card>
                <CardBody>
                    <Row className="gy-3 gx-3">
                        {/* 
                    	<Col xl={3} md={3} sm={12} xs={12}>
                            <Label>Employee</Label>
                            <Input caret type="select" name="location" value={paginationConfig.filter.employee}
                                onChange={(e) => {
                                let x = paginationConfig;
                                x.filter.employee = e.target.value;
                                setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                }}
                                >
                                <option value={""}>All Employee</option>
                                {employees.map((_, index) => (
                                <option key={_.id} value={_.Employee.id}>
                                    {_.Employee.firstName} {_.Employee.lastName}
                                </option>
                                ))}
                            </Input>
                        </Col>
                        */}
                        <Col xl={3} md={3} sm={12} xs={12}>
	          	          <Label>Status</Label>
	          	          <Input caret type="select" name="status" onChange={(e) => {
	          	        	    console.log("sss" , e.target.value );
	          	            	let x = paginationConfig;
	          		            x.filter.status = e.target.value;
	          		            if( e.target.value !== "" && e.target.value !== null ){
	          		            	x.filter.location = null;
	          		            }
	          		            
	          		            setSaloonStatus(e.target.value);
	          		            setPaginationConfig({ ...x });
	          		            
	          	            }}
	          	            >
	          	            <option value={""}>All</option>
	          	            <option value={"active"} selected>Active</option>
	          	            <option value={"inactive"}>Inactive</option>
	          	          </Input>
	          	        </Col>
                        {role === USER_TYPES.SUPER_ADMIN && (
                            <Col xl={3} md={3} sm={12} xs={12}>
                                <Label>Location</Label>
                                <Input caret type="select" name="location" value={paginationConfig.filter.location}
                                    onChange={(e) => {
                                    const ef = employeeFilter;
                                    ef.filter.working_location = e.target.value;
                                    setEmployeeFilter({ ...ef });
                                    let x = paginationConfig;
                                    x.filter.employee = ((employees.filter((value) => value?.Employee?.id == x.filter.employee && (value?.Employee?.saloonIds.split(',')).includes(e.target.value))?.length > 0) ? x.filter.employee : '');
                                    x.filter.location = e.target.value;
                                    setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                    }}
                                    >
                                    <option value={""}>All Location</option>
                                    {saloons.map((_, index) => (
                                    <option key={_.id} value={_.Saloon.id}>
                                        {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                                    </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>From</Label>
                            <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
                        </Col>
                        {/*
                        	
                        
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>To</Label>
                            <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
                        </Col>
                        */}
                    </Row>
                </CardBody>
            </Card>
            {!loading ? (
                <>
                {!dataFound && (
                    <NoDataFound />
                )}
                <Card className="newdashboard-table">
                <CardBody className="px-2 py-0">
                    <Table responsive bordered>
                        
                            <thead>
                                <tr>
                                    <th style={{minWidth: '50px',width: '50px'}}></th>
                                    <th style={{minWidth: '120px',width: '120px'}}></th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>10 - 11</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>11 - 12</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>12 - 1</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>1 - 2</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>2 - 3</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>3 - 4</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>4 - 5</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>5 - 6</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>6 - 7</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>7 - 8</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>8 - 9</th>
                                    <th className="text-center" style={{minWidth: '70px',width: '70px'}}>9 - 10</th>
                                    <th className="text-center" style={{minWidth: '60px',width: '60px'}}>Total</th>
                                </tr>
                            </thead>
                        
                        <tbody className="table-body show-border">
                            
                            
                            
                            {
                                
                            		( reports?.scheduleReportDetails && reports?.scheduleReportDetails?.length > 0 && (
                                        <>
                                       
                                        {(() => {
                                            const tableColumns = [];
                                            var rowIndex = 1;
                                            //{console.log("rrrrr");}
                                            reports?.scheduleReportDetails.map((row) => {
                                            	var rowIndex = 1;
                                            	{
                                            		tableColumns.push(
	                                                        <>
		                                                        <tr>
		                                                        	<td style={{'font-weight' : 'bold','tet-align' : 'center'}} colSpan={15} className={"salons-main-" + reports?.saloonId}>{row?.name}</td>
		                                                        </tr>
	                                                        </>
	                                                       	)
                                                }
                                            	
                                            	row?.date?.length > 0 && (
                                                		row?.date.map((dateInfo) => {
                                                		<>
	                                                    {!dataFound && setDataFound(true)}
	                                                    {tableColumns.push(
	                                                        <>
	                                                        <tr>
	                                                            <td className="text-center">{rowIndex++}</td>
	                                                            <td className={"salon-date-" + reports?.saloonId}>{ (dateInfo?.date ? getFormatOnlyDate(dateInfo.date) : '') }</td>
	                                                        {(() => {
	                                                            const dailyLogColumns = [];
	                                                            var dayAmount = 0;
	                                                            var totalDaySalesAmount = 0;
	                                                            var totalDayCount = 0;
	                                                            dateInfo?.hours.map((dailyLog) => {
	                                                            	var additionalClassName = '';
	                                                                var displayAmount = ( dailyLog?.value ? (dailyLog.value) : 0 );
	                                                                if( parseFloat(dayAmount) > 0 ) {
	                                                                	if( parseFloat(displayAmount) >  parseFloat(dayAmount)){
	                                                                		additionalClassName = "text-success";
		                                                                } else {
		                                                                	additionalClassName = "text-danger";
		                                                                }
	                                                                }
	                                                                additionalClassName = '';
	                                                                totalDaySalesAmount = parseFloat(totalDaySalesAmount) + parseFloat(dayAmount);
                                                                	totalDayCount = parseInt(totalDayCount) + parseInt(dailyLog.count);
	                                                                dayAmount = displayAmount;
	                                                                var displayHtml = ( ( dailyLog?.count ? '<span>' + (dailyLog.count) + '</span>' : '') + '<br>' + (dailyLog?.value ? '$' + (dailyLog.value) : '') );
	                                                                dailyLogColumns.push(
	                                                                		<>
	                                                                			<td className={ "text-center " +  additionalClassName } dangerouslySetInnerHTML={{ __html: displayHtml }}></td>
		                                                                    </>
	                                                                    
	                                                                	);
	                                                            })
	                                                            { 
	                                                            	var totalDisplayHtml = ( ( totalDayCount ? '<span>' + (totalDayCount) + '</span>' : '') + '<br>' + (totalDaySalesAmount ? '$' + (parseFloat(totalDaySalesAmount).toFixed(2)) : '') );
	                                                            	dailyLogColumns.push(
	                                                            			<>
	                                                                			<td className="text-center" dangerouslySetInnerHTML={{ __html: totalDisplayHtml }}></td>
		                                                                    </>
	                                                                	);
	                                                            }
	                                                            return dailyLogColumns;
	                                                        })()}
	                                                        </tr>
	                                                        </>
	                                                    )}
	                                                    </>
	                                                	})
	                                                )
	                                            })
	                                            return tableColumns;
	                                        })()}
                                        </>
                                    ))
                                
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            </>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
};

export default ScheduleDetailedReport;