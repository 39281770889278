import React, {useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap";
import { api, getLocaleFixedValue } from "../../util";
import { toast } from "react-toastify";
import Loading from "../../components/common/Loading";

const SaloonDashboard = () => {

    const [totalYearlySales , setTotalYearlySales] = useState("0.00");
    const [totalMonthlySales , setTotalMonthlySales] = useState("0.00");
    const [totalYearlyTarget , setTotalYearlyTarget] = useState("0.00");
    const [totalMonthlyTarget , setTotalMonthlyTarget] = useState("0.00");
    const [employeeTicketAverage , setEmployeeTicketAverage] = useState([]);
    const [isLoading , setIsLoading] = useState(false);

    const getDashboardDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/dashboard/getSaloonDashBoard');
            if(response.status === 200) {
                if(response?.data?.data) {
                    var responseData = response.data.data;
                    setTotalMonthlySales(responseData?.totalMonthlySales?.totalAmount ? getLocaleFixedValue(responseData.totalMonthlySales.totalAmount) : "0.00");
                    setTotalMonthlyTarget(responseData?.totalMonthlyTarget?.targetValue ? getLocaleFixedValue(responseData.totalMonthlyTarget.targetValue) : "0.00");
                    setTotalYearlySales(responseData?.totalSales?.totalAmount ? getLocaleFixedValue(responseData.totalSales.totalAmount) : "0.00");
                    setTotalYearlyTarget(responseData?.totalTarget?.targetValue ? getLocaleFixedValue(responseData.totalTarget.targetValue) : "0.00");
                    setEmployeeTicketAverage(responseData?.ticketAverage);
                }
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getDashboardDetails();
    } , []);

    return (
        <React.Fragment>
            <section>
                <Row className="gy-3">
                    <Col lg={6}>
                        <h4 className="text-white mb-3">Individual</h4>
                        <Card className="newdashboard-table individual-table">
                            <CardBody className="px-2 py-0">
                                <Table responsive>
                                <thead>
                                    <tr>
                                        <th style={{minWidth: '110px',width: '110px'}}></th>
                                        <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Employee</th>
                                        <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Ticket Average</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeTicketAverage?.length > 0 ? (
                                        employeeTicketAverage.map((ticketAverageInfo , index) => (
                                            <>
                                                <tr>
                                                    <td><span className="fw-bolder text-break">{index + 1}</span></td>
                                                    <td className="text-center">{ticketAverageInfo?.Employee?.firstName + ' ' + ticketAverageInfo?.Employee?.lastName}</td>
                                                    <td className="text-center">{ticketAverageInfo?.ticketAverage ? getLocaleFixedValue(ticketAverageInfo.ticketAverage) : ''}</td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr>
                                          <td className="text-center" colSpan="10">
                                            <i class="bi bi-exclamation-circle"></i> No Data Found
                                          </td>
                                        </tr>
                                    )}
                                </tbody>
                                </Table>
                                {isLoading && (
                                    <Loading />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <h4 className="text-white mb-3">Store Statistics</h4>
                        <Row>
                            <Col size={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="gy-3">
                                        <Col lg={6} className="border-end">
                                            <div className="d-flex flex-column align-items-center">
                                                <h5>Yearly Target</h5>
                                                <h4 className="mb-0 fw-bolder">${totalYearlyTarget}</h4>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h5>Total Sales</h5>
                                                <h4 className="mb-0 fw-bolder">${totalYearlySales}</h4>
                                            </div>
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col size={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="gy-3">
                                        <Col lg={6}>
                                            <div className="border p-2 d-flex flex-column align-items-center rounded">
                                                <h5>Monthly Target</h5>
                                                <h4 className="mb-0 fw-bolder">${totalMonthlyTarget}</h4>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="border p-2 d-flex flex-column align-items-center rounded">
                                                <h5>Total Sales</h5>
                                                <h4 className="mb-0 fw-bolder">${totalMonthlySales}</h4>
                                            </div>
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </React.Fragment>
  );
};

export default SaloonDashboard;
