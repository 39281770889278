import {
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_PENDING,
} from "./actionTypes";

// NOTES

export const getProjectsRequest = (payload) => {
  return {
    type: GET_PROJECTS_REQUEST,
    payload,
  };
};

export const getProjectsSuccess = (payload) => {
  return {
    type: GET_PROJECTS_SUCCESS,
    payload,
  };
};

export const getProjectsFailure = (payload) => {
  return {
    type: GET_PROJECTS_FAILURE,
    payload,
  };
};

export const getProjectsPending = () => {
  return {
    type : GET_PROJECTS_PENDING,
  }
}
