import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , FIXED_VALUE_INCENTIVE , PRECENTAGE_VALUE_INCENTIVE } from "../../util";
import { getAccountsRequest } from "../../redux/accounts/actions";
import { toast } from "react-toastify";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "bootstrap";


const UpsertProject = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accounts } = useSelector((state) => state.accounts);
  const { permissions } = useSelector((state) => state.user);
  const [initialValues, setInitialValues] = useState({
	  accountId : { 'label' : 'Select' , 'value' : '' },
	  name: "",
	  appId : "",
	  accessToken : "",
  });
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (isActionPermitted("project", "read", permissions)) {
      dispatch(getAccountsRequest());
    }
  }, [permissions]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
    });
  }, [accounts]);
 
  const onSubmit = async (values) => {
	  if(!values?.accountId?.value){
		  toast.error("Please select square account");
		  return false;
	  }
	  
	  setIsLoading(true)
	  if (id === "add") {
	      try {
	        const response = await api.post("/projects", {
	          ...values,
	        });
	        if (response.status === 201) {
	          toast.success(response?.data?.message || response.message);
	          navigate("/square-applications");
	        }
	      } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    } else {
	      try {
	        const response = await api.put("/projects/" + id, {
	          ...values,
	        });
	        if (response.status === 200) {
	          toast.success(response?.data?.message || response.message);
	          navigate("/square-applications");
	        }
	      } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    }
  };
  
  useEffect(() => {
    if (id !== "add") {
      const getProject = async (id) => {
    	 setIsLoading(true);
    	 try {
          const response = await api.get("/projects/" + id);
          if (response.status === 200) {
        	  var accountSelectedObject = { 'label' : 'Select' , 'value' : '' };
        	  if( response?.data?.data?.Account?.id  ){
        		accountSelectedObject = { "value": response?.data?.data?.Account?.id , "label": response?.data?.data?.Account?.name };
        	 }
        	 setInitialValues({
              ...response.data.data,
              accountId: accountSelectedObject || "",
              name: response.data.data.name || "",
              appId: response.data.data.appId || "",
              accessToken: response.data.data.accessToken || "",
            });
          }
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        } finally {
          setIsLoading(false);
        }
      };
      getProject(id);
    }
  }, [id]);

 
  var accountSelection = [];
  accountSelection.push({ 'label' : 'Select' , 'value' : '' });
  accounts.map( (_, index) => (
		accountSelection.push({ "value": _.id, "label": _.name })
  ))
  
   const handleAccountSelection = (selectedOptions,values, setValues) => {
	 setValues({ ...values, accountId: selectedOptions });
   }
  
  return (
    <React.Fragment>
      <Formik
        validateOnChange
        initialValues={{ ...initialValues }}
        validationSchema={
          Yup.object().shape({
        	  //accountId: Yup.string().required("Please enter first name"),
        	  //accountId: Yup.string().required("Please select account"),
        	  name: Yup.string().required("Please enter name"),
        	  appId: Yup.string().required("Please enter application ID"),
        	  accessToken: Yup.string().required("Please enter application token"),
           }) 
        }
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, values, touched, setValues }) => (
          <>
            <PageHeader
              shouldShowAddBtn={false}
              title={id === "add" ? "Add Square Application" : "Edit Square Application"}
            />
            <Card>
              <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                <i className="bi bi-list-task me-2"> </i>
                {id === "add" ? "Create a new Square Application" : "Edit this Square Application"}
              </CardTitle>
              <CardBody>
                <Form>
                  <Row>
	                  <Col xl={6}>
	                  <FormGroup>
	                    <Label for="accountId">Square Account</Label>
	                    <Select options={accountSelection} name="accountId" value={values.accountId} 
	                    onChange={(e) => {
	                    	handleAccountSelection(e, values, setValues);
	                    }}
	                    closeMenuOnSelect={true} />
	                    {errors.accountId && touched.accountId && (
	                     <span component="div" className="invalid-feedback">
	                        {errors.accountId}
	                      </span>
	                    )}
	                  </FormGroup>
	                </Col>
                  	<Col xl={6}>
                      <FormGroup>
                        <Label for="name">Name</Label>
                        <Field
                          id="name"
                          name="name"
                          placeholder="Name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                        />
                        {errors.name && touched.name && (
                          <span component="div" className="invalid-feedback">
                            {errors.name}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={12}>
                    <FormGroup>
                      <Label for="appId">Application ID</Label>
                      <Field
                        id="appId"
                        name="appId"
                        placeholder="Application ID"
                        type="text"
                        className={
                          "form-control" +
                          (errors.appId && touched.appId ? " is-invalid" : "")
                        }
                      />
                      {errors.appId && touched.appId && (
                        <span component="div" className="invalid-feedback">
                          {errors.appId}
                        </span>
                      )}
                    </FormGroup>
                    </Col>
                    
                    <Col xl={12}>
                    <FormGroup>
                      <Label for="accessToken">Application Token</Label>
                      <Field
                        id="accessToken"
                        name="accessToken"
                        placeholder="Application Token"
                        type="text"
                        className={
                          "form-control" +
                          (errors.accessToken && touched.accessToken ? " is-invalid" : "")
                        }
                      />
                      {errors.accessToken && touched.accessToken && (
                        <span component="div" className="invalid-feedback">
                          {errors.accessToken}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
             </Row>
             <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                    <Button
                      color="transaprent"
                      className="border"
                      type="button"
                      onClick={() => navigate("/square-applications")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading} className="btn btn-primary">
                      {id === "add" ? "Add Square Application" : "Update Square Application"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UpsertProject;
