import { ErrorMessage, Field, FieldArray, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , FIXED_VALUE_INCENTIVE , PRECENTAGE_VALUE_INCENTIVE, onlyNumber, SELECTION } from "../../util";
import { getEmployeesRequest } from "../../redux/employees/actions";
import { getServicesRequest } from "../../redux/services/actions";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { toast } from "react-toastify";
import { getCategoriesRequest } from "../../redux/actions";
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "bootstrap";


const UpsertEmployee = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saloons } = useSelector((state) => state.saloons);
  //const { services  } = useSelector((state) => state.services );
  const { categories } = useSelector((state) => state.categories);
  const { permissions } = useSelector((state) => state.user);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    login_code: "",
    last_name: "",
    joining_date : "",
    birth_month : "",
    birth_date : "",
    email : "",
    phone : "",
    location : "",
    product_category_commission_type : "",
    product_category_commission_value : "",
    working_location : "",
    pay_rate_hourly : "",
    pay_type : "",
    is_paid_break : SELECTION.NO,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedServiceIndex, setSelectedServiceIndex] = useState(1);
  const [selectedServiceInfo, setSelectedServiceInfo] = useState([]);
  const [commisionInfo, setCommisionInfo] = useState([]);
  
  const [commisionFieldValues, setCommisionFieldValues] = useState([{ intensive_service_id: '', intensive_type: FIXED_VALUE_INCENTIVE, intensive_amount: '' }]);
  
  const handleInputChange = (index, fieldName, event) => {
	    const values = [...commisionFieldValues];
	    values[index][fieldName] = event.target.value;
	    setCommisionFieldValues(values);
  };

  const handleAddFields = () => {
	    const values = [...commisionFieldValues];
	    values.push({ intensive_service_id: '', intensive_type: FIXED_VALUE_INCENTIVE, intensive_amount: '' });
	    setCommisionFieldValues(values);
  };
  
  const getServiceList = async (selectedShopIds) => {
  	setIsLoading(true)
  	console.log("selectedShopIds"  , selectedShopIds)
  	try {
    	var payLoad = {};
    	if( selectedShopIds && selectedShopIds.length > 0 ){
    		payLoad = { params: { filter: { isIncentiveGiven : 1  } , 'selectedSaloonIds' : selectedShopIds } }
    		const response = await api.get("/services" , payLoad );
            if (response.status === 200) {
            	// console.log(response.data.data.list);
            	setServices(response.data.data.list);
            }
    	} else {
    		setServices([]);
    	}
    	setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false);
      }
  };
  
  
  useEffect(() => {
    if (isActionPermitted("employee", "read", permissions)) {
      dispatch(getEmployeesRequest());
      dispatch(getServicesRequest({ filter : { isIncentiveGiven : 1 } }));
      dispatch(getSaloonsRequest({'sort' : 'name'}));
    }
  }, [permissions]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
    });
  }, [saloons]);
  
  const [employeeCommissionInfo, setEmployeeCommissionInfo] = useState([]);
  
  const allMonthDetails = [];
  for (var i = 0; i < 12; i++) {
	  allMonthDetails.push(moment().months(i).format('MMMM'));
  }
  
  const allDateDetails = [];
  for (var i = 1; i <= 31; i++) {
	  allDateDetails.push(i);
  }
  
  const onDateRangeChange = (event , values, setValues ) => {
	  const { name, value } = event.target;
	  setValues({ ...values, joining_date: moment(value).format( "YYYY-MM-DD") });
  };
  
  const handleSelectChange = (selectedOptions, values, setValues) => {
	  //console.log("selectedOptions" , selectedOptions );
	  //console.log("setSelectedShopIds")
	  const allShopIds = [];
	  selectedOptions.forEach( selectedOption => {
		  allShopIds.push(selectedOption?.value);
		  //console.log("selectedOption" , selectedOption?.value)
	  });
	  // setSelectedShopIds(allShopIds);
	  //console.log("allShopIds" , allShopIds );
	  setValues({ ...values, working_location: selectedOptions });
	  getServiceList(allShopIds);
  };
  
  
  //console.log("addRow");
  //console.log(allMonthDetails);
  //console.log(allDateDetails);
  
  const onSubmit = async (values) => {
	  console.log("values");
	  console.log(values);
	  console.log(commisionFieldValues);
	  var selectedLocationIds  = [];
	  if( values?.working_location){
		  values?.working_location.map( ( selectionLocation, index) => (
				 selectedLocationIds.push(selectionLocation?.value)
				  
		  ))
	  }
	  if( selectedLocationIds.length > 0 ){
		  values.working_location = selectedLocationIds.join(',');
	  } else {
		  toast.error("Please select atleast one working location.");
		  return false;
	  }
	  console.log("values");
	  console.log(values);
	  if (commisionFieldValues) {
	      values.commisionInfo = commisionFieldValues;
	  }
	  //return false;
	  setIsLoading(true)
	    if (id === "add") {
	      try {
	        const response = await api.post("/employees", {
	          ...values,
	        });
	        if (response.status === 201) {
	          toast.success(response?.data?.message || response.message);
	          navigate("/employees");
	        }
	      } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    } else {
	      try {
	        const response = await api.put("/employees/" + id, {
	          ...values,
	        });
	        if (response.status === 200) {
	          toast.success(response?.data?.message || response.message);
	          navigate("/employees");
	        }
	      } catch (error) {
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    }
  };
  
  const handleVariablePricing = (e, values, setValues) => {
	  
  }
  
  
  useEffect(() => {
    if (id !== "add") {
      const getEmployee = async (id) => {
        try {
          const response = await api.get("/employees/" + id);
          if (response.status === 200) {
        	// console.log('edit response', response);
        	var allEditLocationOptions = [];
          var intSaloonIdsArray = [];	
        	if( response?.data?.data?.saloonIds ){
            	var allEditSaloonLocationIds = response?.data?.data?.saloonIds;
            	allEditSaloonLocationIds = ( allEditSaloonLocationIds ? allEditSaloonLocationIds.split(',') : [] ) ;
            	intSaloonIdsArray = allEditSaloonLocationIds.map(element => parseInt(element, 10));
            	saloons.forEach((saloon, index) => {
            			// console.log("sallon id " , saloon.Saloon.id );
            			// console.log(allEditSaloonLocationIds.includes(saloon.Saloon.id));
	            		if( intSaloonIdsArray.includes(saloon.Saloon.id) ){
	            			allEditLocationOptions.push({ "value": saloon.Saloon.id, "label": saloon.Saloon.name })
	            		}
            		
            	});		
            	/*setInitialValues({
                    ...initialValues,
                    working_location: allEditLocationOptions,
                });	*/	
            	console.log("saloons");
            	console.log(saloons);
            	console.log(allEditSaloonLocationIds);
            	console.log(allEditLocationOptions);
            }
        	
        	setInitialValues({
              ...response.data.data,
              first_name: response.data.data.firstName || "",
              last_name: response.data.data.lastName || "",
              joining_date: response.data.data.joiningDate || "",
              birth_month: response.data.data.birthMonth || "",
              birth_date: response.data.data.birthDate || "",
              login_code: response.data.data.loginCode || "",
              email: response.data.data.email || "",
              phone: response.data.data.contactNo || "",
              pay_rate_hourly: response.data.data.hourlyRate || "",
              product_category_commission_value: response.data.data.productCategoryCommissionValue || "",
              product_category_commission_type: response.data.data.productCategoryCommissionType || "",
              pay_type: response.data.data.payType || "",
              working_location: allEditLocationOptions || "",
              is_paid_break: response.data.data.isPaidBreak || SELECTION.NO,
              
            });
            if( response.data.data.employeeIncentive ){
            	var editServiceInfo = [];
            	response.data.data.employeeIncentive.forEach((incentiveInfo) => {
            		var rowIncentiveInfo = { intensive_service_id: incentiveInfo?.serviceId, intensive_type: incentiveInfo?.type, intensive_amount: incentiveInfo?.amount }
            		editServiceInfo.push(rowIncentiveInfo);
                });
            	// console.log("editServiceInfo" , editServiceInfo );
            	setCommisionFieldValues(editServiceInfo);
              getServiceList(intSaloonIdsArray);
            }
            
            //
            //setCommisionFieldValues(values);
          }
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        } finally {
          setIsLoading(false);
        }
      };
      getEmployee(id);
    }
  }, [id, saloons]);

 

  /*const handleVariablePricing = (e, values, setValues) => {
    const { checked } = e.target;
    if (checked) {
      let pricing = values.prices.map((_) => ({ ..._, price: -1 }));
      setValues({ ...values, prices: pricing, isPricing: checked });
    } else {
      let pricing = values.prices.map((_) => ({ ..._, price: 0 }));
      setValues({ ...values, prices: pricing, isPricing: checked });
    }
  };*/
  var saloonSelectiosn = [];
  saloons.map( (_, index) => (
		 saloonSelectiosn.push({ "value": _.Saloon.id, "label": _.Saloon.name })
  ))
  //Formik.setFieldValue("inputFile",ev.target.files[0]) }}

  const [formattedPhoneNumber , setFormattedPhoneNumber] = useState(null);

  const handleMobileField = (e , values, setValues ) => {
    let enterValue = e.target.value;
    console.log("before enterValue",enterValue);
    enterValue = enterValue.replace(/[^\d]/g, '').match(/\d*/g).join('').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-').replace(/-*$/g, '');
    console.log("after enterValue",enterValue);
    setValues({ ...values, phone: enterValue });
    //setFormattedPhoneNumber((value.replace(/[^\d]/g, '')).match(/\d*/g).join('').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-').replace(/-*$/g, ''));
  }

  const [chosenServiceOptions, setChosenServiceOptions] = useState({});

  const isChosenByOther = (optionValue, selectName) => {
      for (let key in chosenServiceOptions) {
          if (key !== selectName) {
              if (parseInt(chosenServiceOptions[key]) === optionValue) {
                  return true;
              }
          }
      }
      return false;
  }

  return (
    <React.Fragment>
      <Formik
        validateOnChange
        initialValues={{ ...initialValues }}
        validationSchema={
    		Yup.object().shape({
        	  first_name: Yup.string().required("Please enter first name"),
        	  last_name: Yup.string().required("Please enter last name"),
              email: Yup.string().required("Please enter email").matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ , 'Please enter valid email'),
              phone: Yup.string().required("Please enter phone").min(12 , 'Please enter valid phone'),
              //pay_rate_hourly: Yup.string().required("Please enter pay rate hourly"),
              login_code: Yup.number().required("Please enter login code"),
          })
        }
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ errors, values, touched, setValues }) => (
          <>
            <PageHeader
              shouldShowAddBtn={false}
              title={id === "add" ? "Add Employee" : "Edit Employee"}
            />
            <Card>
              <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                <i className="bi bi-hdd-stack me-2"> </i>
                {id === "add" ? "Create a new employee" : "Edit this employee"}
              </CardTitle>
              <CardBody>
                <Form>
                  <Row>
                    <Col xl={6}>
                      <FormGroup>
                        <Label for="first_name">First Name</Label>
                        <Field
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.first_name && touched.first_name ? " is-invalid" : "")
                          }
                        />
                        {errors.first_name && touched.first_name && (
                          <span component="div" className="invalid-feedback">
                            {errors.first_name}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
                    <FormGroup>
                      <Label for="last_name">Last Name</Label>
                      <Field
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.last_name && touched.last_name ? " is-invalid" : "")
                        }
                      />
                      {errors.last_name && touched.last_name && (
                        <span component="div" className="invalid-feedback">
                          {errors.last_name}
                        </span>
                      )}
                    </FormGroup>
                    </Col>
                    
                    <Col xl={6}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Field
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      {errors.email && touched.email && (
                        <span component="div" className="invalid-feedback">
                          {errors.email}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6}>
                  <FormGroup>
                    <Label for="phone">Phone</Label>
                    <Field
                      id="phone"
                      name="phone"
                      //value={formattedPhoneNumber}
                      //onChange={handleMobileField}
	                  onChange={(e) => {
	                	  handleMobileField(e, values, setValues);
		              }}
                      placeholder="Phone"
                      type="text"
                      className={
                        "form-control" +
                        (errors.phone && touched.phone ? " is-invalid" : "")
                      }
                    />
                    {errors.phone && touched.phone && (
                      <span component="div" className="invalid-feedback">
                        {errors.phone}
                      </span>
                    )}
                  </FormGroup>
                  </Col>
                  <Col xl={6}>
                  <FormGroup>
                    <Label for="joining_date">Joining Date</Label>
                    
	                <Input
		                name="joining_date"
		                onChange={(e) => {
		                	onDateRangeChange(e, values, setValues);
		                }}
		                value={moment(values.joining_date).format("YYYY-MM-DD")}
		                //max={endDate}
		                type="date"
		              />
                    {errors.joining_date && touched.joining_date && (
                      <span component="div" className="invalid-feedback">
                        {errors.joining_date}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col xl={6}>
                <FormGroup>
                  <Label for="phone">Login Code</Label>
                  <Field
                    id="login_code"
                    name="login_code"
                    placeholder="Login Code"
                    type="text"
                    // min={0}
                    // step={1}
                    // minLength={5}
                    maxLength={4}
                    className={
                      "form-control" +
                      (errors.login_code && touched.login_code ? " is-invalid" : "")
                    }
                    onChange={(e) => onlyNumber(e , values , setValues)}
                  />
                  {errors.login_code && touched.login_code && (
                    <span component="div" className="invalid-feedback">
                      {errors.login_code}
                    </span>
                  )}
                </FormGroup>
                </Col>
                <Col xl={6}>
                <FormGroup>
                  <Label for="birth_month">Birth Month</Label>
                  <Field caret type="select" component="select" id="birth_month" name="birth_month" value={values.birth_month} className="form-control" >
                  <option value="">Select</option>
                  {allMonthDetails.map((allMonthDetail, index) => (
                    		<option value={allMonthDetail}>{allMonthDetail}</option>
                   ))}
                  </Field>
                  {errors.birth_month && touched.birth_month && (
                    <span component="div" className="invalid-feedback">
                      {errors.birth_month}
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col xl={6}>
              <FormGroup>
                <Label for="birth_date">Birth Date</Label>
                <Field caret type="select" component="select" id="birth_date" name="birth_date" value={values.birth_date} className="form-control" >
          		<option value="">Select</option>
                {allDateDetails.map((allDateDetail, index) => (
                  		<option value={allDateDetail}>{allDateDetail}</option>
                 ))}
                </Field>
                {errors.birth_date && touched.birth_date && (
                  <span component="div" className="invalid-feedback">
                    {errors.birth_date}
                  </span>
                )}
              </FormGroup>
              </Col>
              <Col xl={6}>
              <FormGroup>
                <Label for="pay_type">Working Locations</Label>
                <Select options={saloonSelectiosn} name="working_location" value={values.working_location} 
                onChange={(e) => {
                	handleSelectChange(e, values, setValues);
                }}
                closeMenuOnSelect={true} isMulti  />
                {errors.working_location && touched.working_location && (
                 <span component="div" className="invalid-feedback">
                    {errors.working_location}
                  </span>
                )}
              </FormGroup>
            </Col>

            <Col xl={6}>
              <FormGroup>
                <Label for="is_paid_break">Is Paid Break</Label>
                <Field caret type="select" component="select" id="is_paid_break" name="is_paid_break" value={values.is_paid_break}
                  className={
                    "form-control " +
                    (errors.is_paid_break && touched.is_paid_break
                      ? " is-invalid"
                      : "")
                  }
                >
                <option value={SELECTION.NO}>{SELECTION.NO}</option>
                <option value={SELECTION.YES}>{SELECTION.YES}</option>
                </Field>

                {errors.is_paid_break && touched.is_paid_break && (
                  <span component="div" className="invalid-feedback">
                    {errors.is_paid_break}
                  </span>
                )}
              </FormGroup>
            </Col>
              <Col xl={6}>
	              <FormGroup>
	                <Label for="pay_rate_hourly">Pay Rate Hourly</Label>
	                <Field
	                  id="pay_rate_hourly"
	                  name="pay_rate_hourly"
	                  placeholder="Pay Rate Hourly"
	                  type="number"
	                  className={
	                    "form-control" +
	                    (errors.pay_rate_hourly && touched.pay_rate_hourly ? " is-invalid" : "")
	                  }
	                />
	                {errors.pay_rate_hourly && touched.pay_rate_hourly && (
	                  <span component="div" className="invalid-feedback">
	                    {errors.pay_rate_hourly}
	                  </span>
	                )}
	              </FormGroup>
            </Col>
            
            
                    
                    <Col xl={6}>
                      <FormGroup>
                        <Label for="pay_type">Pay Type</Label>
                        <Field caret type="select" component="select" id="pay_type" name="pay_type" value={values.pay_type}
                          className={
                            "form-control " +
                            (errors.pay_type && touched.pay_type
                              ? " is-invalid"
                              : "")
                          }
                        >
                        <option value="">Select</option>
                        <option value="Bi">Bi</option>
                        <option value="Irregular">Irregular</option>
                        </Field>

                        {errors.pay_type && touched.pay_type && (
                          <span component="div" className="invalid-feedback">
                            {errors.pay_type}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
	                    <FormGroup>
	                      <Label for="product_category_commission_type">Product Category Commission Type</Label>
	                      <Field caret type="select" component="select" id="product_category_commission_type" name="product_category_commission_type" value={values.product_category_commission_type}
	                      className={ "form-control " + (errors.product_category_commission_type && touched.product_category_commission_type ? " is-invalid" : "") }
	                    >
	                      <option value="">Select</option>	
	                      <option value={FIXED_VALUE_INCENTIVE}>Fixed</option>
	          			      <option value={PRECENTAGE_VALUE_INCENTIVE}>Percentage</option>
	                    </Field>
	                      
	                      {errors.product_category_commission_type && touched.product_category_commission_type && (
	                        <span component="div" className="invalid-feedback">
	                          {errors.product_category_commission_type}
	                        </span>
	                      )}
	                    </FormGroup>
	              </Col>
	              <Col xl={6}>
		              <FormGroup>
		                <Label for="product_category_commission_value">Product Category Commission Value</Label>
		                
		                <Field
		                  id="product_category_commission_value"
		                  name="product_category_commission_value"
		                  placeholder="Product Category Commission Value"
		                  type="number"
		                  className={
		                    "form-control" +
		                    (errors.product_category_commission_value && touched.product_category_commission_value ? " is-invalid" : "")
		                  }
		                />
		                {errors.product_category_commission_value && touched.product_category_commission_value && (
		                  <span component="div" className="invalid-feedback">
		                    {errors.product_category_commission_value}
		                  </span>
		                )}
		              </FormGroup>
		        </Col>
                  </Row>
                  <hr />
                 
                  <div className="d-flex justify-content-between">
                  		Commissions  Info
                  		<Button type="button" onClick={handleAddFields}><i className="bi bi-plus-circle"></i></Button>
                  </div>
                  <div className="incentive-main-div">
                  		{employeeCommissionInfo}
                  		{commisionFieldValues.map((field, index) => (
                  			
	                  		<div key={index}>
	                  			<Row>
	                  				<Col xl={4}>
	                  				 <FormGroup>
	                  				 	<Label>Service</Label>
	                  				 	<Field 
                                caret 
                                type="select" 
                                component="select" 
                                value={field?.intensive_service_id} 
                                onChange={(event) => {
                                  handleInputChange(index, 'intensive_service_id', event);
                                  setChosenServiceOptions({...chosenServiceOptions , ['commission_field_' + index] : event.target?.value});
                                }}  
                                name={"insentive_service_" + selectedServiceIndex }
                                className={"form-control"}
                              >
			                  			  <option value="">Select</option>
			                  		      {services.filter((value) => !isChosenByOther(value?.id , 'commission_field_' + index))
                                  .map((_, index) => (
			                  		      		<option value={_.id}>{_.name}</option>
			                  		      ))}
			                  		    </Field>
	                  				</FormGroup>
	                  				</Col>
	                  				<Col xl={4}>
		                  			  <FormGroup>
			                  			  <Label>Type</Label>
				                  			  <Field caret type="select" component="select" value={field?.intensive_type} onChange={(event) => handleInputChange(index, 'intensive_type', event)} name={"insentive_type_" + selectedServiceIndex } className={"form-control"}>
					                  			  <option value={FIXED_VALUE_INCENTIVE}>Fixed</option>
					                  			  <option value={PRECENTAGE_VALUE_INCENTIVE}>Percentage</option>
		                  		      </Field>
		                  			  </FormGroup>
	                  			  </Col>
	                  			  <Col xl={4}>
		                  			  <FormGroup>
			                  			  <Label>Incentive</Label>
			                  			  <Field name={"insentive_amount_" + selectedServiceIndex } min={0} step={0.01} value={field?.intensive_amount} onChange={(event) => handleInputChange(index, 'intensive_amount', event)}   placeholder="Incentive" type="number" className={ "form-control"}/>
		                  			  </FormGroup>
	                  			  </Col>
	                  			</Row>
	                  		</div>
                  		        
                  		))}
                  </div>
                   
                  
                  <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                    <Button
                      color="transaprent"
                      className="border"
                      type="button"
                      onClick={() => navigate("/employees")}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={isLoading} className="btn btn-primary">
                      {id === "add" ? "Add Employee" : "Update Employee"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UpsertEmployee;
