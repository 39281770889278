import React, {useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

const DesignDashboard = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);

  return (
    <React.Fragment>
      <section>
        <Row className="gy-3">
          <Col lg={6}>
            <h4 className="text-white mb-3">Individual</h4>
            <Card className="newdashboard-table individual-table">
              <CardBody className="px-2 py-0">
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{minWidth: '110px',width: '110px'}}></th>
                      <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Employee</th>
                      <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Lorem Lipsum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Position</span></td>
                      <td className="text-center">John Doe</td>
                      <td className="text-center">-</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <h4 className="text-white mb-3">Store Statistics</h4>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody className="d-flex flex-column align-items-center">
                    <h5>Total Sales</h5>
                    <h4 className="mb-0 fw-bolder">100</h4>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody className="d-flex flex-column align-items-center">
                    <h5>Total Sales</h5>
                    <h4 className="mb-0 fw-bolder">50</h4>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            
            <Row>
              <Col size={12}>
                <Card>
                  <CardBody>
                    <Row className="gy-3">
                      <Col lg={6} className="border-end">
                          <div className="d-flex flex-column align-items-center">
                            <h5>Total Sales</h5>
                            <h4 className="mb-0 fw-bolder">100</h4>
                          </div>
                      </Col>
                      <Col lg={6}>
                          <div className="d-flex flex-column align-items-center">
                            <h5>Total Sales</h5>
                            <h4 className="mb-0 fw-bolder">50</h4>
                          </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col size={12}>
                <Card>
                  <CardBody>
                    <Row className="gy-3">
                      <Col lg={6}>
                          <div className="border p-2 d-flex flex-column align-items-center rounded">
                            <h5>Total Sales</h5>
                            <h4 className="mb-0 fw-bolder">100</h4>
                          </div>
                      </Col>
                      <Col lg={6}>
                          <div className="border p-2 d-flex flex-column align-items-center rounded">
                            <h5>Total Sales</h5>
                            <h4 className="mb-0 fw-bolder">50</h4>
                          </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card className="newdashboard-table">
              <CardBody className="px-2 py-0">
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{minWidth: '130px' ,width: '130px'}}></th>
                      <th className="text-center" style={{minWidth: '100px' ,width: '100px'}}>Lorem Lipsum</th>
                      <th className="text-center" style={{minWidth: '100px' ,width: '100px'}}>Lorem Lipsum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span className="fw-bolder">Total Sales</span></td>
                      <td className="text-center">100</td>
                      <td className="text-center">50</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder">Lorem Lipsum</span></td>
                      <td className="text-center">100</td>
                      <td className="text-center">50</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder">Lorem Lipsum</span></td>
                      <td className="text-center">100</td>
                      <td className="text-center">50</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder">Lorem Lipsum</span></td>
                      <td className="text-center">100</td>
                      <td className="text-center">50</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder">Lorem Lipsum</span></td>
                      <td className="text-center">100</td>
                      <td className="text-center">50</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder">Lorem Lipsum</span></td>
                      <td className="text-center">100</td>
                      <td className="text-center">50</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>

          </Col>
        </Row>


        <Row>
          <Col lg={4}>
            <h4 className="text-white mb-3">Employee Average</h4>
            <Card className="newdashboard-table employee-average">
              <CardBody className="px-2 py-0">
                <Table responsive>
                  <tbody>
                    <tr>
                      <th><span className="fw-bolder text-break">Sr. No.</span></th>
                      <td>1</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Emp. Name</span></th>
                      <td>John Doe</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Average Ticket Size</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                    <tr>
                      <th><span className="fw-bolder text-break">Lorem Lipsum</span></th>
                      <td>100</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>

          <Col lg={8}>
            <h4 className="text-white mb-3">Comparison Table</h4>
            <Card className="newdashboard-table">
              <CardBody className="px-2 py-0">
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{minWidth: '150px',width: '150px'}}>Shop Name</th>
                      <th className="text-center" style={{minWidth: '100px' ,width: '100px'}}>Lorem</th>
                      <th className="text-center" style={{minWidth: '100px',width: '100px'}}>Lorem</th>
                      <th className="text-center" style={{minWidth: '100px',width: '100px'}}>Lorem</th>
                      <th className="text-center" style={{minWidth: '100px',width: '100px'}}>Lorem</th>
                      <th className="text-center" style={{minWidth: '100px',width: '100px'}}>Lorem</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span className="fw-bolder text-break">Spot</span></td>
                      <td className="text-center">2 PM</td>
                      <td className="text-center">800</td>
                      <td className="text-center">600</td>
                      <td className="text-center">500</td>
                      <td className="text-center">400</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Spot</span></td>
                      <td className="text-center">2 PM</td>
                      <td className="text-center">800</td>
                      <td className="text-center">600</td>
                      <td className="text-center">500</td>
                      <td className="text-center">400</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Spot</span></td>
                      <td className="text-center">2 PM</td>
                      <td className="text-center">800</td>
                      <td className="text-center">600</td>
                      <td className="text-center">500</td>
                      <td className="text-center">400</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Spot</span></td>
                      <td className="text-center">2 PM</td>
                      <td className="text-center">800</td>
                      <td className="text-center">600</td>
                      <td className="text-center">500</td>
                      <td className="text-center">400</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Spot</span></td>
                      <td className="text-center">2 PM</td>
                      <td className="text-center">800</td>
                      <td className="text-center">600</td>
                      <td className="text-center">500</td>
                      <td className="text-center">400</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Spot</span></td>
                      <td className="text-center">2 PM</td>
                      <td className="text-center">800</td>
                      <td className="text-center">600</td>
                      <td className="text-center">500</td>
                      <td className="text-center">400</td>
                    </tr>
                    <tr>
                      <td><span className="fw-bolder text-break">Total:</span></td>
                      <td className="text-center"></td>
                      <td className="text-center fw-bolder text-black">5600</td>
                      <td className="text-center fw-bolder text-black">4200</td>
                      <td className="text-center fw-bolder text-black">3500</td>
                      <td className="text-center fw-bolder text-black">2800</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

      <Button variant="primary" onClick={toggle}>
        Launch demo modal
      </Button>

      <Modal isOpen={isOpen} toggle={toggle} className="clock-summary-modal" size="lg" centered>
        <ModalHeader closeButton>
          <h5 className="mb-0 fw-bolder">Lorem Lipsum</h5>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card className="newdashboard-table shadow-none mb-0">
            <CardBody className="px-2 py-0">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Sr. No.</th>
                    <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Start Time</th>
                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-center">1</td>
                    <td className="text-center">10:00 AM</td>
                    <td className="text-center">01:30 PM</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-center" ><span className="fw-bolder">Break Time: </span> 00:00:00</td>
                  </tr>
                  <tr>
                    <td className="text-center">2</td>
                    <td className="text-center">02:30 AM</td>
                    <td className="text-center">04:30 PM</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-center" ><span className="fw-bolder">Break Time: </span> 00:00:00</td>
                  </tr>
                  <tr>
                    <td className="text-center">3</td>
                    <td className="text-center">04:45 AM</td>
                    <td className="text-center">07:00 PM</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-center" >
                      <span className="fw-bolder">Total Working Hours: </span> 08:30:00
                      <span className="fw-bolder ps-3">Total Break Hours: </span> 00:30:00
                      </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </ModalBody>
          <ModalFooter>
              <Button color="dark" className="btn-sm m-0" onClick={toggle}>Close</Button>
          </ModalFooter>
      </Modal>
      </section>
    </React.Fragment>
  );
};

export default DesignDashboard;
