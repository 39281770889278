import { takeLatest, call, put } from "redux-saga/effects";
import { getEmployeesListApiCall , getEmployeesDropdownListApiCall, getEmployeesApiCall , employeeLoginApiCall, getLateEmployeesApiCall, getTodayLateClockOutApiCall, getClockInSummaryApiCall } from "./apiCalls";
import { getEmployeesFailure, getEmployeesSuccess, employeeLoginFailure , employeeLoginSuccess, getEmployeesPending } from "./actions";
import { GET_EMPLOYEES_REQUEST , GET_EMPLOYEES_LIST_REQUEST ,GET_EMPLOYEES_DROPDOWN_LIST_REQUEST, EMPLOYEE_LOGIN_REQUEST, GET_LATE_EMPLOYEES_REQUEST, GET_CLOCK_IN_SUMMARY_REQUEST, GET_TODAY_LATE_CLOCK_OUT } from "./actionTypes";
import {
  LOCAL_STORAGE_USER as LOCAL_STORAGE_USER_KEY,
  USER_TYPES,
} from "../../util";
import { toast } from "react-toastify";

function* getEmployeesWorker({ payload }) {
  try {
    yield put(getEmployeesPending());
    const response = yield call(getEmployeesApiCall, payload);
    yield put(getEmployeesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getEmployeesFailure({ error }));
  }
}

function* getEmployeesListWorker({ payload }) {
  try {
    yield put(getEmployeesPending());
    const response = yield call(getEmployeesListApiCall, payload);
    yield put(getEmployeesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getEmployeesFailure({ error }));
  }
}

function* getEmployeesDropdownListWorker({ payload }) {
  try {
    yield put(getEmployeesPending());
    const response = yield call(getEmployeesDropdownListApiCall, payload);
    yield put(getEmployeesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getEmployeesFailure({ error }));
  }
}

function* employeeWorker({ payload }) {
  try {
    const response = yield call(employeeLoginApiCall, payload.payload);
    if (!response.data) return null;
    localStorage.setItem(
      LOCAL_STORAGE_USER_KEY,
      JSON.stringify(response.data.data)
    );
    yield put(employeeLoginSuccess(response.data.data));
    // if (response.data?.data?.role === USER_TYPES.ADMIN) {
    //   return payload.navigate("/billing");
    // } else if (response.data?.data?.role === USER_TYPES.CUSTOMER) {
    //   return payload.navigate("/user");
    // }
    return payload.navigate("/welcome");
  } catch (error) {
    console.log(error.message);
    toast.error(error?.response?.data?.message || error.message);
    yield put(employeeLoginFailure({ error }));
  }
}

function* getLateEmployeesWorker({payload}) {
  try {
    yield put(getEmployeesPending());
    const response = yield call(getLateEmployeesApiCall, payload);
    yield put(getEmployeesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getEmployeesFailure({ error }));
  }
}

function* getTodayLateClockOutWorker({payload}) {
  try {
    yield put(getEmployeesPending());
    const response = yield call(getTodayLateClockOutApiCall, payload);
    yield put(getEmployeesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getEmployeesFailure({ error }));
  }
}

function* getClockInSummaryWorker({payload}) {
  try {
    yield put(getEmployeesPending());
    const response = yield call(getClockInSummaryApiCall, payload);
    yield put(getEmployeesSuccess(response.data.data));
  } catch (error) {
    console.log(error);
    yield put(getEmployeesFailure({ error }));
  }
}

export function* employeesWatcher() {
  yield takeLatest(EMPLOYEE_LOGIN_REQUEST, employeeWorker);
  yield takeLatest(GET_EMPLOYEES_REQUEST, getEmployeesWorker);
  yield takeLatest(GET_EMPLOYEES_LIST_REQUEST, getEmployeesListWorker);
  yield takeLatest(GET_EMPLOYEES_DROPDOWN_LIST_REQUEST, getEmployeesDropdownListWorker);
  yield takeLatest(GET_LATE_EMPLOYEES_REQUEST, getLateEmployeesWorker);
  yield takeLatest(GET_TODAY_LATE_CLOCK_OUT, getTodayLateClockOutWorker);
  yield takeLatest(GET_CLOCK_IN_SUMMARY_REQUEST, getClockInSummaryWorker);
}
