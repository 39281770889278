import React, {useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , SELECTION , LOCAL_STORAGE_USER, socket, SOCKET, USER_ROLES } from "../../util";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import { useForm } from "react-hook-form";
import { useNavigate , useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { LocalUtils } from "../../util";
import moment from "moment";
import signupIntroImage from '../../assets/images/crop-9.png';

export default function SaloonBookAppointment({ pageInfo }) {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
	const [isNumberButtonEnabled, setisNumberButtonEnabled] = useState(false);

	const maxLength = 12; // with hypen
	const numberLength = 10; // total phone number digit 
	const regex = /^[0-9-]+$/;
	
	// On Number button click function 
	const handleNumberClick = (number) => {
		if (phoneNumber.length < numberLength && !phoneNumber.endsWith('-')) {
		setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + number);
		setIsNextButtonEnabled(phoneNumber.length === numberLength - 1);
		setisNumberButtonEnabled(phoneNumber.length === numberLength - 1);
		}
	};

	// On Keypress function 
	const handleKeyUp = (event) => {
		const value = event.target.value;
		setPhoneNumber(value.replace(/[^\d]/g, ''));
		setIsNextButtonEnabled(value.length === maxLength);
		setisNumberButtonEnabled(value.length === maxLength);
	};

	// Backspace button function 
	const handleBackspace = () => {
		setPhoneNumber(phoneNumber.slice(0, -1));
		console.log(phoneNumber.length)
		setIsNextButtonEnabled(phoneNumber.length === maxLength);
		setisNumberButtonEnabled(phoneNumber.length === maxLength);
		if( phoneNumber.length <= 0){
			setShowFirstStep(false);
			setShowIntialStep(true);	
		}
	};

	// clear button function
	const handleClear = () => {
		setPhoneNumber('');
		setIsNextButtonEnabled(false);
		setisNumberButtonEnabled(false);
	};

	// formate number with hypen 
	const formattedPhoneNumber = phoneNumber.match(/\d*/g).join('').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-').replace(/-*$/g, '');

	// Redirect page with phone number
	const navigate = useNavigate();
	// const data = { phoneNumber: formattedPhoneNumber};
	const redirectSignin = async () => {
		if(isNextButtonEnabled !== false){
		
			const validateMobile = await checkNumberExists();
			setShowFirstStep(false);
			if( validateMobile?.id && validateMobile.id !== null ){
				if(validateMobile?.mobileSubscribe !== SELECTION.YES) {
					setCustomerInfo(validateMobile);
					setShowSecondStep(true);
					return;
				}
				if(validateMobile?.emailSubscribe !== SELECTION.YES) {
					setCustomerInfo(validateMobile);
					setShowThirdStep(true);
				} else {
					let customerInfo = setOldCustomerDetails(validateMobile);
					await createCustomerEntry(customerInfo);
				}
			} else {
				setCustomerInfo([]);
				setShowSecondStep(true);
			}
		}
	}
  
	const checkNumberExists = async() => {
		try {
			const response = await api.post("/saloons/checkMobileExists/" , { 'mobile_no' : formattedPhoneNumber } );
			if (response.status === 200) {
				var responseInfo = response.data.data;
				LocalUtils.setItem(LOCAL_STORAGE_USER , responseInfo);
				return responseInfo;
			}
		} catch (error) {
			
		}
	}
  
	const { saloon_location_id } = useParams();
	const dispatch = useDispatch();
	
	const [showIntialStep, setShowIntialStep] = useState(true);
	const [showFirstStep, setShowFirstStep] = useState(false);
	const [showSecondStep, setShowSecondStep] = useState(false);
	const [showThirdStep , setShowThirdStep] = useState(false);
	const [customerInfo , setCustomerInfo] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isMobileSubscribed , setIsMobileSubscribed] = useState(SELECTION.YES);
	
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
		reset,
	} = useForm();

	const validationError = (error) => {
		return(error && ((<span className="text-danger">{error?.message}</span>)));
	}

	const emailRegex = () => {
		return ({
			value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ , 
			message: "Please Enter Valid Email" ,
		})
	}

	const onSubmit = async (data) => {

		if(showSecondStep && customerInfo?.id && customerInfo?.id !== null) {
			if(customerInfo.emailSubscribe !== SELECTION.YES) {
				setShowSecondStep(false);
				setShowThirdStep(true);
				return;
			}
		}

		let oldCustomerInfo = setOldCustomerDetails(customerInfo);
		data = {...oldCustomerInfo , ...data};

		if(showThirdStep) {
			data.email_subscription = SELECTION.YES;
		}

		await createCustomerEntry(data);
	}

	const createCustomerEntry = async (data) => {
		data.mobile_subscription = isMobileSubscribed;
		data.mobile_no = formattedPhoneNumber;
		data.saloon_location_id = saloon_location_id;
		try {
			const response = await api.post("/auth/createCustomerEntry", data);

			if(response?.data?.code === 101){
				checkServerSideValidation( response?.data?.message , setError)
			}else{
				if (response.status === 201) {
					LocalUtils.setItem(LOCAL_STORAGE_USER , response?.data?.data?.customer);
 					 setShowFirstStep(true);
					 setShowSecondStep(false);
					 setShowThirdStep(false);
					 reset();
					 redirectThankYou();
					//redirectBookService();
				}
			}
		} catch (error) {
			toast.error(error.response?.data?.message || error.message);
		} finally {
			setIsLoading(false);
		}
	}

	const redirectThankYou = () => {
		socket.emit(SOCKET.EMIT_QUEUE_DATA_CHANGE, {});
		navigate("/thank-you" , {state:{saloonLocationId:saloon_location_id}});
	}

	const checkServerSideValidation = (validationErrors , setError) => {
		
		if( typeof(validationErrors) !== undefined  ){
			if( Array.isArray(validationErrors)){
				validationErrors.forEach ( (value)  => {
					setError( ''+  value.field +'', {
						type : "custom",
						message: value.message,
					});
					
				});
			}
		}
	}

	const setOldCustomerDetails = (customerInfo) => {
		let data = {};
		data.name = customerInfo?.name;
		data.mobile_no = customerInfo?.mobile;
		data.email = customerInfo?.email;
		data.date = customerInfo?.birthDate;
		data.month = customerInfo?.birthMonth;
		data.mobile_subscription = customerInfo?.mobileSubscribe;
		data.email_subscription = customerInfo?.emailSubscribe;

		return data;
	}

	const skipThirdStep = async () => {
		let oldCustomerDetails = setOldCustomerDetails(customerInfo);
		await createCustomerEntry(oldCustomerDetails);
		redirectThankYou();
		//redirectBookService();
	}
	
	const redirectMainSignupPage = async () => {
		reset();
		setShowIntialStep(false);
		setShowFirstStep(true);
		setShowSecondStep(false);
		setShowThirdStep(false);
	}
	
	const manageBackButton = () => {
		reset();
		setShowFirstStep(true);
		setShowSecondStep(false);
		setShowThirdStep(false);
	}

	const redirectBookService = () => {
		navigate( "/book-services/" + saloon_location_id );
	}

	useEffect(() => {
		if(showFirstStep) {
			localStorage.removeItem(LOCAL_STORAGE_USER);
		}
	} , [showFirstStep]);

	useEffect(() => {
		if (!socket) return;
		  socket.emit(SOCKET.EMIT_JOIN, {
			roomId: "VISITOR",
			from: {
			  role : USER_ROLES.VISITOR,
			  saloon_location_id : saloon_location_id,
			}
		  });
	
		socket.on("disconnected", (data) => {
		  // console.log("socket got disconnected on",  moment().format("DD MMM, YY hh:mm:ss"), "because of ", data);
		});    
	
		return () => {
		  socket.off(SOCKET.EMIT_JOIN);
		};
	}, []);
	
	useEffect(() => {
	    const handleResize = () => {
	      const img = document.querySelector('.full-screen-image-container img');
	      img.style.width = `${window.innerWidth}px`;
	      img.style.height = `${window.innerHeight}px`;
	    };
	    window.addEventListener('resize', handleResize);
	    return () => {
	      window.removeEventListener('resize', handleResize);
	    };
	  }, []);
	
	const [lastActivity, setLastActivity] = useState(Date.now());
	
	useEffect(() => {
	    let inactivityTimeout = null;

	    const resetInactivityTimeout = () => {
	      clearTimeout(inactivityTimeout);
	      inactivityTimeout = setTimeout(() => {
	        // Reload the page after 10 seconds of inactivity
	        window.location.reload();
	      }, 30000); // 10 seconds
	    };

	    // Listen for mouse and keyboard events
	    document.addEventListener('mousemove', resetInactivityTimeout);
	    document.addEventListener('keydown', resetInactivityTimeout);
	    document.addEventListener('scroll', resetInactivityTimeout);
	    document.addEventListener('click', resetInactivityTimeout);

	    // Clean up event listeners when the component unmounts
	    return () => {
	      clearTimeout(inactivityTimeout);
	      document.removeEventListener('mousemove', resetInactivityTimeout);
	      document.removeEventListener('keydown', resetInactivityTimeout);
	      document.removeEventListener('scroll', resetInactivityTimeout);
	      document.removeEventListener('click', resetInactivityTimeout);
	    };
	}, []);
  
  return (
    <>
    { ( showIntialStep !== false ) && ( 	  
    		<div className="full-screen-image-container" onClick={redirectMainSignupPage}>
    	      <img src={signupIntroImage} alt="Full Screen Image" />
    	    </div>
    		
    		
	     
      )}
    { ( showFirstStep !== false ) && ( 	  
	    <div className="main-card">
	        <div className="sign-in-section scale15">
	          <div className="heading-div">
	            <h4 className="welcome-title">Welcome!</h4>
	            <h5 className="sign-in-msg">Please Sign in using your <br /> 10 digit phone number</h5>
	          </div>
	
	          <div className="sign-in-card">
	            <div className="display-div">
	              <input type="text" className="value" value={formattedPhoneNumber} onChange={handleKeyUp} disabled={isNextButtonEnabled} name="phone_number" autoComplete="off"
	              onKeyDown={(event) => {
	              if (!regex.test(event.key)) {event.preventDefault();} ; if (event.key === 'Backspace') {handleBackspace()} }} 
	              />
	              <button type="button" onClick={handleBackspace} className="backspace-btn"><i className="fa-solid fa-left-long arrow"></i></button>
	            </div>
	            <div className="number-pad-card">
	              <button type="button" onClick={() => handleNumberClick('1')} disabled={isNumberButtonEnabled} className="number-card number">1</button>
	              <button type="button" onClick={() => handleNumberClick('2')} disabled={isNumberButtonEnabled} className="number-card number">2</button>
	              <button type="button" onClick={() => handleNumberClick('3')} disabled={isNumberButtonEnabled} className="number-card number">3</button>
	              <button type="button" onClick={() => handleNumberClick('4')} disabled={isNumberButtonEnabled} className="number-card number">4</button>
	              <button type="button" onClick={() => handleNumberClick('5')} disabled={isNumberButtonEnabled} className="number-card number">5</button>
	              <button type="button" onClick={() => handleNumberClick('6')} disabled={isNumberButtonEnabled} className="number-card number">6</button>
	              <button type="button" onClick={() => handleNumberClick('7')} disabled={isNumberButtonEnabled} className="number-card number">7</button>
	              <button type="button" onClick={() => handleNumberClick('8')} disabled={isNumberButtonEnabled} className="number-card number">8</button>
	              <button type="button" onClick={() => handleNumberClick('9')} disabled={isNumberButtonEnabled} className="number-card number">9</button>
	              <button type="button" className="number-card option-btn" onClick={handleClear}>Clear</button>
	              <button type="button" onClick={() => handleNumberClick('0')} disabled={isNumberButtonEnabled} className="number-card number">0</button>
	              <button type="button" className="number-card option-btn next-btn" onClick={redirectSignin} disabled={!isNextButtonEnabled}>Next</button>
	            </div>
	          </div>
	        </div>
	      </div>
      )}
      { showSecondStep && (
    	<div className="main-card">
	      <div className="card-form card scale15 mb-250">
	        <div className="border-bottom p-3 mb-0 card-title">
	          <div className="number-div justify-content-center position-relative"> <button onClick={manageBackButton} className="back-arrow-ab"><i className="fa-solid fa-chevron-left icon"></i> Go Back</button> <i className="fa-solid fa-square-phone icon"></i><span className="number">{formattedPhoneNumber}</span></div>
	        </div>
	        <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" className="signin-form card-body">
	          <div className="row">
				{!customerInfo?.name && (
					<div className="col-sm-12">
	              <div className="form-group custome-form-group">
	                <label className="form-label custome-label" htmlFor="name">Name<span className="text-danger">*</span>
	                </label>
	                <input type="text" className="form-control" name="name" placeholder="Please Enter Your Name" autoComplete="off"
	                {...register("name", {
	                  required: "Please Enter Your Name",
	                  setValueAs:value => value.trim() 
	                })} />
	                {validationError(errors.name)}
	              </div>
	            </div>
				)}
	            <div className="col-sm-12">
	              <div className="form-group custome-form-group">
					<p className="mb-0 big-p-font">Select 'Yes' to Sign Up for exclusive future offers and discounts - stay tuned!</p>
	              </div>
	            </div>
	            <div className="col-sm-12">
	              <div className="form-group text-right mb-0 btns card-footer border-top-0 d-flex gap-2 justify-content-between">
	                <button type="submit" className="btn btn-primary send-btn w-50" title="Yes" onClick={() => setIsMobileSubscribed(SELECTION.YES)}>Yes</button>
	                <button type="submit" className="border btn btn-transaprent back-btn w-50" title="No" onClick={() => setIsMobileSubscribed(SELECTION.NO)}>No</button>
	              </div>
	            </div>
	          </div>
	        </form>
	      </div>
	    </div>  
      )}
	  {showThirdStep && (
		<div className="main-card main-card-column scale15">
		<h4 className="welcome-title">Join our Birthday Club for Special Gift/Offer</h4>
		<div className="card-form card mb-250">
		  <div className="border-bottom p-3 mb-0 card-title">
			<div className="number-div justify-content-center text-center"><span className="welcome-back">Welcome Back! {customerInfo?.name}</span></div>
		  </div>
		  <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" className="signin-form card-body">
			<div className="row">
			  <div className="col-sm-12">
				<div className="form-group custome-form-group">
				  <label className="form-label custome-label" htmlFor="date_of_birth">Date of Birth</label>
					<div className="select-mdiv">
						<select name="month" className="form-select w-50" {...register("month", { required: "Please Select Month" })} >
							<option value="">Select Month</option>
							<option value="January">January</option>
							<option value="February">February</option>
							<option value="March">March</option>
							<option value="April">April</option>
							<option value="May">May</option>
							<option value="June">June</option>
							<option value="July">July</option>
							<option value="August">August</option>
							<option value="September">September</option>
							<option value="October">October</option>
							<option value="November">November</option>
							<option value="December">December</option>
						</select>
						<select name="date" className="form-select w-50" {...register("date", { required: "Please Select Date" })} >
							<option value="">Select Date</option>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
							<option value="11">11</option>
							<option value="12">12</option>
							<option value="13">13</option>
							<option value="14">14</option>
							<option value="15">15</option>
							<option value="16">16</option>
							<option value="17">17</option>
							<option value="18">18</option>
							<option value="19">19</option>
							<option value="20">20</option>
							<option value="21">21</option>
							<option value="22">22</option>
							<option value="23">23</option>
							<option value="24">24</option>
							<option value="25">25</option>
							<option value="26">26</option>
							<option value="27">27</option>
							<option value="28">28</option>
							<option value="29">29</option>
							<option value="30">30</option>
							<option value="31">31</option>
						</select> 
					</div>
						{validationError(errors?.month)} 
						{validationError(errors?.date)} 
				</div>
			  </div>
			  {customerInfo.email === null && (
				<div className="col-sm-12">
					<div className="form-group custome-form-group">
					<label className="form-label custome-label" htmlFor="email">Email</label>
					<input type="text" className="form-control" name="email" placeholder="Please Enter Your Email" autoComplete="off" {...register("email" , { required: "Please Enter Your Email" , pattern: emailRegex() , setValueAs:value => value.trim() })} />
					{validationError(errors.email)}
					</div>
				</div>
			  )}
			  <div className="col-sm-12">
				<div className="form-group text-right mb-0 btns card-footer border-top-0 d-flex gap-2 justify-content-between">
				  <button type="submit" name="submit" className="btn btn-primary send-btn" title="Email Me Birthday Gift">Email Me Birthday Gift</button>
				  <button type="button" onClick={skipThirdStep} className="border btn btn-transaprent back-btn" title="Skip">Skip</button>
				</div>
			  </div>
			</div>
		  </form>
		</div>
	  </div> 
	  )}
    </>
 );
}