import { api , BACK_BASE_URL } from "../../util";
import axios from "axios"

export const getEmployeesApiCall = (payload = {}) => {
  let url = "/employees";

  return api.get(url, {
	    params: {
	      page: payload.page,
	      limit: payload.limit,
	      filter: payload.filter || {},
	      //   sort: payload.sort,
	      //   search: payload.search,
	    },
	  });
};

export const getEmployeesListApiCall = (payload = {}) => {
  let url = "/employees/list";

  return api.get(url, {
	    params: {
	      page: payload.page,
	      limit: payload.limit,
	      filter: payload.filter || {},
	      //   sort: payload.sort,
	      //   search: payload.search,
	    },
	  });
};

export const getEmployeesDropdownListApiCall = (payload = {}) => {
	  let url = "/employees/dropdownList";

	  return api.get(url, {
		    params: {
		      page: payload.page,
		      limit: payload.limit,
		      filter: payload.filter || {},
		      //   sort: payload.sort,
		      //   search: payload.search,
		    },
		  });
	};

export const employeeLoginApiCall = (payload) => {
    return axios.post(`${BACK_BASE_URL}/auth/employeeSignin`, payload)
}

export const getLateEmployeesApiCall = (payload = {}) => {
	let url = "/timesheet/getLateEmployees";
  
	return api.get(url, {
		params: {
			page: payload.page,
			limit: payload.limit,
			filter: payload.filter || {},
			//   sort: payload.sort,
			//   search: payload.search,
		},
	});
};

export const getTodayLateClockOutApiCall = (payload = {}) => {
	let url = "/timesheet/getTodayLateClockOut";
  
	return api.get(url, {
		params: {
			page: payload.page,
			limit: payload.limit,
			filter: payload.filter || {},
			//   sort: payload.sort,
			//   search: payload.search,
		},
	});
};

export const getClockInSummaryApiCall = (payload = {}) => {
	let url = "/timesheet/getClockInSummary";
  
	return api.get(url, {
		params: {
			page: payload.page,
			limit: payload.limit,
			filter: payload.filter || {},
			//   sort: payload.sort,
			//   search: payload.search,
		},
	});
};