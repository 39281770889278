import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef , useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { api, isActionPermitted , STATUS, onlyNumber , DB_FORMAT, downloadExcel } from "../../util";
import { getEmployeesRequest } from "../../redux/employees/actions";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "bootstrap";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import Loading from "../../components/common/Loading";


const EmployeeSchedule = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saloons } = useSelector((state) => state.saloons);
  
  const { permissions , code } = useSelector((state) => state.user);
  const [ initialValues, setInitialValues] = useState({});
  const [ isLoading, setIsLoading] = useState(false);
  const [ dynamicAddedField, setDynamicAddedField] = useState([]);
  
  const [saloonEmployees, setSaloonEmployees] = useState([]);
  const [empTotalTimeInfo, setEmpTotalTimeInfo] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState([]);
  const [dbDates, setDbDates] = useState([]);
  const [isGenerateExcelEnabled , setIsGenerateExcelEnabled] = useState(true);
  const [askForConfirmation , setAskForConfirmation] = useState(false);
  const [isFinalized , setIsFinalized] = useState(false);
  const [isSubmittedOnce , setIsSubmittedOnce] = useState(false);
 
  const scheduleFormRef = useRef();
  
  useEffect(() => {
    if (isActionPermitted("employee", "read", permissions)) {
      dispatch(getEmployeesRequest());
      dispatch(getSaloonsRequest({'sort' : 'name'}));
    }
  }, [dispatch, permissions]);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
    });
    
  }, [saloons]);
  
  const daysOfWeeks = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  
  const getEmployeeList = async (saloonId = null , inputScheduleDate = null ,  employeeId = null , values = null , setValues = null  ) => {
	  const saloon  = ( saloonId ? saloonId : saloonSelection );
  	  const  scheduleSelectedDate = ( inputScheduleDate ? inputScheduleDate : scheduleDate );
  	  if( !saloon ){
  		  toast.error("Please select salon");
  		  return false;
  	  }
  	  if( !scheduleSelectedDate ){
		  toast.error("Please select schedule start date");
		  return false;
	  }
  	  setIsLoading(true) 
      try {
    	var payLoad = {};
    	if( saloon ){
    		payLoad = { params: { filter: { saloonId : saloon , employeeId : employeeId , scheduleDate : scheduleSelectedDate   } } }
    		const response = await api.get("/employees/getSaloonEmployee" , payLoad );
            if (response.status === 200) {
            	setSaloonEmployees(response.data.data.list);
            	if(response?.data?.data?.scheduleInfo){
            		setScheduleInfo(response.data.data.scheduleInfo);
            	}
            	if(response?.data?.data?.allDates){
            		setDbDates(response.data.data.allDates)
            	}
            	
            	const allReceviedDates = response?.data?.data?.allDates;
            	const allScheduleInfo = response?.data?.data?.scheduleInfo;
				const allTargetInfo = response?.data?.data?.targetInfo;
				const allDbDates = response?.data?.data?.allDates;
            	const dayWiseFields = {};
            	const saloonEmployeeIds = {};
				const allTargetFields = {};

				//const finalizedSchedule = allScheduleInfo.filter((value) => value.isFinalized === STATUS.ACTIVE && value.scheduleDate == scheduleDate);
				const finalizedSchedule = allScheduleInfo.filter((value) => value.isFinalized === STATUS.ACTIVE && ( response?.data?.data?.allDates.indexOf(value.scheduleDate) !== -1 ) && ( response?.data?.data?.allDates.indexOf(scheduleDate) !== -1 ) );
				//const submittedSchedule = allScheduleInfo.filter((value) => value.scheduleDate == scheduleDate);
				const submittedSchedule = allScheduleInfo.filter((value) => ( response?.data?.data?.allDates.indexOf(value.scheduleDate) !== -1 ) && ( response?.data?.data?.allDates.indexOf(scheduleDate) !== -1 ) );
				// console.log('finalizedSchedule' , finalizedSchedule)
				// console.log('submittedSchedule' , submittedSchedule	)
				// console.log('allScheduleInfo' , allScheduleInfo	)
				// console.log('scheduleDate' , scheduleDate	)
				setIsSubmittedOnce(false);
				if( submittedSchedule?.length > 0 ) {
					setIsSubmittedOnce(true);
				} 
				setIsFinalized(false);
				if(finalizedSchedule?.length > 0) {
					setIsFinalized(true);
				}
				
				daysOfWeeks.forEach( ( daysOfWeek, dayOfWeekIndex )  => {
					const searchKey = findTargetKey(allTargetInfo , allDbDates[dayOfWeekIndex]);
					const targetData = ( searchKey !== null ?  allTargetInfo[searchKey] : [] );
					const targetValue  = targetData?.targetValue;
					const targetFieldName = daysOfWeek + "_target";
					allTargetFields[targetFieldName] = targetValue;
					scheduleFormRef.current?.setValues({
						...scheduleFormRef.current?.values , 
						[targetFieldName] : (targetValue && targetValue !== null ? targetValue : "")
					})
				})
            	
				response.data.data.list.forEach( ( saloonEmployee , index ) => {
            		 var totalTime = 0;
            		daysOfWeeks.forEach( ( daysOfWeek, dayOfWeekIndex )  => {
                		
                		const searchKey = findKey( allScheduleInfo, saloonEmployee?.Employee?.id , allReceviedDates[dayOfWeekIndex]  );
        				const scheduleData = ( searchKey !== null ?  allScheduleInfo[searchKey] : [] );
        				const startTime  = scheduleData?.ScheduleInfo?.startTime;
        				const endTime  = scheduleData?.ScheduleInfo?.endTime;
                		
                		var start_field_name =  daysOfWeek + '_start_time_' + saloonEmployee?.Employee?.id
                		var end_field_name =  daysOfWeek + '_end_time_' + saloonEmployee?.Employee?.id
                		dayWiseFields[start_field_name] = (startTime !== endTime ? startTime : null);
                		dayWiseFields[end_field_name] = (endTime !== startTime ? endTime : null);
                		
                		if( startTime && endTime ){
            			  	const differenceInSeconds = diffBetweenTwoTimeIntoSecond(startTime,endTime);
            			    if( differenceInSeconds > 0 ){
            			    	totalTime = parseFloat(totalTime) + parseFloat(differenceInSeconds);
            			    }	
            		    }
                		
                		
                	})
                	saloonEmployeeIds[ saloonEmployee?.Employee?.id + '_total_time'] = 0;
                	const workingHrs = secondsToHoursMinutes(totalTime);
            		saloonEmployeeIds[ saloonEmployee?.Employee?.id + '_total_time'] =  workingHrs;
                })
                if( response.data.data.list.length === 0 ){
                	setIsLoading(true);
                }
            	setEmpTotalTimeInfo(saloonEmployeeIds);
            	setDynamicAddedField(dayWiseFields);
				setTargetFields(allTargetFields);
            }
        }
    	setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
         toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsLoading(false);
      }
  };
  
  const onSubmit = async (values) => {
		const data = {};
		if(!scheduleDate){
			toast.error("Please select schedule start date.");
			return false;
		} else {
			data.scheduleDate = scheduleDate; 
		}
		if(!saloonSelection){
			toast.error("Please select salon.");
			return false;
		} else {
			data.saloonId = saloonSelection; 
		}

		if(askForConfirmation) {
			setAskForConfirmation(false);
			if(values?.confirmation_code === "") {
				toast.error("Please enter confirmation code");
				return false;
			}
	
			if(values?.confirmation_code) {
				data.confirmation_code = values.confirmation_code;
			}
		}

		if(isFinalized) {
			data.is_finalized = isFinalized;
		}
	  
	  setIsLoading(true)
	    if (id === "add") {
	      try {
	        const response = await api.post("/employees/manageSchedule", {
	          ...data,
	          dynamicAddedField,
			  targetFields,
	        });
	        if (response.status === 201) {
			  setIsSubmittedOnce(true);
			  setShowConfirmationCodeModal(false);
	          toast.success(response?.data?.message || response.message);
	          navigate("/schedules/add");
			  setIsGenerateExcelEnabled(true);
			  if(values?.is_finalized === STATUS.ACTIVE) {
				setIsFinalized(true);
				scheduleFormRef.current?.setValues({
					...scheduleFormRef.current?.values , 
					is_finalized : ""
				})
			  }
	        } else if(response.status === 200 && response?.data?.code === 101) {
				scheduleFormRef.current?.setValues({
					...scheduleFormRef.current?.values ,
					confirmation_code : ""
				})
				setShowConfirmationCodeModal(true);
			}
	      } catch (error) {
			setShowConfirmationCodeModal(false);
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    } else {
	      try {
	        const response = await api.post("/employees/manageSchedule" , {
	          ...data,
	          dynamicAddedField,
			  targetFields,
	        });
	        if (response.status === 201) {
			  setIsSubmittedOnce(true);
			  setShowConfirmationCodeModal(false);
	          toast.success(response?.data?.message || response.message);
	          navigate("/schedules/add");
			  setIsGenerateExcelEnabled(true);
			  if(values?.is_finalized === STATUS.ACTIVE) {
				setIsFinalized(true);
				scheduleFormRef.current?.setValues({
					...scheduleFormRef.current?.values , 
					is_finalized : ""
				})
			  }
	        } else if(response.status === 200 && response?.data?.code === 101) {
				scheduleFormRef.current?.setValues({
					...scheduleFormRef.current?.values ,
					confirmation_code : ""
				})
				setShowConfirmationCodeModal(true);
			}
	      } catch (error) {
			setShowConfirmationCodeModal(false);
	        toast.error(error.response?.data?.message || error.message);
	      } finally {
	        setIsLoading(false);
	      }
	    }
  };
  
  /*
  useEffect(() => {
    if ( id !== "add") {
		
      const getSchedule = async (id) => {
        try {
          const response = await api.get("/schedules/" + id);
          if (response.status === 200) {
        	if( !response?.data?.data?.saloonId ){
        		setSaloonSelection( response?.data?.data?.saloonId );
        	}
        	if( !response?.data?.data?.scheduleDate ){
        		setScheduleDate( response?.data?.data?.scheduleDate );
        	}
        	
        	
        	var employeeId = response?.data?.data?.employeeId;
        	const dayWiseFields = {};
        	getEmployeeList( response?.data?.data?.saloonId , response?.data?.data?.employeeId );
    	 	daysOfWeeks.forEach( daysOfWeek  => {
    	 		
    	 		var start_field_name =  daysOfWeek + '_start_time_' + employeeId
        		var end_field_name =  daysOfWeek + '_end_time_' + employeeId
        		dayWiseFields[start_field_name] = "";
        		dayWiseFields[end_field_name] = "";
        	})
        	setDynamicAddedField(dayWiseFields);
          }
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        } finally {
          setIsLoading(false);
        }
      };
    }
  }, [id, saloons]); 
  */

//   const handleTimeSelection = (e , values , setValues ) => {
// 	  const { name , value } = e.target;
// 	  setDynamicAddedField({ ...dynamicAddedField, [name]: value })
//   }
  
  const handleTimePickerSelection = (e , fieldName , employeeId ) => {
	  setIsGenerateExcelEnabled(false);
	  const selectedValue =  moment( new Date(e) ).format('HH:mm:ss');
	  setDynamicAddedField({ ...dynamicAddedField, [fieldName]: selectedValue })
	  var totalTime = 0;
	  daysOfWeeks.forEach( ( daysOfWeek, dayOfWeekIndex )  => {
		  var startTime = dynamicAddedField[ daysOfWeek + '_start_time_' + employeeId ];
		  var endTime = dynamicAddedField[ daysOfWeek + '_end_time_' + employeeId ];
		  const checkStringIncludeEndTime = fieldName.includes(daysOfWeek + '_end_time_' + employeeId);
		  const checkStringIncludeStartTime = fieldName.includes(daysOfWeek + '_start_time_' + employeeId);
		  if( checkStringIncludeEndTime ){
			  endTime = selectedValue;
		  }
		  if( checkStringIncludeStartTime ){
			  startTime = selectedValue;
		  }
		  if( startTime && endTime ){
			  	const differenceInSeconds = diffBetweenTwoTimeIntoSecond(startTime,endTime);
			    if( differenceInSeconds > 0 ){
			    	totalTime = parseFloat(totalTime) + parseFloat(differenceInSeconds);
			    }	
		  }
	  })
	  const workingHrs = secondsToHoursMinutes(totalTime);
	  setEmpTotalTimeInfo({ ...empTotalTimeInfo, [employeeId + '_total_time']: workingHrs  })
  }
  
  function diffBetweenTwoTimeIntoSecond(startTime,endTime){
	    const startTimeMoment = moment(startTime, "HH:mm:ss");
	    const endTimeMoment = moment(endTime, "HH:mm:ss");

	    const differenceInMilliseconds = endTimeMoment.diff(startTimeMoment);

	    const seconds = differenceInMilliseconds / 1000;
	    
	    return seconds;
  }
  
  function secondsToHoursMinutes(seconds) {
    // Create a Moment.js duration object
    const duration = moment.duration(seconds, 'seconds');

    // Extract hours and minutes from the duration
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    
    var outPut = "";
    if( parseFloat(hours)  > 0 && parseFloat(minutes) > 0 ){
    	outPut =  hours + " hrs " + minutes + " mins";  
    } else {
    	if( parseFloat(hours)  > 0 ){
    		outPut =  hours + " hrs";
    	}
    	if( parseFloat(minutes)  > 0 ){
    		outPut =  minutes + " mins";
    	}
    }
    return outPut;
}
  
  const [scheduleDate, setScheduleDate] = useState(null);
  const [saloonSelection, setSaloonSelection] = useState(null);
  const onScheduleDateChange = (value , values, setValues ) => {
	  var scheduleSelectionDate = moment(new Date(value ) ).format( "YYYY-MM-DD");
	  var checkMondaySelected = isMonday (scheduleSelectionDate);
	  if(!checkMondaySelected){
		  toast.error("only monday allowed to selected");
		  return false;
	  }
	  setScheduleDate( scheduleSelectionDate );
	  /*const { name, value } = event.target;
	  setScheduleDate( moment(value).format( "YYYY-MM-DD") );
	  setValues({ ...values, joining_date: moment(value).format( "YYYY-MM-DD") });*/
  };
  const isMonday = (date) => {
	  return moment(date).day() === 1; // Monday is represented by 1 in Moment.js
  };
  
  const isWeekend = (date: Dayjs) => {
	  const day = date.day();
	  return day !== 1;
  };
 
  const findKey = (array, id, date) => {
	  for (let i = 0; i < array.length; i++) {
	    const innerArray = array[i];
	    if (innerArray.employeeId === id && innerArray.scheduleDate === date) {
	        return i; // Returning the array indices as the key
	    }
	  }
	  return null; // If not found
  };

  const findTargetKey = (array , date) => {
	for (let i = 0; i < array.length; i++) {
		const innerArray = array[i];
		if(innerArray.targetDate === date) {
			return i; // Returning the array indices as the key
		}
	}
	return null; // If not found
  }
  
  // Calculate the start of the current week (Monday)
  const startOfWeek = moment().clone().startOf('isoWeek');
  const startOfWeekDate = startOfWeek.format('YYYY-MM-DD');

  const generateExcel = async () => {
	
	if( !saloonSelection ){
		toast.error("Please select salon");
		return false;
	}
	setIsLoading(true) 
	try {
    	var payLoad = {};
		payLoad = { params: { filter: { saloonId : saloonSelection , employeeId : '' , scheduleDate : scheduleDate , isExcelExport : true } } }
		
		const response = await api.get("/employees/getSaloonEmployee" , payLoad );
		
		if (response.status === 200) {
			downloadExcel(response?.data?.data);
		}
	} catch (error) {
		toast.error(error.response?.data?.message || error.message);
	} finally {
		setIsLoading(false);
	}
  }

  const [currentDayIndex , setCurrentDayIndex] = useState(0);
  const [isPastDate , setIsPastDate] = useState(false)

  const getEmployees = () => {
	getEmployeeList( saloonSelection , scheduleDate  )
	var currentDay = (moment().day() - 1);
	if(currentDay === -1 ) { 
		currentDay = 7;
	}
	setCurrentDayIndex(currentDay);
	if(moment(scheduleDate) < moment()) {
		setIsPastDate(true);
	} else {
		setIsPastDate(false);
	}
  }

  const [showConfirmationCodeModal , setShowConfirmationCodeModal] = useState(false);
  const toggle = () => setShowConfirmationCodeModal(!showConfirmationCodeModal);
  const [targetFields , setTargetFields] = useState([]);

  const handleTargetFields = (e) => {
	setTargetFields({...targetFields , [e.target?.name] : e.target?.value});
  }

  const isUserHasCode = ((code !== null && code !== 0) ? true : false);

  useEffect(() => {
	if(saloonSelection && scheduleDate) {
		getEmployees();
	}
  } , [saloonSelection , scheduleDate])

  return (
    <React.Fragment>
      <Formik
        validateOnChange
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
        enableReinitialize
		innerRef={scheduleFormRef}
      >
        {({ errors, values, touched, setValues , submitForm }) => (
          <>
            <PageHeader
              shouldShowAddBtn={false}
              title={id === "add" ? "Employee Schedule" : "Employee Schedules"}
            />
            <Card>
            <CardBody>
              	<Row className="gy-3 gx-3">
	            	<Col xl={3} md={3} sm={12} xs={12}>
						<Label>Salon</Label>
						<Input caret type="select" name="saloon" 
							onChange={(e) => {
								setSaloonSelection(e.target.value);
							}}
							>
							<option value={""}>Select</option>
							{saloons.map((_, index) => {
							var selectedSaloon = "";
							if( _.Saloon.id === saloonSelection ){
								selectedSaloon = "selected";
							}
							return (
								<option key={_.Saloon.id} {...selectedSaloon} value={_.Saloon.id}>
									{_.Saloon.name}
								</option> 
							);
							})}
						</Input>
	            	</Col>
					<Col xl={2}>
						<FormGroup>
							<Label htmlFor="schedule_date">Schedule Start Date</Label>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker 
									views={['year', 'month', 'day']}
									format="DD-MM-YYYY"
									minDate={dayjs(startOfWeekDate)}	
									shouldDisableDate={isWeekend}
									onChange={ (e) => {
										onScheduleDateChange(e);
									}} 
								/>
							</LocalizationProvider>
						</FormGroup>
					</Col> 
					<Col xl={3} className="d-flex align-items-center pt-2">
						<Button type="button" className="btn btn-primary" onClick={() => getEmployees()}>Search</Button>
					</Col>
            	</Row>
            </CardBody>
          </Card>
		  {!isLoading ? (
			<Card>
              <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                <i className="bi bi-clock me-2"> </i>
                {id === "add" ? "Manage Employee Attendance" : "Manage Employee Attendance"}
              </CardTitle>
              <CardBody>
                <Form>
                  <Row>
                  <Table className="table-bordered">
                    <thead>
                      <tr>
	                    <th style={{width: "6%"}}>Sr No</th>
                      	<th style={{width: "9%"}}>Employee</th>
                        <th style={{width: "11.2%"}}>Monday</th>
                        <th style={{width: "11.2%"}}>Tuesday</th>
                        <th style={{width: "11.2%"}}>Wednesday</th>
                        <th style={{width: "11.2%"}}>Thursday</th>
                        <th style={{width: "11.2%"}}>Friday</th>
                        <th style={{width: "11.2%"}}>Saturday</th>
                        <th style={{width: "11.2%"}}>Sunday</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    	( ( saloonEmployees ) && saloonEmployees.map( ( saloonEmployee , index ) => {
                    		
                    		let colorCode = "";
                    		if( isSubmittedOnce ){
                    			colorCode = "orange";
                    		}
                    		if( isFinalized ){
                    			colorCode = "green";
                    		}
                    		return ( 
	                    		<tr>
	                    		<td className="text-center">{ ( index + 1 ) }</td>
	                    		<td>{  saloonEmployee?.Employee?.firstName + ' - '  + saloonEmployee?.Employee?.lastName  }</td>
	                    		{
	                    			daysOfWeeks.map( (daysOfWeek , dayOfWeekIndex )  => {
	                    				const startFieldName =  daysOfWeek + '_start_time_' + saloonEmployee?.Employee?.id
	                    				const endFieldName =  daysOfWeek + '_end_time_' + saloonEmployee?.Employee?.id
	                    				const searchKey = findKey( scheduleInfo, saloonEmployee?.Employee?.id , dbDates[dayOfWeekIndex]  );
	                    				const scheduleData = ( searchKey !== null ?  scheduleInfo[searchKey] : [] );
	                    				const startTime  = scheduleData?.ScheduleInfo?.startTime;
	                    				const endTime  = scheduleData?.ScheduleInfo?.endTime;
	                    				
	                    				// var displayStartTime = null;
	                    				// var displayEndTime = null;
	                    				
	                    				if( startTime ){
	                    					var startHours = moment(startTime, 'HH:mm:ss').hours();
	                    					var startMinutes = moment(startTime, 'HH:mm:ss').minutes();
	                    					const currentStartDate = moment();
	                    				    currentStartDate.set({ hour: startHours, minute: startMinutes, second: 0 });
	                    				    // displayStartTime = currentStartDate.format("YYYY-MM-DD HH:mm:ss");
	                    				}
	                    				if( endTime ){
	                    					var endHours = moment(endTime, 'HH:mm:ss').hours();
	                    					var endMinutes = moment(endTime, 'HH:mm:ss').minutes();
	                    					const currentEndDate = moment();
	                    					currentEndDate.set({ hour: endHours, minute: endMinutes, second: 0 });
	                    				    // displayEndTime = currentEndDate.format("YYYY-MM-DD HH:mm:ss");
	                    				}
	                    				
	                    				return ( 
	                    					<td className="timepicker-td">
		                    					<LocalizationProvider dateAdapter={AdapterDayjs}>
		                    				        <TimePicker disabled={(!isUserHasCode && isPastDate && currentDayIndex > dayOfWeekIndex) ? true : false}
																label="Start Time"
		                    				        			format="hh:mm A"
		                    				        			name={startFieldName}
		                    				        			value={ ( dynamicAddedField[startFieldName] !== null ?  dayjs(moment().format(DB_FORMAT.DATE) + dynamicAddedField[startFieldName] ) : null ) }
					                    				        onChange={(e) => {
						        	                  				handleTimePickerSelection(e, startFieldName , saloonEmployee?.Employee?.id );
					                    				        }}
					                    				        sx={{
					                    				            '& .MuiFormLabel-root': {
					                    				              color: colorCode,
					                    				            },
					                    				            '& .MuiInputBase-root': {
					                    				            	borderColor: colorCode ,
					                    				            },
					                    				            '& .MuiTextField-root': {
					                    				            	borderColor: colorCode ,
					                    				            },
					                    				            '& .MuiOutlinedInput-notchedOutline': {
					                    				            	borderColor: colorCode ,
					                    				            },
					                    				            '& .MuiOutlinedInput-root': {
					                    				                '& fieldset': {
					                    				                  borderColor:colorCode + ' !important',
					                    				                },
					                    				            },
					                    				        }}
																slotProps={{
																	actionBar : {
																		actions : ['clear' , 'accept'] , sx : {justifyContent : "flex-start" , justifyContent : "flex-end"},
																	}
																}}
																onAccept={(newTime) => {
																	// console.log('newTime' , newTime)
																	if(newTime === null) {
																		const df = dynamicAddedField;
																		df[startFieldName] = null;
																		setDynamicAddedField({...df});
																	}
																}}
		                    				        />
		                    				    </LocalizationProvider>
		                    				    <LocalizationProvider dateAdapter={AdapterDayjs}>
		                    				        <TimePicker disabled={(!isUserHasCode && isPastDate && currentDayIndex > dayOfWeekIndex) ? true : false}
																label="End Time" 
		                    				        			name={endFieldName} 
		                    				        			value={ ( dynamicAddedField[endFieldName] !== null ?  dayjs(moment().format(DB_FORMAT.DATE) + dynamicAddedField[endFieldName] ) : null ) }
					                    				        onChange={(e) => {
					                    				        	handleTimePickerSelection(e, endFieldName , saloonEmployee?.Employee?.id );
					                    				        }}
					                    				        sx={{
					                    				            '& .MuiFormLabel-root': {
					                    				              color: colorCode,
					                    				            },
					                    				            '& .MuiInputBase-root': {
					                    				            	borderColor: colorCode ,
					                    				            },
					                    				            '& .MuiTextField-root': {
					                    				            	borderColor: colorCode ,
					                    				            },
					                    				            '& .MuiOutlinedInput-notchedOutline': {
					                    				            	borderColor: colorCode ,
					                    				            },
					                    				            '& .MuiOutlinedInput-root': {
					                    				                '& fieldset': {
					                    				                  borderColor:colorCode + ' !important',
					                    				                },
					                    				            },
					                    				        }}
																slotProps={{
																	actionBar : {
																		actions : ['clear' , 'accept'] , sx : {justifyContent : "flex-start" , justifyContent : "flex-end"},
																	}
																}}
																onAccept={(newTime) => {
																	// console.log('newTime' , newTime)
																	if(newTime === null) {
																		const df = dynamicAddedField;
																		df[endFieldName] = null;
																		setDynamicAddedField({...df});
																	}
																}}
		                    				        />
		                    				    </LocalizationProvider>
		                    				</td>
	                    				)	
	                    			})
	                    		}
	                    		<td>{ empTotalTimeInfo[ saloonEmployee?.Employee?.id + "_total_time" ] }</td>
	                    		</tr>
                    		)
                    	}))
                    }
					{
						(saloonEmployees.length > 0 && (
							<tr>
								<td colSpan={2}></td>
								{daysOfWeeks.map((daysOfWeek , dayOfWeekIndex ) => {
									const fieldName = daysOfWeek + "_target";
									return (
										<td>
											<Col>
												<Label>Target</Label>
												<Field
													disabled={(!isUserHasCode && isPastDate && currentDayIndex > dayOfWeekIndex) ? true : false} 
													type="text" 
													name={fieldName}
													value={values[fieldName]}
													placeholder="Target"
													className="form-control"
													onChange={(e) => {
														onlyNumber(e , values , setValues);
														handleTargetFields(e);
													}}
												/>
											</Col>
										</td>
									)
								})}
							</tr>
						))
					}
                    {
                    	( saloonEmployees.length === 0 ) && ( <tr><td className="text-center" colSpan="10"><i class="bi bi-exclamation-circle"></i> No Data Found</td></tr> ) 
                    }
                    </tbody>
                  </Table>
                  </Row>
                 <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                    <Button
                      color="transaprent"
                      className="border"
                      type="button"
                      onClick={() => navigate("/")}
                    >
                      Cancel
                    </Button>
					{!isFinalized && (
						<Button type="submit" disabled={isLoading} className="btn btn-primary">
							Submit
						</Button>
					)}
					{isUserHasCode && isSubmittedOnce && (
						<Button type="button" disabled={isLoading} className="btn btn-primary" 
							onClick={() => {
								setValues({...values , is_finalized : STATUS.ACTIVE});
								submitForm();
							}}
						>
							Submit & Finalize
						</Button>
					)}
					<Button type="button" title="Generate Excel" disabled={!isGenerateExcelEnabled || isLoading} onClick={() => generateExcel()} className="btn btn-primary">
						Generate Excel
                    </Button>
                  </div>

				  <Modal size="md" centered isOpen={showConfirmationCodeModal} toggle={toggle}>
						<ModalHeader toggle={toggle} className="text-capitalize">
							Confirmation Code
						</ModalHeader>
						<ModalBody>
							<Row>
								<Col>
									<FormGroup>
										<Label for="confirmation_code">
											Enter Confirmation Code
										</Label>
										<Field
											onChange={(e) => {
												onlyNumber(e , values , setValues);
											}}
											id="confirmation_code"
											name="confirmation_code"
											placeholder="Confirmation Code"
											type="text"
											maxLength={6}
											autoComplete="off"
											className={
												"form-control" +
												(errors.confirmation_code && touched.confirmation_code ? " is-invalid" : "")
											}
										/>
										{errors.confirmation_code && touched.confirmation_code && (
											<span component="div" className="invalid-feedback">
											{errors.confirmation_code}
											</span>
										)}
									</FormGroup>
								</Col>
							</Row>
						</ModalBody>
						<ModalFooter tag={"div"} className="transparant border-0 bg-light bg-light">
							<Button
								type="button"
								onClick={toggle}
								color="transprent"
								className="border"
							>
								Cancel
							</Button>
							<Button
								type="button"
								color={"primary"}
								className="text-capitalize"
								onClick={() => {
									setAskForConfirmation(true);
									submitForm();
								}}
							>
								Add
							</Button>
						</ModalFooter>
					</Modal>
                </Form>
              </CardBody>
            </Card>
		  ) : (
			<Loading />
		  )}
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default EmployeeSchedule;
