import React, {useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Label,
  Input,
} from "reactstrap";
import { DB_FORMAT, exportExcel, getLocaleFixedValue, isActionPermitted, SELECTION, STATUS, USER_TYPES } from "../../util";
import { getPayrollReportRequest } from "../../redux/report/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import PageHeader from "../../components/common/PageHeader";
import { getEmployeesRequest } from "../../redux/employees/actions";
import NoDataFound from "../../components/common/NoDataFound";
import Loading from "../../components/common/Loading";

const PayrollReport = () => {

    const dispatch = useDispatch();
    const { permissions , role } = useSelector((state) => state.user);
    const { saloons } = useSelector((state) => state.saloons);
    const { employees } = useSelector((state) => state.employees);
    const { reports , count , loading } = useSelector((state) => state.reports);
    const [paginationConfig, setPaginationConfig] = useState({
        filter: {
            // name: "",
            from:  moment().format(DB_FORMAT.DATE),
            to:  moment().format(DB_FORMAT.DATE),
            location: "",
            employee: "",
        },
    });
    const [employeeFilter , setEmployeeFilter] = useState({
        filter : {
            working_location : ""
        }
    })

    // const [showPayrollReport , setShowPayrollReport] = useState(true);
    // const togglePayrollReport = () => setShowPayrollReport(!showPayrollReport);

    useEffect(() => {
        if (isActionPermitted("payroll-report", "read", permissions)) {
            setDataFound(false);
            if(role == USER_TYPES.SUPER_ADMIN) {
                dispatch(getSaloonsRequest());
            }
            dispatch(getEmployeesRequest(employeeFilter));
            dispatch(getPayrollReportRequest(paginationConfig));
        }
    } , [paginationConfig]);

    const onDateRangeChange = ({ target }) => {
        const { name, value } = target;
        const pc = paginationConfig;
        if (name === "from") {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        } else {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        }
        setPaginationConfig({ ...pc });
    };

    // const onSearchChange = ({ target }) => {
    //     const { value } = target;
    //     const pc = paginationConfig;
    //     pc.filter.name = value;
    //     setPaginationConfig({ ...pc });
    // };
  
    const [startDate , setStartDate] = useState(paginationConfig.filter.from);
    const [endDate , setEndDate] = useState(moment().format( DB_FORMAT.DATE));

    const [dataFound , setDataFound] = useState(false);

    return (
        <React.Fragment>
            <PageHeader title="Payroll Report" shouldShowAddBtn={true} onAddBtnClick={() => exportExcel('/reports/payrollReport' , paginationConfig)} addBtnText="Export Excel" />
            <Card>
                <CardBody>
                    <Row className="gy-3 gx-3">
                        <Col xl={3} md={3} sm={12} xs={12}>
                            <Label>Employee</Label>
                            <Input caret type="select" name="location" value={paginationConfig.filter.employee}
                                onChange={(e) => {
                                let x = paginationConfig;
                                x.filter.employee = e.target.value;
                                setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                }}
                                >
                                <option value={""}>All Employee</option>
                                {employees.map((_, index) => (
                                <option key={_.id} value={_.Employee.id}>
                                    {_.Employee.firstName} {_.Employee.lastName}
                                </option>
                                ))}
                            </Input>
                        </Col>
                        {role === USER_TYPES.SUPER_ADMIN && (
                            <Col xl={3} md={3} sm={12} xs={12}>
                                <Label>Location</Label>
                                <Input caret type="select" name="location" value={paginationConfig.filter.location}
                                    onChange={(e) => {
                                    const ef = employeeFilter;
                                    ef.filter.working_location = e.target.value;
                                    setEmployeeFilter({ ...ef });
                                    const x = paginationConfig;
                                    x.filter.employee = ((employees.filter((value) => value?.Employee?.id == x.filter.employee && (value?.Employee?.saloonIds.split(',')).includes(e.target.value))?.length > 0) ? x.filter.employee : '');
                                    x.filter.location = e.target.value;
                                    setPaginationConfig({ ...x });
                                    }}
                                    >
                                    <option value={""}>All Location</option>
                                    {saloons.map((_, index) => (
                                    <option key={_.id} value={_.Saloon.id}>
                                        {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                                    </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>From</Label>
                            <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
                        </Col>
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>To</Label>
                            <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {!loading ? (
                <>
                {!dataFound && (
                    <NoDataFound />
                )}
                <Card className="newdashboard-table individual-table">
                <CardBody className="px-2 py-0">
                    <Table responsive bordered>
                    {!loading && dataFound && (
                        <thead>
                        <tr>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>{role === USER_TYPES.ADMIN ? 'Sr.No.' : ''}</th>
                            <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Employee</th>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Hours</th>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Pay Rate</th>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Hourly Pay</th>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Tips</th>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Commissions</th> 
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Pay</th> 
                        </tr>
                    </thead>
                    )}
                    <tbody>
                        {reports?.length > 0 && 
                            reports.map((report) => (
                                <>
                                {report?.Employee && report?.Employee?.length > 0 && (
                                    <>
                                        {!dataFound && setDataFound(true)}
                                        {role == USER_TYPES.SUPER_ADMIN && (
                                            <tr>
                                                <td style={{'font-weight' : 'bold'}} colSpan={8}>{report?.name}</td>
                                            </tr>
                                        )}
                                        {(() => {
                                            const tableColumns = [];
                                            var totalSalonHourlyPay = 0;
                                            var totalSalonTips = 0;
                                            var totalSalonCommissions = 0;
                                            var totalSalonPay = 0; 
                                            report?.Employee.map((row , index) => {
                                                var totalWorkingHours = row?.totalWorkingHours ? row.totalWorkingHours : 0;
                                                if(row?.Employee?.isPaidBreak === SELECTION.YES) {
                                                    totalWorkingHours = parseFloat(totalWorkingHours) + parseFloat(row?.totalBreakHours ? row.totalBreakHours : 0);
                                                }
                                                const hourlyRate = row?.hourlyRate ? (row.hourlyRate) : 0;
                                                const totalHourlyPay = (parseFloat(hourlyRate)*parseFloat(totalWorkingHours));
                                                const tipAmount = row?.tipAmount ? (row.tipAmount) : 0;
                                                const commissionValue = row?.commissionValue ? (row.commissionValue) : 0;
                                                const totalPay = (parseFloat(totalHourlyPay) + parseFloat(tipAmount) + parseFloat(commissionValue));
                                                totalSalonHourlyPay += parseFloat(totalHourlyPay);
                                                totalSalonTips += parseFloat(tipAmount);
                                                totalSalonCommissions += parseFloat(commissionValue);
                                                totalSalonPay += parseFloat(totalPay); 
                                                tableColumns.push(
                                                    <tr>
                                                        <td className="text-center">{index + 1}</td>
                                                        <td className="text-center">{(row?.Employee?.firstName ? row.Employee.firstName : '') + ' ' + (row?.Employee?.lastName ? row.Employee.lastName : '')}</td>
                                                        <td className="text-center">{getLocaleFixedValue(totalWorkingHours)}</td>
                                                        <td className="text-center">{getLocaleFixedValue(hourlyRate)}</td>
                                                        <td className="text-center">{getLocaleFixedValue(totalHourlyPay)}</td>
                                                        <td className="text-center">{getLocaleFixedValue(tipAmount)}</td>
                                                        <td className="text-center">{getLocaleFixedValue(commissionValue)}</td>
                                                        <td className="text-center">{getLocaleFixedValue(totalPay)}</td>
                                                    </tr>
                                                )
                                            });
                                            tableColumns.push(
                                                <tr>
                                                    <td colSpan={3}></td>
                                                    <td className="text-center" style={{'font-weight' : 'bold'}}>Total</td>
                                                    <td className="text-center">{getLocaleFixedValue(totalSalonHourlyPay)}</td>
                                                    <td className="text-center">{getLocaleFixedValue(totalSalonTips)}</td>
                                                    <td className="text-center">{getLocaleFixedValue(totalSalonCommissions)}</td>
                                                    <td className="text-center">{getLocaleFixedValue(totalSalonPay)}</td>
                                                </tr>
                                            )
                                            return tableColumns;
                                        })()}
                                    </>
                                )}
                                </>
                            ))
                        }
                        {
                            ( reports.length == 0 ) && ( <tr><td className="text-center" colSpan="10"><i class="bi bi-exclamation-circle"></i> No Data Found</td></tr> ) 
                        }
                    </tbody>
                    </Table>
                </CardBody>
            </Card>
            </>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
};

export default PayrollReport;
