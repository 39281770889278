import { GET_USERS_SUCCESS, GET_USERS_FAILURE, GET_USER_PENDING } from "./actionTypes";

const initialState = {
  users: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function usersReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_USER_PENDING: 
      return { ...state , loading : true };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_USERS_FAILURE:
      return { ...state, failed: true, loading: false, error: payload.error };

    default:
      return state;
  }
}

export default usersReducer;
