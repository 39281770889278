import { GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE, GET_LOCATIONS_PENDING } from "./actionTypes";

const initialState = {
  locations: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function locationsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_LOCATIONS_PENDING:
      return { ...state , loading : true };

    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_LOCATIONS_FAILURE:
      return { ...state, locations : [] , failed: true, loading: false, error: payload.error };

    default:
      return state;
  }
}

export default locationsReducer;
