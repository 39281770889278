import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardBody, Input , Row , Col , Label } from "reactstrap";
import ConfirmModal from "../../components/common/ConfirmModal";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getCustomersRequest } from "../../redux/customers/actions";
import { api, isActionPermitted , getFormatDate , USER_TYPES, DB_FORMAT } from "../../util";
import { getSaloonsRequest } from "../../redux/saloons/actions";

const ListCustomersCheckIn = () => {
  const dispatch = useDispatch();
  const { customers, count, loading } = useSelector((state) => state.customers);
  const { saloons } = useSelector((state) => state.saloons);
  const user = useSelector((state) => state.user);
  const { permissions } = useSelector((state) => state.user);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
	  filter: {
	      from: moment().format(DB_FORMAT.DATE),
	      to: moment().format(DB_FORMAT.DATE),
	      name: "",
	      location: "",
	  },
	  page: 1,
	  limit: 10,
	  search: "",
  });

  useEffect(() => {
    if (user.role === USER_TYPES.SUPER_ADMIN) {
      dispatch(getSaloonsRequest());
    }
    if (isActionPermitted("customers-check-in", "read", permissions)) {
      dispatch(getCustomersRequest(paginationConfig));
    }
  }, [dispatch, paginationConfig , permissions , user.role]);

  const deleteCustomer = async (id) => {
    try {
      const response = await api.delete("/customers/" + id);
      if (response.status === 200) {
        if (isActionPermitted("customers-check-in", "read", permissions)) {
          dispatch(getCustomersRequest(paginationConfig));
        }
        toast.success(response?.data?.message || response.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };
  
  const onDateRangeChange = ({ target }) => {
	  const { name, value } = target;
	  let pc = paginationConfig;
	  if (name === "from") {
		  pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
	  } else {
		  pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
	  }
	  setPaginationConfig({ ...pc, page: 1, limit: 10 });
  };
  const [startDate , setStartDate] = useState(paginationConfig.filter.from);
  const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));

  return (
    <React.Fragment>
      <PageHeader title="Customer's Check - In" shouldShowAddBtn={false}/>
      <Card>
      	<CardBody>
      		<Row className="gy-3 gx-3">
      			<Col xl={3} md={3} sm={12} xs={12}>
      				<Label>Customer</Label>
	        		<Input value={paginationConfig.filter.name} type="search" placeholder="Search Customer" role="search" aria-roledescription="search for Customer" autoFocus onChange={onSearchChange}/>
	        	</Col>
	        	<Col xl={3} md={3} sm={6} xs={6}>
              		<Label>From</Label>
              		<Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
              	</Col>
              	<Col xl={3} md={3} sm={6} xs={6}>
              		<Label>To</Label>
              		<Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
              	</Col>
          		{user.role === USER_TYPES.SUPER_ADMIN && (
                        <Col xl={3} md={3} sm={12} xs={12}>
                          <Label>Location</Label>
                          <Input caret type="select" name="location" value={paginationConfig.filter.location}
                            onChange={(e) => {
                              let x = paginationConfig;
                              x.filter.location = e.target.value;
                              setPaginationConfig({ ...x,  page: 1, limit: 10 });
                            }}
                          	>
                            <option value={""}>All Location</option>
                            {saloons.map((_, index) => (
                              <option key={_.id} value={_.Saloon.id}>
                                {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                              </option>
                            ))}
                          </Input>
                        </Col>
                      )}
        	</Row>
	     </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={customers}
        columns={[
          { Header: "Name", accessor: "name", width: "20%", },
          { Header: "Mobile", accessor: "mobile", width: "20%"},
          { Header: "Email", accessor: "email", width: "15%", class: "text-break"},
          { Header: "DOB" , accessor: "dateOfBirth" },
          { Header: "Mobile Subscribed" , accessor: "mobileSubscribe", width: "10%" },
          { Header: "Email Subscribed" , accessor: "emailSubscribe", width: "10%" },
          {
              Header: "Location",
              accessor: "saloon",
              width: "15%",
              Cell: ({ row }) => {
                return `${(row?.original?.Saloon?.name)}${(row?.original?.Saloon?.location && row?.original?.Saloon?.location != null) ? ' - ' + row?.original?.Saloon?.location : ''}`;
              },
          },
          {
              Header: "Date and Time",
              accessor: "createdAt",
              width: "20%",
              
              Cell: ({ row }) => {
                  return `${getFormatDate(row.original.createdAt)}`;
              },
          },
          /* {
              Header: "Actions",
              accessor: "actions",
              Cell: ({ row }) => {
                return (
                    <div className="d-flex gap-2">
                    <Button color="danger" onClick={() => { setDeleteId(row.original.id); setShowDeleteModal(true); }}>
                        <i className="bi bi-trash"></i>
                    </Button>
                    </div>
                );
              },
          } */
        ]}
      />

      {showDeleteModal && (
        <ConfirmModal
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onOk={() => deleteCustomer(deleteId)}
          onCancel={() => {
            setDeleteId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ListCustomersCheckIn;
