import React, {useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  Table,
} from "reactstrap";
import { DB_FORMAT, getFormatOnlyDate, isActionPermitted, USER_TYPES, roundOff, exportExcel } from "../../util";
import { getHourlyDetailedReportRequest } from "../../redux/report/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { getEmployeesRequest } from "../../redux/employees/actions";
import PageHeader from "../../components/common/PageHeader";
import NoDataFound from "../../components/common/NoDataFound";
import Loading from "../../components/common/Loading";

const HourlyDetailedReport = () => {

    const dispatch = useDispatch();
    const { permissions , role } = useSelector((state) => state.user);
    const { saloons } = useSelector((state) => state.saloons);
    const { employees } = useSelector((state) => state.employees);
    const { reports , count , loading } = useSelector((state) => state.reports);
    const [paginationConfig, setPaginationConfig] = useState({
        filter: {
            from:  moment().format(DB_FORMAT.DATE),
            to:  moment().format(DB_FORMAT.DATE),
            location: "",
            employee: "",
        },
        page: 1,
        limit: 10,
    });
    const [employeeFilter , setEmployeeFilter] = useState({
        filter : {
            working_location : ""
        }
    })

    useEffect(() => {
        if (isActionPermitted("hourly-detailed-report", "read", permissions)) {
            setDataFound(false);
            if(role == USER_TYPES.SUPER_ADMIN) {
                dispatch(getSaloonsRequest());
            }
            dispatch(getEmployeesRequest(employeeFilter));
            dispatch(getHourlyDetailedReportRequest(paginationConfig));
        }
    } , [paginationConfig]);

    const onDateRangeChange = ({ target }) => {
        const { name, value } = target;
        let pc = paginationConfig;
        if (name === "from") {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        } else {
            pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
            setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
        }
        setPaginationConfig({ ...pc, page: 1, limit: 10 });
    };

    // const onSearchChange = ({ target }) => {
    //     const { value } = target;
    //     const pc = paginationConfig;
    //     pc.filter.name = value;
    //     setPaginationConfig({ ...pc,  page: 1, limit: 10 });
    // };
  
    const [startDate , setStartDate] = useState(paginationConfig.filter.from);
    const [endDate , setEndDate] = useState(moment().format( DB_FORMAT.DATE));

    const [dataFound , setDataFound] = useState(false);
    return (
        <React.Fragment>
            <PageHeader title="Hourly Detailed Report" shouldShowAddBtn={true} onAddBtnClick={() => exportExcel('/reports/hourlyDetailedReport' , paginationConfig)} addBtnText="Export Excel" />
            <Card>
                <CardBody>
                    <Row className="gy-3 gx-3">
                        <Col xl={3} md={3} sm={12} xs={12}>
                            <Label>Employee</Label>
                            <Input caret type="select" name="location" value={paginationConfig.filter.employee}
                                onChange={(e) => {
                                let x = paginationConfig;
                                x.filter.employee = e.target.value;
                                setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                }}
                                >
                                <option value={""}>All Employee</option>
                                {employees.map((_, index) => (
                                <option key={_.id} value={_.Employee.id}>
                                    {_.Employee.firstName} {_.Employee.lastName}
                                </option>
                                ))}
                            </Input>
                        </Col>
                        {role === USER_TYPES.SUPER_ADMIN && (
                            <Col xl={3} md={3} sm={12} xs={12}>
                                <Label>Location</Label>
                                <Input caret type="select" name="location" value={paginationConfig.filter.location}
                                    onChange={(e) => {
                                    const ef = employeeFilter;
                                    ef.filter.working_location = e.target.value;
                                    setEmployeeFilter({ ...ef });
                                    let x = paginationConfig;
                                    x.filter.employee = ((employees.filter((value) => value?.Employee?.id == x.filter.employee && (value?.Employee?.saloonIds.split(',')).includes(e.target.value))?.length > 0) ? x.filter.employee : '');
                                    x.filter.location = e.target.value;
                                    setPaginationConfig({ ...x,  page: 1, limit: 10 });
                                    }}
                                    >
                                    <option value={""}>All Location</option>
                                    {saloons.map((_, index) => (
                                    <option key={_.id} value={_.Saloon.id}>
                                        {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                                    </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>From</Label>
                            <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
                        </Col>
                        <Col xl={3} md={3} sm={6} xs={6}>
                            <Label>To</Label>
                            <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            {!loading ? (
                <>
                {!dataFound && (
                    <NoDataFound />
                )}
                <Card className="newdashboard-table individual-table">
                <CardBody className="px-2 py-0">
                    <Table responsive bordered>
                        {dataFound && (
                            <thead>
                                <tr>
                                    <th style={{minWidth: '50px',width: '50px'}}></th>
                                    <th style={{minWidth: '110px',width: '110px'}}></th>
                                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Date</th>
                                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Start Time</th>
                                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>End Time</th>
                                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Working Hours</th>
                                    <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Total Break Hours</th> 
                                </tr>
                            </thead>
                        )}
                        <tbody className="table-body">
                            {reports?.length > 0 && 
                                reports.map((report) => (
                                    report?.Employee && report?.Employee?.length > 0 && (
                                        <>
                                        {role == USER_TYPES.SUPER_ADMIN && ((report.Employee).filter((row) => row.dailyLogInfo?.length > 0))?.length > 0 && (
                                            <tr>
                                                <td style={{'font-weight' : 'bold'}} colSpan={7} className={"salons-" + report?.saloonId}>{report?.name}</td>
                                            </tr>
                                        )}
                                        {(() => {
                                            const tableColumns = [];
                                            var rowIndex = 1;
                                            report?.Employee.map((row) => {
                                                row?.dailyLogInfo?.length > 0 && (
                                                    <>
                                                    {!dataFound && setDataFound(true)}
                                                    {tableColumns.push(
                                                        <>
                                                        <tr>
                                                            <td className="text-center">{rowIndex++}</td>
                                                            <td colSpan={6} className={"employees-" + report?.saloonId}>{(row?.Employee?.firstName ? row.Employee.firstName : '') + ' ' + (row?.Employee?.lastName ? row.Employee.lastName : '')}</td>
                                                        </tr>
                                                        {(() => {
                                                            var totalWorkingHours = 0;
                                                            var totalBreakHours = 0;
                                                            const dailyLogColumns = [];
                                                            row?.dailyLogInfo.map((dailyLog) => {
                                                                var workingHours = dailyLog?.workingSec ? parseFloat(dailyLog.workingSec)/3600 : "0.00";
                                                                var breakHours = dailyLog?.breakSec ? parseFloat(dailyLog.breakSec)/3600 : "0.00";
                                                                dailyLogColumns.push(
                                                                    <tr>
                                                                        <td colSpan={2}> </td>
                                                                        <td className="text-center">{(dailyLog?.workingDate ? getFormatOnlyDate(dailyLog.workingDate) : '-')}</td>
                                                                        <td className="text-center">{(dailyLog?.startTime ? moment(dailyLog?.startTime , DB_FORMAT.TIME).format("hh:mm A") : '-')}</td>
                                                                        <td className="text-center">{(dailyLog?.endTime ? moment(dailyLog?.endTime , DB_FORMAT.TIME).format("hh:mm A") : '-')}</td>
                                                                        <td className="text-center">{roundOff(workingHours)}</td>
                                                                        <td className="text-center">{roundOff(breakHours)}</td>
                                                                    </tr>
                                                                )
                                                                totalWorkingHours += parseFloat(workingHours);
                                                                totalBreakHours += parseFloat(breakHours);
                                                            })
                                                            dailyLogColumns.push(
                                                                <tr>
                                                                    <td colSpan={4}></td>
                                                                    <td className="text-center" style={{'font-weight' : 'bold'}}>Total</td>
                                                                    <td className="text-center">{roundOff(totalWorkingHours)}</td>
                                                                    <td className="text-center">{roundOff(totalBreakHours)}</td>
                                                                </tr>
                                                            )
                                                            return dailyLogColumns;
                                                        })()}
                                                        </>
                                                    )}
                                                    </>
                                                )
                                            })
                                            return tableColumns;
                                        })()}
                                        </>
                                    )
                                ))
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            </>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
};

export default HourlyDetailedReport;