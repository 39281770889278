import React, {useState, useEffect} from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from 'react-router-dom';

export default function AppointmentStep1({ pageInfo }) {

  const { state = { phoneNumber: '' } } = useLocation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  function validationError(error) {
    return(error && ((<span className="text-danger">{error?.message}</span>)));
  }

  const navigate = useNavigate();
  const onSubmit = () => {
    navigate('/book-services', { state: state });
  }

  
  return (
    <>
    <div className="main-card">
      <div className="card-form card">
        <div className="border-bottom p-3 mb-0 card-title">
          <div className="number-div"><i className="fa-solid fa-square-phone icon"></i>{state && state.phoneNumber && ( <span className="number">{state.phoneNumber}</span>)}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" className="signin-form card-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="full_name">Full Name<span className="text-danger">*</span>
                </label>
                <input type="text" className="form-control" name="full_name" placeholder="Enter Your Full Name"
                {...register("full_name", {
                  required: "Please Enter Your Full Name"
                })}/>
                {validationError(errors.full_name)}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="email">Email</label>
                <input type="text" className="form-control" name="email" placeholder="Enter Your Email"/>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-check custom-checkbox form-group">
                <input className="form-check-input" name="checkbox" type="checkbox" value="" id="checkbox"  />
                <label className="form-check-label form-label mb-0" htmlFor="checkbox">Lorem ipsum dolor sit amet.</label>
                <br />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-right mb-0 btns card-footer border-top-0 d-flex gap-2 justify-content-between">
              <button type="submit" name="submit" className="btn btn-primary send-btn">Sign Up</button>
                <Link to={'/book-appointment'} className="border btn btn-transaprent back-btn">Cancel</Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}