import { GET_SCHEDULES_SUCCESS, GET_SCHEDULES_FAILURE, GET_SCHEDULES_PENDING } from "./actionTypes";

const initialState = {
  schedules: [],
  count: 0,
  loading: true,
  failed: false,
  error: {},
};

function schedulesReducer(state = initialState, { type, payload }) {
  //console.log("employeesReducertype" , type );	
  switch (type) {
    case GET_SCHEDULES_PENDING:
      return { ...state , loading : true };

    case GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: payload.list,
        count: payload.count,
        failed: false,
        loading: false,
      };

    case GET_SCHEDULES_FAILURE:
      return {
        ...state,
        failed: true,
        schedules: [],
        loading: false,
        error: payload.error,
      };

    default:
      return state;
  }
}

export default schedulesReducer;
