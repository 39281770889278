import React from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import currectIcon from '../../assets/images/correct.png';

export default function EmployeeScreen({ pageInfo }) {
  
  return (
    <>
     <div className="thank-you-div new-thank-you for-big-font">
        <p className="thank-you-text">Welcome</p>

        <h5 className="thank-you-text-2 thank-you-text">You Are Signed In</h5>

        <div className="icon-div">
          <img src={currectIcon} alt="Thank You" className="thanks-icon" />
        </div>
        <h5 className="thank-you-text mt-3">Thank You!</h5>
     </div>
    </>
  );
}