import { api } from "../../util";

export const getCustomersApiCall = (payload = {}) => {
  let url = "/customers";

  return api.get(url, {
    params: {
      page: payload.page,
      limit: payload.limit,
      filter: payload.filter || {},
      sort: payload.sort,
      search: payload.search,
    },
  });
};

export const getSubscribedCustomersApiCall = (payload = {}) => {
  let url = "/customers/getSubscribedCustomers";

  return api.get(url, {
    params: {
      page: payload.page,
      limit: payload.limit,
      filter: payload.filter || {},
      sort: payload.sort,
      search: payload.search,
    },
  });
};
