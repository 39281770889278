import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Input, Row, Col, Label } from "reactstrap";
import ConfirmModal from "../../components/common/ConfirmModal";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { getEmployeesRequest } from "../../redux/employees/actions";
import { getEmployeesListRequest } from "../../redux/employees/actions";
import { api, isActionPermitted, getFormatDate , getFormatOnlyDate, ENABLE_STATUS_TEXT, STATUS, DB_FORMAT } from "../../util";
import moment from "moment/moment";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import Select from 'react-select';

const ListEmployee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { employees, count, loading } = useSelector((state) => state.employees);
  const { saloons } = useSelector((state) => state.saloons);
  const { permissions } = useSelector((state) => state.user);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setshowStatusModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
      name: "",
      from: null, // moment().format(DB_FORMAT.DATE),
	    to: null, //moment().format(DB_FORMAT.DATE),
      working_location: "",
    },
    sort: "name",
    // searchColumn: "name",
    // search: "",
  });
  const [employeeId , setEmployeeId] = useState(null);
  const [currentStatus , setCurrentStatus] = useState(null);

  useEffect(() => {
    if (isActionPermitted("employee", "read", permissions)) {
      dispatch(getEmployeesRequest(paginationConfig));
      //dispatch(getEmployeesListRequest(paginationConfig));
      dispatch(getSaloonsRequest());
    }
  }, [paginationConfig, permissions]);

  const deleteEmployee = async (id) => {
    try {
      const response = await api.delete("/employees/" + id);
      if (response.status === 200) {
        if (isActionPermitted("employee", "read", permissions)) {
          dispatch(getEmployeesRequest(paginationConfig));
        }
        toast.success(response?.data?.message || response.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  const onDateRangeChange = ({ target }) => {
	  const { name, value } = target;
	  let pc = paginationConfig;
	  if (name === "from") {
      pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setStartDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
    } else {
      pc.filter[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
      setEndDate((value !== "" ? moment(value).format(DB_FORMAT.DATE) : null));
    }
	  setPaginationConfig({ ...pc, page: 1, limit: 10 });
  };

  const [startDate , setStartDate] = useState(paginationConfig.filter.from);
  const [endDate , setEndDate] = useState(moment().format(DB_FORMAT.DATE));

  const [locationFilterOptions , setLocationFilterOptions] = useState([]);

  useEffect(() => {
    const options = [];
    if(saloons && saloons?.length > 0) {
      saloons.map((_ , index) => (
        options.push({"value" : _.Saloon.id , "label" : _.Saloon.name})
      ))
    }
    setLocationFilterOptions(options);
  } , [saloons]);

  const handleMultipleFilterSelectField = (e) => {
    console.log(e);
    let x = paginationConfig;
    var workingLocationValues = [];
    if(e.length > 0) {
      e.map((value , i) => (
        workingLocationValues.push(value?.value)
      ))
    }
    x.filter.working_location = workingLocationValues;
    setPaginationConfig({ ...x,  page: 1, limit: 10 });
  }

  const changeEmployeeStatus = async (employeeId) => {
    // var currentEnableDisableStatus = !e.target.checked;
    const statusInfo = {
      id: employeeId,
      status: currentStatus
    }
    try {
      const response = await api.post('/employees/updateRecordStatus' , statusInfo);

      if(response.status === 200) {
        if (isActionPermitted("employee", "read", permissions)) {
          dispatch(getEmployeesRequest(paginationConfig));
          //dispatch(getEmployeesListRequest(paginationConfig));
          dispatch(getSaloonsRequest());
        }
        toast.success(response?.data?.message || response.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  }

  return (
    <React.Fragment>
      <PageHeader title="Employees" addBtnUrl="/employees/add" />
      <Card>
        <CardBody>
          <Row className="gy-3 gx-3">
            <Col xl={3} md={3} sm={12} xs={12}>
              <Label>Employee</Label>
              <Input
                value={paginationConfig.filter.name}
                type="search"
                placeholder="Search Employee"
                role="search"
                aria-roledescription="search for employee"
                autoFocus
                onChange={onSearchChange}
              />
            </Col>
            <Col>
              <Label>Working Locations</Label>
              <Select 
                options={locationFilterOptions} 
                name="working_location" 
                onChange={handleMultipleFilterSelectField}
                closeMenuOnSelect
                isMulti
              />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>From Join Date</Label>
              <Input name="from" onChange={onDateRangeChange} value={moment(paginationConfig.filter.from).format(DB_FORMAT.DATE)} max={endDate}  type="date" />
            </Col>
            <Col xl={3} md={3} sm={6} xs={6}>
              <Label>To Join Date</Label>
              <Input min={startDate} onChange={onDateRangeChange} name="to" value={moment(paginationConfig.filter.to).format(DB_FORMAT.DATE)} type="date"/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={employees}
        columns={[
          { 
            Header: "Name",
            // accessor: "Employee.firstName"
            Cell : ({row}) => {
              return `${row?.original?.Employee?.firstName} ${row?.original?.Employee?.lastName}`;
            }
          },
          { Header: "Email", accessor: "Employee.email"  },
          { Header: "Phone", accessor: "Employee.contactNo" },
          { Header: "Code", accessor: "Employee.employeeCode" },
          {
              Header: "Joining Date",
              accessor: "Employee.joiningDate",
              Cell: ({ row }) => {
                  return `${ ( row.original.Employee.joiningDate ? getFormatOnlyDate(row.original.Employee.joiningDate) : '' )}`;
              },
          },
          {
            Header : "Status" , 
            Cell : ({row}) => {
              return (
                <div className="d-flex gap-2">
                  {isActionPermitted("employee", "edit", permissions) && (
                    <div className="form-check form-switch">
                      <input type="checkbox" className="form-check-input" checked={row.original?.Employee?.is_active === STATUS.ACTIVE ? true : false}
                        onChange={() => {
                          setEmployeeId(row.original?.employeeId);
                          setCurrentStatus(row.original?.Employee?.is_active === STATUS.ACTIVE ? STATUS.ACTIVE_TEXT : STATUS.INACTIVE_TEXT);
                          setshowStatusModal(true);
                        }} />
                      <label className="form-check-label" htmlFor={"customSwitches-"+row.original?.employeeId}>{row.original?.Employee?.is_active === STATUS.ACTIVE ? STATUS.ACTIVE_TEXT : STATUS.INACTIVE_TEXT }</label>
                    </div>
                  )}
                </div>
              )
            }
          },
          {
            Header: "Actions",
            accessor: "actions",
            width: "10%",
            Cell: ({ row }) => {
            	// {console.log("row")}{console.log(row)}
              return (
                <div className="d-flex gap-2">
                  {isActionPermitted("employee", "edit", permissions) && (
                    <Button
                      onClick={() => navigate(`/employees/${row.original.employeeId}`)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                  {isActionPermitted("employee", "delete", permissions) && (
                    <Button
                      color="danger"
                      onClick={() => {
                        setDeleteId(row.original.employeeId);
                        setShowDeleteModal(true);
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  )}
                </div>
              );
            },
          },
        ]}
      />

      {showDeleteModal && (
        <ConfirmModal
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onOk={() => deleteEmployee(deleteId)}
          onCancel={() => {
            setDeleteId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
      {showStatusModal && (
        <ConfirmModal
          isOpen={showStatusModal}
          setIsOpen={setshowStatusModal}
          onOk={() => changeEmployeeStatus(employeeId)}
          onCancel={() => {
            setEmployeeId(null);
            setCurrentStatus(null);
            setshowStatusModal(false);
          }}
          type="update"
          title="Update Status"
        />
      )}
    </React.Fragment>
  );
};

export default ListEmployee;
