import {
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_LIST_REQUEST,
  GET_EMPLOYEES_DROPDOWN_LIST_REQUEST,
  EMPLOYEE_LOGIN_REQUEST,
  EMPLOYEE_LOGIN_FAILURE,
  EMPLOYEE_LOGIN_SUCCESS,
  GET_LATE_EMPLOYEES_REQUEST,
  GET_TODAY_LATE_CLOCK_OUT,
  GET_CLOCK_IN_SUMMARY_REQUEST,
  GET_EMPLOYEE_PENDING,
} from "./actionTypes";

// NOTES

export const getEmployeesRequest = (payload) => {
  return {
    type: GET_EMPLOYEES_REQUEST,
    payload,
  };
};

export const getEmployeesSuccess = (payload) => {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload,
  };
};

export const getEmployeesFailure = (payload) => {
  return {
    type: GET_EMPLOYEES_FAILURE,
    payload,
  };
};

export const getEmployeesPending = () => {
  return {
    type : GET_EMPLOYEE_PENDING,
  }
}

export const getEmployeesListRequest = (payload) => {
  return {
    type: GET_EMPLOYEES_LIST_REQUEST,
    payload,
  };
};

export const getEmployeesDropdownListRequest = (payload) => {
  return {
    type: GET_EMPLOYEES_DROPDOWN_LIST_REQUEST,
    payload,
  };
};

export const getLateEmployeesRequest = (payload) => {
  return {
    type: GET_LATE_EMPLOYEES_REQUEST,
    payload,
  };
};

export const getTodayLateClockOutRequest = (payload) => {
  return {
    type: GET_TODAY_LATE_CLOCK_OUT,
    payload,
  };
};



export const getClockInSummaryRequest = (payload) => {
  return {
    type: GET_CLOCK_IN_SUMMARY_REQUEST,
    payload,
  };
};

// EMPLOYEE LOGIN

export const employeeLoginRequest = (payload, navigate, dispatch) => {
  return {
    type: EMPLOYEE_LOGIN_REQUEST,
    payload: { payload, navigate, dispatch },
  };
};

export const employeeLoginSuccess = (payload) => {
  return {
    type: EMPLOYEE_LOGIN_SUCCESS,
    payload,
  };
};

export const employeeLoginFailure = (payload) => {
  return {
    type: EMPLOYEE_LOGIN_FAILURE,
    payload,
  };
};