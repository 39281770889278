import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { getCategoriesRequest, getEmployeesRequest , getEmployeesDropdownListRequest } from "../../redux/actions";
import { getSaloonsRequest } from "../../redux/saloons/actions";
import { DB_FORMAT, PAYMENT_STATUS, SOCKET, USER_ROLES, api, getFormatOnlyDate, getLocaleFixedValue, roundOff, socket } from "../../util";
import Loading from "../../components/common/Loading";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const { saloons } = useSelector((state) => state.saloons);
  const { categories } = useSelector((state) => state.categories);
  const { employees } = useSelector((state) => state.employees);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [renderRequired , setRenderRequired] = useState(true);
  const [data, setData] = useState({
    cash: {
      orderCount: 0,
      net: "0",
      tax: "0",
      gross: "0",
      tip: "0"
    },
    square: {
      orderCount: 0,
      net: 0,
      tax: "0",
      gross: 0,
      tip: "0"
    },
  });

  const [paginationConfig, setPaginationConfig] = useState({
    location: null,
    employee: null,
    category: null,
    date: {
      from: moment().format(DB_FORMAT.DATE),
      to  : moment().format(DB_FORMAT.DATE),
    },
  });
  
  const [employeeFilter , setEmployeeFilter] = useState({
      filter : {
          working_location : ""
      }
  })

  useEffect(() => {
    if(renderRequired) {
      getComparisionTotals();
      setRenderRequired(false);
    }
  }, [renderRequired]);
  
  useEffect(() => {
	    dispatch(getEmployeesDropdownListRequest(employeeFilter));
 } ,[employeeFilter]);

  useEffect(() => {
    dispatch(getCategoriesRequest());
    dispatch(getSaloonsRequest());
    dispatch(getEmployeesDropdownListRequest(employeeFilter));
    getEmployeeAverage();
  } ,[]);

  useEffect(() => {
    fetchData();
  }, [paginationConfig]);

  const fetchData = async () => {
    try {
      // setIsLoading(true);
      const response = await api.post("/dashboard", paginationConfig);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  const onDateRangeChange = ({ target }) => {
    const { name, value } = target;
    let pc = paginationConfig;
    if (name === "from") {
      pc.date[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
    } else {
      pc.date[name] = (value !== "" ? moment(value).format(DB_FORMAT.DATE) : null);
    }
    setPaginationConfig({ ...pc });
    getComparisionTotals();
    updateDisplayDates();
    getEmployeeAverage();
    
  };

  const [comparisionTotals , setComparisionTotals] = useState([]);

  const getComparisionTotals = async () => {
    try {
      setIsLoading(true);
      var requestUrl = '/dashboard/getComparisionTotals?extraParams=' + JSON.stringify(paginationConfig);
      console.log("paginationConfig" , paginationConfig ); 
      const response = await api.get(requestUrl);
      if(response.status === 200) {
        setComparisionTotals(response?.data?.data?.comparisionTotals);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }
  
  const updateDisplayDates = async () => {
	  	var date = moment().format(DB_FORMAT.DATE);
	    if( paginationConfig.date.from ){
	    	date = paginationConfig.date.from;
	    }
	    console.log("date" , date );
	    console.log("paginationConfig" , paginationConfig.date.from );
	    const lastTenDates = [date];
	    for ( var i = 1; i < 10; i++) {
	      date = moment(date).subtract(1 , 'weeks').format(DB_FORMAT.DATE);
	      lastTenDates.push(date);
	    }
	    setComparisionDates(lastTenDates);
  };

  const [comparisionDates , setComparisionDates] = useState([]);

  useEffect(() => {
    var date = moment().format(DB_FORMAT.DATE);
    if( paginationConfig.date.from ){
    	date = paginationConfig.date.from;
    }
    console.log("date" , date );
    console.log("paginationConfig" , paginationConfig.date.from );
    const lastTenDates = [date];
    for ( var i = 1; i < 10; i++) {
      date = moment(date).subtract(1 , 'weeks').format(DB_FORMAT.DATE);
      lastTenDates.push(date);
    }
    setComparisionDates(lastTenDates);
  } , [])

  // useEffect(() => {
  //   const startOfHour = moment().startOf('hour').add(1 , 'hours').format(DB_FORMAT.DATE_TIME);
  //   const currentTime = moment().format(DB_FORMAT.DATE_TIME);
  //   const timeBeforeNextHour = (Date.parse(startOfHour) - Date.parse(currentTime));
  //   // console.log('timeBeforeNextHour' , timeBeforeNextHour)
  //   const interval = setInterval(() => {
  //     setRenderRequired(true);
  //   }, timeBeforeNextHour);
  //   return () => {
  //     clearInterval(interval);
  //   }
  // } , []);

  const [employeeAverage , setEmployeeAverage] = useState([]);
  const [isAverageTableLoading , setIsAverageTableLoading] = useState(false);
  const getEmployeeAverage = async () => {
      try {
        setIsAverageTableLoading(true);
        var requestUrl = '/dashboard/getEmployeeAverage?extraParams=' + JSON.stringify(paginationConfig);
        console.log("paginationConfig" , paginationConfig ); 
        const response = await api.get(requestUrl);
        if(response.status === 200) {
            setEmployeeAverage(response?.data?.data?.ticketAverage);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      } finally {
        setIsAverageTableLoading(false);
      }
  }

  useEffect(() => {
    if (!socket) return;
      socket.emit(SOCKET.EMIT_JOIN, {
        roomId: "SUPER_ADMIN",
        from: {
          id : user?.id,
          accountId : user?.accountId,
          role : (user?.accountId && user.accountId > 0 ? USER_ROLES.ACCOUNT : USER_ROLES.SUPER_ADMIN)
        }
      });

    socket.on("disconnected", (data) => {
      console.log("socket got disconnected on",  moment().format("DD MMM, YY hh:mm:ss"), "because of ", data);
    });    

    return () => {
      socket.off(SOCKET.EMIT_JOIN);
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on(SOCKET.RECEIVE_BILLING_DATA_CHANGE , (data) => {
      // console.log('billing data change')
      // console.log(data);
      if(data.paymentStatus === PAYMENT_STATUS.SUCCESSFUL) {
        // console.log('payment complete')
        setRenderRequired(true);
        fetchData();
        getEmployeeAverage();
      }
    })
    return () => {
      socket.off(SOCKET.RECEIVE_BILLING_DATA_CHANGE);
    };
  } , [socket]);
  const dateWiseTotal = [];
  const dateWiseCount = [];
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row xxl={5} xl={5} md={3} sm={2} xs={1} className="gy-3 gx-3">
            <Col>
              <Label>Location</Label>
              <Input
                caret
                type="select"
                name="location"
                value={paginationConfig.location}
                onChange={(e) => {
                	const ef = employeeFilter;
                	ef.filter.working_location = e.target.value;
                	setEmployeeFilter({ ...ef });
                  let x = paginationConfig;
                  x.location = e.target.value;
                  setPaginationConfig({ ...x });
                }}
              >
                <option value={""}>All Location</option>
                {saloons.map((_, index) => (
                  <option key={_.id} value={_.Saloon.id}>
                     {_.Saloon.name}{_.Saloon.location && _.Saloon.location != null && (<> - {_.Saloon.location}</>)}
                  </option>
                ))}
              </Input>
            </Col>
            <Col>
              <Label>Employee</Label>
              <Input
                caret
                type="select"
                name="location"
                value={paginationConfig.employee}
                onChange={(e) => {
                  let x = paginationConfig;
                  x.employee = e.target.value;
                  setPaginationConfig({ ...x });
                }}
              >
                <option value={""}>All Employees</option>
                {employees.map( employee => (
                        <option key={employee.id} value={employee.id}>
                          {employee.firstName + " " + employee.lastName}
                        </option>
                      ))}
              </Input>
            </Col>
            <Col>
              <Label>Category</Label>
              <Input
                caret
                type="select"
                name="location"
                value={paginationConfig.category}
                onChange={(e) => {
                  let x = paginationConfig;
                  x.category = e.target.value;
                  setPaginationConfig({ ...x });
                }}
              >
                <option value={""}>All Categories</option>
                {categories.map((_, index) => (
                  <option key={_.id} value={_.id}>
                    {_.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col>
              <Label>From</Label>
              <Input
                name="from"
                onChange={onDateRangeChange}
                max={paginationConfig.date.to}
                value={moment(paginationConfig.date.from).format(DB_FORMAT.DATE)}
                type="date"
              />
            </Col>
            <Col>
              <Label>To</Label>
              <Input
                onChange={onDateRangeChange}
                name="to"
                min={paginationConfig.date.from}
                value={moment(paginationConfig.date.to).format(DB_FORMAT.DATE)}
                type="date"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <CardTitle tag="h4" className="text-white">
        Cash transactions
      </CardTitle>
      <Row xl={5} md={3} sm={2} xs={1} className="mb-4">
        <Col>
          <Card body className="my-2">
            <CardTitle tag="h5">Transactions</CardTitle>
            <CardText tag="h3">{data.cash.orderCount || 0}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
          <Card body className="my-2">
            <CardTitle tag="h5">Net Sales</CardTitle>
            <CardText tag="h3">${roundOff(data.cash.gross || 0)}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
          <Card body className=" my-2">
            <CardTitle tag="h5">Tax</CardTitle>
            <CardText tag="h3">${roundOff(data.cash.tax || 0)}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
          <Card body className="my-2">
            <CardTitle tag="h5">Gross Sales</CardTitle>
            <CardText tag="h3">${roundOff(data.cash.net || 0)}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
            <Card body className="my-2">
              <CardTitle tag="h5">Tips</CardTitle>
              <CardText tag="h3">${roundOff(data.cash.tip || 0)}</CardText>
              {/* <Button color="primary">Go somewhere</Button> */}
            </Card>
          </Col>
      </Row>

      <CardTitle tag="h4" className="text-white">
        Card transactions
      </CardTitle>
      <Row xl={5} md={3} sm={2} xs={1} className="mb-4">
        <Col>
          <Card body className=" my-2">
            <CardTitle tag="h5">Transactions</CardTitle>
            <CardText tag="h3">{data.square.orderCount || 0}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
          <Card body className="my-2">
            <CardTitle tag="h5">Net Sales</CardTitle>
            <CardText tag="h3">${roundOff(data.square.gross || 0)}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
          <Card body className=" my-2">
            <CardTitle tag="h5">Tax</CardTitle>
            <CardText tag="h3">${roundOff(data.square.tax || 0)}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
          <Card body className="my-2">
            <CardTitle tag="h5">Gross Sales</CardTitle>
            <CardText tag="h3">${roundOff(data.square.net || 0)}</CardText>
            {/* <Button color="primary">Go somewhere</Button> */}
          </Card>
        </Col>
        <Col>
            <Card body className="my-2">
              <CardTitle tag="h5">Tips</CardTitle>
              <CardText tag="h3">${roundOff(data.square.tip || 0)}</CardText>
              {/* <Button color="primary">Go somewhere</Button> */}
            </Card>
          </Col>
      </Row>
      <Row>
        <Col>
            <h4 className="text-white mb-3">Comparison Table</h4> 
            <Card className="newdashboard-table">
              <CardBody className="px-2 py-0">
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th style={{minWidth: '150px',width: '150px'}}>Shop Name</th>
                      <th style={{minWidth: '50px',width: '80px'}}>Time</th>
                      {comparisionDates.map((date , i) => (
                        <th className="text-center">{getFormatOnlyDate(date)}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {saloons.length > 0 ? saloons.map((saloonInfo , index) => (
                    	<>
                        <tr>
                          <td><span className="fw-bolder text-break">{saloonInfo?.Saloon?.name}</span></td>
                          <td>{moment().format('hh A')}</td>
                          
                          {
                        	  
                            comparisionDates.map((date , i) => (
                        		  
                            <td className="text-center">
                              {(() => {
                                const data = comparisionTotals.filter((value) => (value?.saloonId == saloonInfo?.saloonId && value?.createdDate == date) );
                                var customBreakClassName = '';
                                if( data?.length > 0 ) {
                                	if( data[0]?.recordCount > 0 ) {
                                		dateWiseTotal[date] = ( parseFloat(dateWiseTotal[date]) > 0 ?  ( parseFloat( dateWiseTotal[date] ) + parseFloat(data[0]?.comparisionTotal) ) : parseFloat(data[0]?.comparisionTotal) ) ;
                                		dateWiseCount[date] = ( parseFloat(dateWiseCount[date]) > 0 ?  ( parseFloat( dateWiseCount[date] ) + parseFloat(data[0]?.recordCount) ) : parseFloat(data[0]?.recordCount) ) ;
                                		
                                		customBreakClassName = "display-record-count"
	                                }
                                }
                                return (
                                        <span >
                                          {
                                        	  data?.length > 0? (
                                                      <>
                                                        {getLocaleFixedValue(data[0]?.comparisionTotal)}
                                                        <br/><span className={customBreakClassName}>
                                                        ({data[0]?.recordCount})
                                                        </span>
                                                      </>
                                                    ) : ( '0.00') 
                                        	  
                                          }
                                        </span>
                                      );
                              })()}
                            </td>
                          ))}
                          {console.log("dateWiseTotal" , dateWiseTotal )}
                      </tr>
                     </>
                    )) : (
                      <>
                      {!isLoading && (
                        <tr>
                          <td className="text-center" colSpan="11">
                            <i class="bi bi-exclamation-circle"></i> No Data Found
                          </td>
                        </tr>
                      )}
                      </>
                    )}
                    <tr>
                  		<td className="text-center fw-bolder" colSpan="2">Total</td>
                  		{
                      	  comparisionDates.map((date , i) => (
                          <td className="text-center">
                              {(() => {
                                return (
                                        <span>
                                          {
                                        	  <>
                                                { ( dateWiseTotal[date] > 0 ? getLocaleFixedValue(dateWiseTotal[date]) : '' )}
                                                <br/><span>
                                                { dateWiseCount[date] > 0 ? '(' + dateWiseCount[date]  + ')': '' }
                                                </span>
                                              </>
                                           }
                                        </span>
                                      );
                              })()}
                             </td>
                      	  ))
                  		}
                  	</tr>
                  </tbody>
                </Table>
                {isLoading && (
                  <Loading />
                )}
              </CardBody>
            </Card>
          </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="text-white mb-3">Employee Average</h4>
          <Card className="newdashboard-table employee-average">
              <CardBody className="">
              <Table responsive bordered>
                  <thead>
                    <tr className="text-center">
                      <th style={{minWidth: '50px',width: '150px'}}>Sr. No.</th>
                      <th style={{minWidth: '150px',width: '150px'}}>Salons</th>
                      <th style={{minWidth: '70px',width: '150px'}}>Emp. Name</th>
                      <th style={{minWidth: '70px',width: '150px'}}>Average Ticket Size</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X1</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X2</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X3</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X4</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X5</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X6</th>
                      <th style={{minWidth: '70px',width: '150px'}}>X7</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeAverage.length > 0 ? 
                      employeeAverage.map((row , index) => (
                        <>
                        <tr>
                          <td className="text-center">{index + 1}</td>
                          <td>
                            {(() => {
                              const employeeSalons = [];
                              if(row?.Employee?.saloonIds) {
                                const saloonIds = row.Employee.saloonIds.split(',');
                                const employeeSalon = saloons.filter((value) => saloonIds.includes((value?.Saloon?.id).toString()));
                                employeeSalon.map((saloonInfo , salonIndex) => (
                                  employeeSalons.push(
                                  <div style={{minHeight : "20px"}}>
                                    {(saloonInfo?.Saloon?.name ? saloonInfo.Saloon.name + ((salonIndex + 1) < saloonIds.length ? ' ,' : '') : '')}
                                  </div>
                                  )
                                ))
                              }
                              return employeeSalons;
                            })()}
                          </td>
                          <td>{row?.Employee?.firstName + ' ' + row?.Employee?.lastName}</td>
                          <td className="text-center">{getLocaleFixedValue(row?.ticketAverage)}</td>
                          {row?.lastSevenTransactions.length > 0 && 
                            row.lastSevenTransactions.map((transaction) => (
                              <td className="text-center">{(transaction?.totalAmount ? getLocaleFixedValue(transaction.totalAmount) : '-')}</td>
                            ))}
                        </tr>
                        </>
                      )) : (
                        <>
                        {!isAverageTableLoading && (
                          <tr>
                            <td className="text-center" colSpan="11">
                              <i class="bi bi-exclamation-circle"></i> No Data Found
                            </td>
                          </tr>
                        )}
                        </>
                      )}
                  </tbody>
                </Table>
                {isAverageTableLoading && (
                  <Loading />
                )}
              </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Dashboard;
