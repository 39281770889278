import React, {useState, useEffect} from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AppointmentStep2({ pageInfo }) {

  const [startDate, setStartDate] = useState('');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  function validationError(error) {
    return(error && ((<span className="text-danger">{error?.message}</span>)));
  }

  const navigate = useNavigate();
  const onSubmit = () => {
    navigate('/book-services');
  }

  
  return (
    <>
    <div className="main-card">
      <div className="card-form card">
        <div className="border-bottom p-3 mb-0 card-title">
          <div className="number-div"><span className="welcome-back">Welcome Back ! Deep Suthar</span></div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} id="contact-us-form" className="signin-form card-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="date_of_birth">Date of Birth</label>
                <ReactDatePicker 
                    className="form-control"
                    name="date_of_birth"
                    placeholderText="DD-MM-YYYY"
                    dateFormat='dd-MM-yyyy'
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                ></ReactDatePicker>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="email">Email</label>
                <input type="text" className="form-control" name="email" placeholder="Enter Your Email"/>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group text-right mb-0 btns card-footer border-top-0 d-flex gap-2 justify-content-between">
                <button type="submit" name="submit" className="btn btn-primary send-btn">Proceed</button>
                <Link to={'/book-services'} className="border btn btn-transaprent back-btn">Skip</Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}