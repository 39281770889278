import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import ConfirmModal from "../../../components/common/ConfirmModal";
import { STATUS } from "../../../util";

const SelectEmployeeModal = ({
  isOpen,
  setIsOpen,
  onOk,
  onCancel,
  employeesList
}) => {
  
  const toggle = () => setIsOpen((prev) => !prev);
  const [showModal, setShowModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [customerName, setCustomerName] = useState('');

  const onSelect = (e , employeeInfo) => {
    if(employeeInfo?.isWorking === STATUS.ACTIVE) {
      setCustomerName(employeeInfo?.Customer?.name || '');
      setShowModal(true);
    } else {
      onOk(e.target?.value);
      toggle();
    }
    setSelectedEmployee(e?.target);
  }

  return (
    <>
    <Modal size="md" centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="text-capitalize">
        Select Employee
      </ModalHeader>
      <ModalBody>
        <Row>
          {employeesList.map(({ Employee }) => (
            <Col lg={6}>
              <FormGroup key={Employee?.id}>
                <input 
                  type="radio"
                  name="employeeId"
                  className="btn-check"
                  id={"btn-check-" + Employee?.id}
                  value={Employee?.id}
                  onClick={(e) => onSelect(e, Employee)}
                />
                <label className="btn btn-outline-primary w-100" htmlFor={"btn-check-" + Employee?.id}>{`${Employee?.firstName} ${Employee?.lastName}`}</label>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </ModalBody>
      <ModalFooter
        tag={"div"}
        className="transparant border-0 bg-light bg-light"
      >
        <Button
          onClick={onCancel || toggle}
          color="transprent"
          className="border"
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    {showModal && (
      <ConfirmModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Select Employee"
        type="Confirm"
        message={"You are already attending to "+ customerName +" . Are you sure you want to take second client ?"}
        onOk={() => {
          onOk(selectedEmployee?.value);
          toggle();
        }}
        onCancel={() => {
          setShowModal(false);
          selectedEmployee.removeAttribute('checked');
          setSelectedEmployee(null);
        }}
      />
    )}
    </>
  );
};

export default SelectEmployeeModal;
