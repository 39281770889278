import { api } from "../../util";

export const getPayrollReportApiCall = (payload = {}) => {
  let url = "/reports/payrollReport/";

  return api.get(url, {
    params: {
      // page: payload.page,
      // limit: payload.limit,
      filter: payload.filter || {},
    },
  });
};

export const getCommissionDetailedReportApiCall = (payload = {}) => {
  let url = "/reports/commissionDetailedReport/";

  return api.get(url, {
    params: {
      page: payload.page,
      limit: payload.limit,
      filter: payload.filter || {},
    },
  });
};

export const getTipsDetailedReportApiCall = (payload = {}) => {
  let url = "/reports/tipsDetailedReport/";

  return api.get(url, {
    params: {
      page: payload.page,
      limit: payload.limit,
      filter: payload.filter || {},
    },
  });
};

export const getHourlyDetailedReportApiCall = (payload = {}) => {
  let url = "/reports/hourlyDetailedReport/";

  return api.get(url, {
    params: {
      page: payload.page,
      limit: payload.limit,
      filter: payload.filter || {},
    },
  });
};

export const getScheduleDetailedReportApiCall = (payload = {}) => {
  let url = "/reports/scheduleDetailedReport/";

  return api.get(url, {
    params: {
      page: payload.page,
      limit: payload.limit,
      filter: payload.filter || {},
    },
  });
};
