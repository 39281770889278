import React, {useState} from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import { useNavigate } from "react-router-dom";

export default function BookAppointment({ pageInfo }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [isNumberButtonEnabled, setisNumberButtonEnabled] = useState(false);

  const maxLength = 12; // with hypen
  const numberLength = 10; // total phone number digit 
  const regex = /^[0-9-]+$/;
  
  // On Number button click function 
  const handleNumberClick = (number) => {
    if (phoneNumber.length < numberLength && !phoneNumber.endsWith('-')) {
      setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + number);
      setIsNextButtonEnabled(phoneNumber.length === numberLength - 1);
      setisNumberButtonEnabled(phoneNumber.length === numberLength - 1);
    }
  };

  // On Keypress function 
  const handleKeyUp = (event) => {
    const value = event.target.value;
    setPhoneNumber(value.replace(/[^\d]/g, ''));
    setIsNextButtonEnabled(value.length === maxLength);
    setisNumberButtonEnabled(value.length === maxLength);
  };

  // Backspace button function 
  const handleBackspace = () => {
    setPhoneNumber(phoneNumber.slice(0, -1));
    setIsNextButtonEnabled(phoneNumber.length === maxLength);
    setisNumberButtonEnabled(phoneNumber.length === maxLength);
  };

  // clear button function
  const handleClear = () => {
    setPhoneNumber('');
    setIsNextButtonEnabled(false);
    setisNumberButtonEnabled(false);
  };

  // formate number with hypen 
  const formattedPhoneNumber = phoneNumber.match(/\d*/g).join('').match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-').replace(/-*$/g, '');

  // Redirect page with phone number
  const navigate = useNavigate();
  const data = { phoneNumber: formattedPhoneNumber};
  const redirectSignin = () => {
    if(isNextButtonEnabled !== false){
      navigate('/appointment-step1', { state: data });
    }
  }
  
  return (
    <>
      <div className="main-card">
        <div className="sign-in-section">
          <div className="heading-div">
            <h4 className="welcome-title">Welcome!</h4>
            <h5 className="sign-in-msg">Please Sign in using your <br /> 10 digit phone number</h5>
          </div>

          <div className="sign-in-card">
            <div className="display-div">
              <input type="text" className="value" value={formattedPhoneNumber}
              onChange={handleKeyUp}
              onKeyDown={(event) => {
              if (!regex.test(event.key)) {event.preventDefault();} }} disabled={isNextButtonEnabled} name="phone_number" />
              <button type="button" onClick={handleBackspace} className="backspace-btn"><i className="fa-solid fa-left-long arrow"></i></button>
            </div>
            <div className="number-pad-card">
              <button type="button" onClick={() => handleNumberClick('1')} disabled={isNumberButtonEnabled} className="number-card number">1</button>
              <button type="button" onClick={() => handleNumberClick('2')} disabled={isNumberButtonEnabled} className="number-card number">2</button>
              <button type="button" onClick={() => handleNumberClick('3')} disabled={isNumberButtonEnabled} className="number-card number">3</button>
              <button type="button" onClick={() => handleNumberClick('4')} disabled={isNumberButtonEnabled} className="number-card number">4</button>
              <button type="button" onClick={() => handleNumberClick('5')} disabled={isNumberButtonEnabled} className="number-card number">5</button>
              <button type="button" onClick={() => handleNumberClick('6')} disabled={isNumberButtonEnabled} className="number-card number">6</button>
              <button type="button" onClick={() => handleNumberClick('7')} disabled={isNumberButtonEnabled} className="number-card number">7</button>
              <button type="button" onClick={() => handleNumberClick('8')} disabled={isNumberButtonEnabled} className="number-card number">8</button>
              <button type="button" onClick={() => handleNumberClick('9')} disabled={isNumberButtonEnabled} className="number-card number">9</button>
              <button type="button" className="number-card option-btn" onClick={handleClear}>Clear</button>
              <button type="button" onClick={() => handleNumberClick('0')} disabled={isNumberButtonEnabled} className="number-card number">0</button>
              <button type="button" className="number-card option-btn next-btn" onClick={redirectSignin} disabled={!isNextButtonEnabled}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}