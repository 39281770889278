import React, {useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";

const SamplePage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((prev) => !prev);
  
  var slipInfo = {
	"address" : "supermarket 123",
	"shop_name" : "Planet Earth",
	"shop_no" : "123-456-9999",
	"receipt_no" : "12345",
	"date" : "12-12-2023",
	"cashier_name" : "John Doe",
	"taxable" : "20.45",
	"vat_value" : "15",
	"vat_amount" : "3.60",
	"total" : "24.05",
	"currency_symbol" : "$",
	"cash" : "25.00",
	"change" : "0.95",
	"rowItems" : [
		{ "service_name" : "Lorem wheat" , "price" : "1.50" },
		{ "service_name" : "Ipsum apple" , "price" : "3.75" },
		{ "service_name" : "Doloe banana" , "price" : "7.30" },
		{ "service_name" : "Sit meat" , "price" : "9.50" },
		{ "service_name" : "Amet candy" , "price" : "0.80" },
		{ "service_name" : "Consectuer coffee" , "price" : "1.20" }
	]
}
  console.log("slipInfo" , slipInfo );
   console.log(JSON.stringify(slipInfo) );
  const handlePrintButtonClick = () => {
	  console.log("Print");
	  window.postMessage.handlePrint(0,JSON.stringify(slipInfo));
	  //window.postMessage.handlePrint("Print message from H5 page!");
  }
  
  const handleOpenButtonClick = () => {
	  console.log("Open");
	  window.postMessage.handleOpen("Open message from H5 page!");
  }
  
  const handleCloseButtonClick = () => {
	  console.log("Close");
	  window.postMessage.handleClose("Close message from H5 page!");
  }
  
  

  return (
    <React.Fragment>
      <section>
      	<Row >
      	 	<Col lg={4}>
	      	 	<Button variant="primary" onClick={handlePrintButtonClick}>
	      	 		Print
	      	 	</Button>
      	 	</Col>
	        <Col lg={4}>
		        <Button variant="primary" onClick={handleOpenButtonClick}>
		        	Open
		        </Button>
	        </Col>
	        <Col lg={4}>
		        <Button variant="primary" onClick={handleCloseButtonClick}>
		        	Close
		        </Button>
	        </Col>
	       </Row> 
        </section>
    </React.Fragment>
  );
};

export default SamplePage;
