import React, {useState, useEffect} from "react";
import {
  Button,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardTitle,
  Col,
  Row,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import '@fortawesome/fontawesome-free/css/all.css';
import '../user-screen/new-design.css';
import { Link } from "react-router-dom";
import { useParams , useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { api , LOCAL_STORAGE_USER } from "../../util";
import { useDispatch , useSelector } from "react-redux";
import { roundOff } from "../../util";
import { getCategoriesRequest } from "../../redux/actions";
import Select from 'react-select';

export default function SaloonBookServices({ pageInfo }) { 

  const customer = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));

  const phoneNumber = ((customer && customer?.mobile) ? customer.mobile : '');
  const customerId = ((customer && customer?.id) ? customer.id : '');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saloon_location_id } = useParams();
  const { categories } = useSelector((state) => state.categories);
  const [employeesList, setEmployeesList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [checkoutList, setCheckoutList] = useState([]);
  const [employeeId , setEmployeeId] = useState(null);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [searchServicesOptions , setSearchServicesOptions] = useState([]);
  const [defaultShowingCategories , setDefaultShowingCategories] = useState([]);
  const [otherCategories , setOtherCategories] = useState([]);

  useEffect(() => {
    setDefaultShowingCategories(categories.filter((value , index) => index < 5));
    setOtherCategories(categories.filter((value , index) => index >= 5))
  } , [categories]);  

  const fetchServices = async () => {
    try {
      const response = await api.get("/services");
      if(response?.data?.code === 200) {
        setServicesList(response?.data?.data?.list);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await api.get("/employees");
      if(response?.data?.code === 200) {
        setEmployeesList(response?.data?.data?.list);
      } else {
        toast.error(response?.data?.message);
      }
      
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    dispatch(getCategoriesRequest());
    fetchServices();
    fetchEmployees();
  }, []);

  const submitService = async () => {
    
    const selectedServices = [];
    const data = {};
    
    checkoutList.forEach((value) => {
      var services = { "service_id": value?.id, "quantity": value?.quantity  };
      selectedServices.push(services);
    });

    data.services = JSON.stringify(selectedServices).replace(/([^]+):/g, '$1:');
    data.customer_id = customerId;
    data.employee_id = employeeId;
    data.service_duration = null;
    data.saloon_id = saloon_location_id;

    try {
      const response = await api.post('/queue/createCustomerQueue' , data );
     
      if(response?.data?.code === 101){
        checkServerSideValidation( response?.data?.message)
      }else{
  
        if (response.status === 201) {
        toast.success(response?.data?.message || response.message);
        redirectThankYou();
        }
      }
    } catch (error) {
      
    }
  }

  const checkServerSideValidation = (validationErrors) => {
	
		if( typeof(validationErrors) !== undefined  ){
			if( Array.isArray(validationErrors)){
				validationErrors.forEach ( (value)  => {
					toast.error(value.message);
				});
			}
		}
	}

  const redirectThankYou = () => {
    navigate("/thank-you" , {state:{saloonLocationId:saloon_location_id}});
  }

  useEffect(() => {
    const options = [];
    if(servicesList?.length > 0) {
      servicesList.map((value) => (
        options.push({
          ...value ,
          'label' : value?.name,
          'value' : value?.id,
        })
      ))
    }
    setSearchServicesOptions(options);
  } , [servicesList]);

  const selectService = (data) => {
    if (!data.isVariablePricing) {
      let checkout = checkoutList.findIndex(
        (cl) => data.id === cl.id
      );
      if (checkout === -1) {
        setCheckoutList((prev) => [
          ...prev,
          { ...data, quantity: 1 },
        ]);
      } else {
        let serviceList = checkoutList;
        serviceList[checkout] = {
          ...serviceList[checkout],
          quantity:
            serviceList[checkout].quantity +
            1,
        };
        setCheckoutList([...serviceList]);
      }
    } else {
      let checkout = checkoutList.findIndex(
        (cl) => data.id === cl.id
      );
      if (checkout === -1) {
        setCheckoutList((prev) => [
          ...prev,
          { ...data, quantity: 1 , servicePrice : [{price : '0.00'}] },
        ]);
      } else {
        let serviceList = checkoutList;
        serviceList[checkout] = {
          ...serviceList[checkout],
          quantity:
            serviceList[checkout].quantity +
            1,
        };
        setCheckoutList([...serviceList]);
      }
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  console.log('checkoutList' , checkoutList)
  return (
    <>
    <div className="main-card">
      <div className="info-card service-info-card">
        <div className="card">
          <div className="border-bottom p-3 mb-0 card-title">
            <i className="fa-solid fa-square-phone icon"></i>{(phoneNumber !== '') && ( <span className="number">{phoneNumber}</span>)}
          </div>
          <div className="card-body">
            <div className="tab-content">
              <div className="tab-header">
                <span className="number-tag">01</span>Select Services
              </div>
              <div className="tab-body">
                <div className="input">
                {checkoutList.map((data, index) => (
                    <div className="service-tag" key={data.id}>
                        {data.name}
                        {`($${roundOff(
                           data.servicePrice[0].price * data.quantity
                        )})`}
                    </div>
                ))}
                </div>
              </div>
              <div className="tab-footer">
                <button onClick={toggle} type="button" className="add-service"><span className="icon-bg"><i className="fa-solid fa-plus icon"></i></span><p className="text">Touch to select service</p></button>
              </div>
            </div>

            <div className="tab-content">
              <div className="tab-header">
                <span className="number-tag">02</span>Select Artist (optional)
              </div>
              <div className="tab-body">
                <div className="checkbox-list">
                  <div className="checkbox-div">
                    <div className="checkbox">
                      <input className="form-check-input checkbox-input" name="select_artist" type="radio" value="" id="artist_1" checked={!employeeId} />
                      <label className="form-check-label" htmlFor="artist_1">First Available</label>
                    </div>
                    <span className="waiting-text">0 min wait</span>
                  </div>
                  {employeesList.map(({ Employee }) => (
                    <div className="checkbox-div" key={Employee?.id}>
                        <div className="checkbox"> 
                            <input className="form-check-input checkbox-input" name="select_artist" type="radio" value={Employee?.id} id={Employee?.id} onClick={(e) => setEmployeeId(e.target.value)} />
                            <label className="form-check-label" htmlFor={Employee?.id}>{`${Employee?.firstName} ${Employee?.lastName}`}</label>
                        </div>
                        <span className="waiting-text">0 min wait</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="form-group text-right mb-0 btns card-footer border-top-0 d-flex gap-2 justify-content-end mt-3">
              <Link to={'/book-appointment/' + saloon_location_id} className="border btn btn-transaprent back-btn">Cancel</Link>
              <button className="btn btn-secondary send-btn" onClick={submitService}>Sign In</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal isOpen={modal} className="service-modal" toggle={toggle} centered size={'xl'}>
        <ModalHeader toggle={toggle}>Select Services</ModalHeader>
        <ModalBody>
          {servicesList.length > 0 && (
            <Card>
              <CardBody>
                <Row className="gy-3 gx-3">
                  <Col>
                    <Label>Search Services</Label>
                    <Select 
                      name="search_services"
                      placeholder="Search Services"
                      options={searchServicesOptions}
                      onChange={selectService}
                      value={null}
                      isSearchable
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          <Card className="shadow-none mb-0">
            <Row>
              <CardTitle tag="h5">Categories</CardTitle>
              <Col lg={12} className="categories-row">
                <div className="btns-group">
                  {defaultShowingCategories.map((data) =>(
                    <Button 
                      block 
                      key={data.id}
                      onClick={() => setSelectedCategory(data.name)} 
                      type="button" 
                      color={
                          data.name === selectedCategory
                            ? "primary"
                            : "secondary"
                      }
                      className="my-1 categories-btn">
                          {data.name}
                    </Button>
                  ))}
                </div>
                <div className="categories-dropdown-btn">
                    <Dropdown isOpen={dropdownOpen} className="my-1" toggle={toggleDropdown} direction="end">
                      <DropdownToggle caret><i class="bi bi-list"></i></DropdownToggle>
                      <DropdownMenu>
                        {otherCategories.map((data) =>(
                            <DropdownItem key={data.id} onClick={() => setSelectedCategory(data.name)} >{data.name}</DropdownItem> 
                        ))}
                      </DropdownMenu>
                  </Dropdown>
                </div>
              </Col>
              <Col lg={12} className="border-start row-height">
                <CardTitle tag="h5">Services </CardTitle>
                <div className="service-list">
                  <Row>
                    {selectedCategory && (
                      servicesList
                        .filter(
                          (data) => 
                              data.Category?.name.includes(selectedCategory) &&
                              parseInt(data.servicePrice[0].price) !== 0
                        )
                        .sort((a, b) => {
                          return (
                            (a.order != null ? a.order : 999) -
                            (b.order != null ? b.order : 999)
                          );
                        })
                        .map((data, index) => (
                          <Col lg={4} key={data.id}>
                            <Button
                              block
                              onClick={(e) => {
                                e.preventDefault();
                                selectService(data);
                              }}
                              type="button"
                              key={data.id}
                              className="my-1"
                            >
                              {data.name}
                            </Button>
                          </Col>
                        ))
                      )
                    }
                  </Row>
                </div>
              </Col>
              <Col sm={12} className="mt-3 border-top pt-2">
                <CardTitle tag="h5">Selected Services</CardTitle>  
                <Row>
                {checkoutList.map((data, index) => (
                    <Col md={6} lg={4}>
                        <Button 
                            block 
                            type="button" 
                            color="light" 
                            className="d-flex justify-content-between align-items-center my-1 fs-6 gap-1"
                            key={data.id}
                        >
                            <div className="d-flex text-start">{data.name} {`(${data.quantity})`} <br />
                                {`$${roundOff(
                                    data.servicePrice[0].price * data.quantity
                                )}`}
                            </div>
                            <i 
                                className="bi bi-x fs-4 fw-bolder" 
                                onClick={() => {
                                    let newArr = checkoutList.filter(
                                    (data, i) => i !== index
                                    );
                                    setCheckoutList([...newArr]);
                                }}
                            />
                        </Button>
                    </Col>
                ))}
                </Row>
              </Col>
            </Row>
          </Card>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <button className="btn btn-primary" onClick={toggle}>OK</button>
        </ModalFooter>
      </Modal>
    </>
  );
}